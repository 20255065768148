import React from 'react';
// import { FormattedMessage as M } from 'react-intl';
import { Formik, Form, ErrorMessage, FastField } from 'formik';
import SelectField from 'component/SelectField';

export default function FilterForm(props) {
  // const months = ['Akutansi', 'Sistem absensi', 'Markeplace', 'Java'];
  const { toggleShow, modalHeader } = props;

  return (
    <div className="modals__content text-left">
      <div className="modals__header">
        <h2>{modalHeader}</h2>
      </div>
      <Formik
        initialValues={{
          ...props.initialValues,
        }}
        validationSchema={''}
        onSubmit={(values, { isSubmitting }) => {
          props.onSubmit(values);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          isSubmitting,
          actions,
        }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-sm-4 mb-lg-0">
                  {props.projects && (
                    <div className="form-group">
                      <label htmlFor="project">Projects</label>
                      <FastField
                        className="form-control"
                        as="select"
                        name="project"
                      >
                        <option>---Select---</option>
                        {props.projects.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name}
                          </option>
                        ))}
                      </FastField>
                      {/*<FastField
                        component="select"
                        name="project"
                        id="project"
                        value={values.project}
                        className={`form-control ${errors.project &&
                          touched.project &&
                          'error'}`}
                      >
                        <option>---Select---</option>
                        {months.map((x, i) => (
                          <option key={i} value={x}>
                            {x}
                          </option>
                        ))}
                      </FastField>*/}
                      <ErrorMessage
                        name="project"
                        component="small"
                        className="form-text text-danger project-error"
                      />
                    </div>
                  )}
                  {props.contacts && (
                    <div className="form-group">
                      <label htmlFor="contact">contacts</label>
                      <FastField
                        className="form-control"
                        as="select"
                        name="contact"
                      >
                        <option>---Select---</option>
                        {props.contacts.map((x, i) => (
                          <option key={i} value={x.id}>
                            {x.name}
                          </option>
                        ))}
                      </FastField>
                      <ErrorMessage
                        name="contact"
                        component="small"
                        className="form-text text-danger contact-error"
                      />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="date">Start Date</label>
                        <FastField
                          className={`form-control ${errors.date &&
                            touched.date &&
                            'error'}`}
                          type="date"
                          name="start"
                          placeholder="Enter your date"
                        />
                        <ErrorMessage
                          name="start"
                          component="small"
                          className="form-text text-danger date-error"
                        />
                        <span className="text-success">
                          Format date : dd/mm/yyyy
                        </span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="date">End Date</label>
                        <FastField
                          className={`form-control ${errors.date &&
                            touched.date &&
                            'error'}`}
                          type="date"
                          name="end"
                          placeholder="Enter your date"
                        />
                        <ErrorMessage
                          name="end"
                          component="small"
                          className="form-text text-danger date-error"
                        />
                        <span className="text-success">
                          Format date : dd/mm/yyyy
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="account">Account</label>
                    <FastField
                      className="form-control mb-2"
                      name={'account'}
                      component={SelectField}
                    >
                      {fieldProps => (
                        <SelectField {...fieldProps} options={props.accounts} />
                      )}
                    </FastField>
                    <ErrorMessage
                      name="account"
                      component="small"
                      className="form-text text-danger account-error"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="search">Search</label>
                    <FastField className="form-control mb-2" name="search" />
                    <ErrorMessage
                      name="search"
                      component="small"
                      className="form-text text-danger search-error"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3"></div>
              </div>
              <div className="action-btn">
                <button className="btn btn-primary mb-0" type="submit">
                  Submit
                </button>
                <button
                  className="btn btn-warning mb-0"
                  type="button"
                  onClick={toggleShow}
                >
                  close
                </button>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
