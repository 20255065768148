export default {
  'app.account': 'Account',
  'app.accountingSettings': 'Accounting Settings',
  'app.addLine': 'Add line',
  'app.addNew': 'Add new',
  'app.alreadyHaveAnAccount': 'Already have an account ?',
  'app.amount': 'Amount',
  'app.back': 'Back',
  'app.cashBank': 'Cash Bank',
  'app.company': 'Company',
  'app.companyProfile': 'Company Profile',
  'app.companySettings': 'Company Settings',
  'app.contact': 'Contact',
  'app.contacts': 'Contacts',
  'app.createCompany': 'Create company',
  'app.date': 'Date',
  'app.delete': 'Delete',
  'app.detail': 'Detail',
  'app.dontHaveAnAccount': "Don't have an account ?",
  'app.edit': 'Edit',
  'app.expenseAccount': 'Expense Account',
  'app.expenseSettings': 'Expense Settings',
  'app.forgotYourPassword': 'Forgot your password?',
  'app.goToDashboard': 'Go to Dashboard',
  'app.goToHome': 'Go to home',
  'app.journal': 'Journal',
  'app.id': 'ID',
  'app.incomeAccount': 'Income Account',
  'app.incomeSettings': 'Income Settings',
  'app.listOfContacts': 'List of contacts',
  'app.listOfExpense': 'List of expense',
  'app.listOfIncome': 'List of income',
  'app.listOfProducts': 'List of products',
  'app.listOfProjects': 'List of projects',
  'app.listOfTaxes': 'List of taxes',
  'app.listOfWallets': 'List of wallets',
  'app.loading': 'Loading...',
  'app.login': 'Login',
  'app.loginHere': 'Login here.',
  'app.logOut': 'Log out',
  'app.newExpense': 'New expense',
  'app.newIncome': 'New income',
  'app.newTransfer': 'New transfer',
  'app.newGeneralLedger': 'New general ledger',
  'app.note': 'Note',
  'app.products': 'Products',
  'app.profile': 'Profile',
  'app.project': 'Project',
  'app.projectList': 'Project list',
  'app.productList': 'Product list',
  'app.register': 'Register account',
  'app.registerHere': 'Register here.',
  'app.resetPassword': 'Reset password',
  'app.submit': 'Submit',
  'app.taxes': 'Taxes',
  'app.toggleLanguage': 'Change Language',
  'app.update': 'Update',
  'app.updateGeneralLedger': 'Update Journal',
  'app.updateIncome': 'Update Income',
  'app.userAccount': 'User Account',
  'app.wallet': 'Wallet',
  'app.wallets': 'Wallets',
  'app.youAreAlreadyLoggedIn': 'You are already logged in,',
  'dashboard.onBoarding': 'Create your company to begin.',
  'landingPage.welcome': 'Welcome to Aiclo',
  'onBoarding.finish': 'You are all set',
  'app.dashboard': 'Dashboard',
  'app.addNewData': 'Add new data',
};
