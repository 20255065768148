import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import flasher from './flasher';
import intl from './intl';
import company from './company';
import contacts from './contacts';
import products from './products';
import projects from './projects';
import transactions from './transactions';
import accounts from './accounts';

export default history =>
  combineReducers({
    // default
    auth,
    flasher,
    intl,
    router: connectRouter(history),
    // business process
    accounts,
    company,
    contacts,
    products,
    projects,
    transactions,
  });
