import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, FastField, ErrorMessage } from 'formik';

import DateField from 'component/Global/DateField';

export default function DateRangeForm(props) {
  const ValidationSchema = Yup.object().shape({});

  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={props.initialValues}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            start: values.start,
            end: values.end,
          })
          .then(() => actions.setSubmitting(false))
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="start">Date start</label>
                <FastField
                  className="form-control"
                  placeholder="Date and time"
                  name="start"
                  component={DateField}
                />
                <small className="form-text text-muted">
                  Use the format : yyyy-mm-dd
                </small>
                <ErrorMessage
                  name="start"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="end">Date end</label>
                <FastField
                  className="form-control"
                  placeholder="Date and time"
                  name="end"
                  component={DateField}
                />
                <small className="form-text text-muted">
                  Use the format : yyyy-mm-dd
                </small>
                <ErrorMessage
                  name="end"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}
