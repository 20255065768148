import React, { Fragment, useState } from 'react';
import get from 'lodash/get';
import shortid from 'shortid';
// import { FormattedMessage as M } from 'react-intl';

import FullPageLoader from 'component/FullPageLoader';
import { defaultAccounts } from 'constant';

export default function OnBoardingCompany(props) {
  const [submitting, setSubmitting] = useState(false);
  const company = get(props, 'company');
  const uid = get(props, 'uid');

  function handleSubmit(useDefault = true) {
    setSubmitting(true);
    const data = useDefault
      ? defaultAccounts.map(x => ({
          ...x,
          id: shortid.generate(),
          children: x.children.map(xx => ({
            ...xx,
            id: shortid.generate(),
          })),
        }))
      : [];

    return props._registerAccountChart(data, uid, company.id).then(result => {
      props._push(useDefault ? '/home/on-boarding/4' : '/home/on-boarding/3');
    });
  }

  return (
    <Fragment>
      <p>How do you want to start your account classification?</p>
      <p>
        <button className="btn btn-primary" onClick={() => handleSubmit()}>
          I want to use the default classification so I can modify on top of it.
        </button>
        <br />
        <small>
          This works for most of our user. If not, just update it any time.
        </small>
      </p>
      <p>
        <button className="btn btn-primary" onClick={() => handleSubmit(false)}>
          I want a blank canvas.
        </button>
        <br />
        <small>Only choose if you now your accounting.</small>
      </p>
      <FullPageLoader show={submitting} />
    </Fragment>
  );
}
