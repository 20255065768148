import React, { Fragment, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Login from './containers/Login';
import Register from './containers/Register';
import Home from './containers/Home';
import Credit from './containers/Credit';
import LandingPage from './containers/LandingPage';

import Header from './component/Header';
import Flasher from './component/Flasher';

import { onAuthStateChanged, logout } from './reducer/auth';
import { deleteFlash } from './reducer/flasher';
import { switchLocale } from './reducer/intl';
// import MenuSidebar from './component/MenuSidebar';

export function CoreApp(props) {
  const { _onAuthStateChanged } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    _onAuthStateChanged();
  }, [_onAuthStateChanged]);
  function handleSidebarOpen() {
    setSidebarOpen(!sidebarOpen);
  }

  return (
    <Fragment>
      <div className={`${sidebarOpen ? 'openMenu' : 'closeMenu'}`}>
        <Header
          {...props}
          handleSidebarOpen={handleSidebarOpen}
          sidebarOpen={sidebarOpen}
        />
        <Switch>
          <Route
            render={params => <Login {...props} {...params} />}
            path="/login"
          />
          <Route
            render={params => <Register {...props} {...params} />}
            path="/register"
          />
          <Route
            render={params => (
              <Home
                {...props}
                {...params}
                handleSidebarOpen={handleSidebarOpen}
                sidebarOpen={sidebarOpen}
              />
            )}
            path="/home"
          />
          <Route
            render={params => <Credit {...props} {...params} />}
            path="/credit"
          />
          <Route
            render={params => <LandingPage {...props} {...params} />}
            exact
            path="/"
          />
          <Route
            render={() => (
              <h3 className="not-found">Houston, we have a problem 404</h3>
            )}
          />
        </Switch>
      </div>
      <Flasher flasher={props.flasher} onDelete={props._deleteFlash} />
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    email: get(state, 'auth.user.email'),
    displayName: get(state, 'auth.user.displayName'),
    flasher: state.flasher,
    locale: state.intl.locale,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    _switchLocale: locale => dispatch(switchLocale(locale)),
    _onAuthStateChanged: () => dispatch(onAuthStateChanged()),
    _logout: () => dispatch(logout()),
    _deleteFlash: () => dispatch(deleteFlash()),
    _push: to => dispatch(push(to)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreApp);
