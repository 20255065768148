import React from 'react';
import { Formik, Form, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const balanceSheet = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'liabilities',
    label: 'Liabilities',
  },
  {
    value: 'equity',
    label: 'Equity',
  },
];

const cashFlow = [
  {
    value: 'cashBank',
    label: 'Cash Bank',
  },
  {
    value: 'operating',
    label: 'Operating',
  },
  {
    value: 'investing',
    label: 'Investing',
  },
  {
    value: 'financing',
    label: 'Financing',
  },
];

const profitLoss = [
  {
    value: 'income',
    label: 'Income',
  },
  {
    value: 'expenseOnIncome',
    label: 'Expense on Income',
  },
  {
    value: 'operationalExpense',
    label: 'Operational Expense',
  },
  {
    value: 'nonOperationalExpense',
    label: 'Non Operational Expense',
  },
  {
    value: 'otherIncome',
    label: 'Other Income',
  },
  {
    value: 'otherExpense',
    label: 'Other Expense',
  },
];

export default function CategoryForm(props) {
  const ValidationSchema = Yup.object().shape({
    // income: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
    // expense: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
    // wallets: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
  });

  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={
        props.initialValues || {
          name: '',
          balanceSheet: '',
          cashFlow: '',
          profitLoss: '',
        }
      }
      onSubmit={(values, actions) => {
        props
          .onSubmit(values)
          .then(() => actions.setSubmitting(false))
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <FastField className="form-control" type="text" name="name" />
                <ErrorMessage
                  name="name"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
            {
              // <div className="col-4">
              //   <div className="form-group">
              //     <label htmlFor="code">Code</label>
              //     <FastField className="form-control" type="text" name="code" />
              //     <ErrorMessage
              //       name="code"
              //       component="small"
              //       className="form-text text-muted"
              //     />
              //   </div>
              // </div>
            }
          </div>
          <div className="form-row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="balanceSheet">Balance Sheet</label>
                <FastField className="form-control" name="balanceSheet">
                  {({ field, form: { isSubmitting, setFieldValue } }) => {
                    return (
                      <div>
                        {balanceSheet.map(x => (
                          <div
                            className="form-check form-check-inline"
                            key={x.value}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              id={x.value}
                              value={x.value}
                              {...field}
                              checked={field.value === x.value}
                              onChange={() =>
                                setFieldValue(field.name, x.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={x.value}
                            >
                              {x.label}
                            </label>
                          </div>
                        ))}
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={null}
                            {...field}
                            checked={!field.value}
                            onChange={() => setFieldValue(field.name, '')}
                          />
                          <label className="form-check-label" htmlFor={''}>
                            Not set
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </FastField>
                <ErrorMessage
                  name="balanceSheet"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="cashFlow">Cash Flow</label>
                <FastField className="form-control" name="cashFlow">
                  {({ field, form: { isSubmitting, setFieldValue } }) => {
                    return (
                      <div>
                        {cashFlow.map(x => (
                          <div
                            className="form-check form-check-inline"
                            key={x.value}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              id={x.value}
                              value={x.value}
                              {...field}
                              checked={field.value === x.value}
                              onChange={() =>
                                setFieldValue(field.name, x.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={x.value}
                            >
                              {x.label}
                            </label>
                          </div>
                        ))}
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={null}
                            {...field}
                            checked={!field.value}
                            onChange={() => setFieldValue(field.name, '')}
                          />
                          <label className="form-check-label" htmlFor={''}>
                            Not set
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </FastField>
                <ErrorMessage
                  name="cashFlow"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="profitLoss">Proft and Loss</label>
                <FastField className="form-control" name="profitLoss">
                  {({ field, form: { isSubmitting, setFieldValue } }) => {
                    return (
                      <div>
                        {profitLoss.map(x => (
                          <div
                            className="form-check form-check-inline"
                            key={x.value}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              id={x.value}
                              value={x.value}
                              {...field}
                              checked={field.value === x.value}
                              onChange={() =>
                                setFieldValue(field.name, x.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={x.value}
                            >
                              {x.label}
                            </label>
                          </div>
                        ))}
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            value={null}
                            {...field}
                            checked={!field.value}
                            onChange={() => setFieldValue(field.name, '')}
                          />
                          <label className="form-check-label" htmlFor={''}>
                            Not set
                          </label>
                        </div>
                      </div>
                    );
                  }}
                </FastField>
                <ErrorMessage
                  name="profitLoss"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            </div>
          </div>

          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
          {props.onBack && (
            <button
              className="btn btn-link"
              type="button"
              disabled={isSubmitting}
              onClick={props.onBack}
            >
              Back
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
}
