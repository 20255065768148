import React from 'react';

export default function Flasher({ flasher, onDelete }) {
  if (!flasher) {
    return '';
  }
  return (
    <div className="py-2 fixed-bottom">
      <div className="container">
        <div
          className={`alert alert-${flasher.type} alert-dismissible fade show`}
          role="alert"
        >
          <div dangerouslySetInnerHTML={{ __html: flasher.message }} />
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => onDelete()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
}
