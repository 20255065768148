import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { businessType, currencies } from 'constant';

const ValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  businessType: Yup.string().required('Required'),
});

const defaultValues = {
  companyName: '',
  businessType: '',
};

export default function CompanyForm(props) {
  return (
    <Formik
      className="form"
      enableReinitialize
      validationSchema={ValidationSchema}
      initialValues={props.initialValues || defaultValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        props
          .onSubmit(values)
          .then(sample => {
            actions.setSubmitting(false);
            actions.resetForm();
          })
          .catch(er => {
            actions.setSubmitting(false);
            console.error(er);
          });
      }}
    >
      {({ errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="companyName">Nama Perusahaan</label>
            <Field className="form-control" type="text" name="companyName" />
            <ErrorMessage
              name="companyName"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <div className="form-group">
            <label htmlFor="businessType">Jenis Bisnis</label>
            <Field
              className="form-control"
              component="select"
              name="businessType"
            >
              <option value={null}>Please select busines type</option>
              {businessType.map(x => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="businessType"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Default currency</label>
            <Field
              className="form-control"
              placeholder="Default currency"
              component="select"
              name="defaultCurrency"
            >
              <option value={null}>Please select default currency</option>
              {currencies.map(x => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </Field>
            <ErrorMessage
              name="defaultCurrency"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
}
