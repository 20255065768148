import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';
import Icon from 'component/Global/Icons';

export default function UserMenu(props) {
  const [active, setActive] = useState(false);
  const { switchLocale } = props;

  return (
    <aside className="user-menu header__user-menu">
      <button
        className="btn btn-link"
        type="button"
        onClick={switchLocale}
        style={{
          minWidth: 'auto',
          padding: '0 15px',
        }}
      >
        <Icon
          name={`${props.locale === 'en' ? 'lang-ind' : 'lang-en'}`}
          classSvg={`${
            props.locale === 'en' ? 'icon-indonesia' : 'icon-english'
          }`}
        />
      </button>
      <Link
        to="#"
        className={`user-menu__info-box ${active ? 'active' : ''}`}
        onClick={() => setActive(!active)}
      >
        <Icon name="user" classSvg="icon-user" />
        <span className="user-menu__name">
          Hello, {props.displayName || props.email}
        </span>
        <i className="fa fa-angle-down"></i>
      </Link>
      <ul className={`user-menu__menu${active ? '_active' : ''}`}>
        <li className="user-menu__item">
          <Link
            to="/home/profile"
            className={`user-menu__link`}
            onClick={() => setActive(!active)}
          >
            <M id="app.profile" defaultMessage="Profile" />
          </Link>
        </li>
        <li className="user-menu__item">
          <Link
            to="/home/profile/taxes"
            className={`user-menu__link`}
            onClick={() => setActive(!active)}
          >
            <i className="fa fa-percent"></i>{' '}
            <M id="app.taxes" defaultMessage="Taxes" />
          </Link>
        </li>
        <li className="user-menu__item">
          <Link
            to="/home/profile/account-classification"
            className={`user-menu__link`}
            onClick={() => setActive(!active)}
          >
            <i className="fa fa-cog"></i>{' '}
            <M id="app.setting" defaultMessage="Setting" />
          </Link>
        </li>
        <li className="user-menu__item">
          <Link
            className={`user-menu__link`}
            onClick={ev => {
              ev.preventDefault();
              props._logout();
            }}
            to="/"
          >
            <i className="fa fa-sign-out"></i>&nbsp;
            <M id="app.logOut" defaultMessage="Log out" />
          </Link>
        </li>
      </ul>
    </aside>
  );
}
