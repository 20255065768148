import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { toQueryString } from 'helper';

export default function Pagination({ count, offset, limit, path }) {
  if (!count || !path) {
    return '';
  }

  const currentPage = offset / limit + 1;
  const adjacent = [...Array(2)];

  if (count < limit) {
    return '';
  }

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        {currentPage - adjacent.length > 1 && (
          <li className="page-item">
            <Link className="page-link" to={path}>
              First
            </Link>
          </li>
        )}
        {adjacent.map((x, i) => {
          const page = currentPage - (adjacent.length - i);
          const offset = (page - 1) * limit;
          const queryString =
            offset === 0
              ? path
              : `${path}${toQueryString({
                  offset,
                  limit,
                })}`;
          return page > 0 ? (
            <li className="page-item" key={i}>
              <Link className="page-link" to={queryString}>
                {page}
              </Link>
            </li>
          ) : (
            <Fragment key={i} />
          );
        })}
        <li className="page-item active">
          <span className="page-link">{currentPage}</span>
        </li>
        {adjacent.map((x, i) => {
          const page = currentPage + i + 1;
          const queryString = `${path}${toQueryString({
            offset: (page - 1) * limit,
            limit,
          })}`;
          return Math.ceil(count / limit) >= page ? (
            <li className="page-item" key={i}>
              <Link className="page-link" to={queryString}>
                {page}
              </Link>
            </li>
          ) : (
            <Fragment key={i} />
          );
        })}
        {Math.ceil(count / limit) - currentPage > adjacent.length && (
          <li className="page-item">
            <Link
              className="page-link"
              to={toQueryString({
                offset:
                  count % limit > 0
                    ? count - (count % limit)
                    : count - (count % limit) - limit,
                limit,
              })}
            >
              Last
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}
