import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
// import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
import { connect } from 'react-redux';

import ContactForm from 'component/ContactForm';
import Loading from 'component/Global/Loading';

export function ContactsDetail(props) {
  const { _getContacts, idToken, company } = props;

  const [isLoading, setIsLoading] = useState(true);

  const id = get(props, 'match.params.id');
  const one = get(props, 'contacts.one');

  const isNew = id === 'new';
  const title = isNew ? 'New Item' : 'Update Item';
  let initialValues = {
    name: '',
    user: props.uid,
    company: company.id,
    isVendor: '',
    data: {
      hello: '',
      number: '',
    },
  };

  useEffect(() => {
    if (!isNew) {
      if (one && one.id === id) {
        setIsLoading(false);
      } else {
        _getContacts(id, idToken).then(() => {
          setIsLoading(false);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [id]); //eslint-disable-line

  if (!isLoading && id !== 'new' && one) {
    initialValues = {
      id: one.id,
      name: one.name,
      company: company.id,
      isVendor: one.isVendor,
      user: props.uid,
    };
  }

  if (!company) {
    return '';
  }

  function handleSubmit(data) {
    setIsLoading(true);

    const request = {
      name: data.name,
      company: company.id,
      isVendor: data.isVendor,
      user: props.uid,
    };

    if (isNew) {
      return props._postContact(request).then(data => {
        props._push(`/home/contacts`);
      });
    } else {
      request.id = id;
      return props._updateContact(request).then(data => {
        setIsLoading(false);
      });
    }
  }

  function handleDelete() {
    return props._deleteContact(id).then(() => {
      props._push(`/home/contact`);
    });
  }

  if (isLoading) {
    // isLoading
    return <Loading></Loading>;
  }

  return (
    <>
      <Helmet>
        <title>Aiclo Accounting - Home - contacts - {title}</title>
      </Helmet>
      <div className="container">
        <div className="row mb-4 justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="d-flex flex-row justify-content-start align-items-center mb-3">
              <Link
                to={`/home/contacts`}
                className="btn btn-link text-left p-0"
                style={{ minWidth: 'auto' }}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ fontSize: '12px' }}
                ></i>{' '}
                Back
              </Link>
              <h3 className="page-title ml-2">
                |{' '}
                {isNew ? (
                  <M id="app.newContacts" defaultMessage="New contacts" />
                ) : (
                  <M id="app.updateContacts" defaultMessage="Update contacts" />
                )}
              </h3>
            </div>
            <div className="card auths">
              <div className="card-body">
                <ContactForm
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  isNew={isNew}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    contacts: state.contacts,
  };
}

export default connect(mapStateToProps)(ContactsDetail);
