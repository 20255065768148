import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';

export default function AsyncSelectField(props) {
  const [value, setValue] = useState(
    props.field.value && {
      label: props.field.value.name,
      value: props.field.value,
    }
  );

  useEffect(() => {
    // if it is an object, it is sent from the container component
    // if its is a string, that is triggered from the on change event,
    // hence, no need to change the value
    if (props.field.value && typeof props.field.value === 'object') {
      setValue({
        label: props.field.value.name,
        value: props.field.value,
      });
    }
  }, [props.field.value]);

  function promiseOptions(inputValue) {
    return props.resolver(inputValue);
  }

  function handleChange(val) {
    setValue(val);
    props.form.setFieldValue(props.field.name, val ? val.value : null);
  }

  return (
    <AsyncSelect
      isDisabled={props.disabled}
      isClearable
      loadOptions={promiseOptions}
      value={value}
      onChange={handleChange}
      defaultOptions
    />
  );
}
