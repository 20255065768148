import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';
import Icon from 'component/Global/Icons';
import moment from 'moment';

const current = moment().format('YYYY-MM');

const mainMenu = [
  {
    id: 'journaling',
    title: 'Journaling',
  },
  {
    id: 'ledger',
    title: 'Ledgers',
    href: `/home/ledgers?month=${current}`,
  },
  {
    id: 'incomes',
    title: 'Incomes',
    href: `/home/incomes?month=${current}`,
  },
  {
    id: 'expenses',
    title: 'Expenses',
    href: `/home/expenses?month=${current}`,
  },
  {
    id: 'transfers',
    title: 'Transfers',
    href: `/home/transfers?month=${current}`,
  },
  {
    id: 'reporting',
    title: 'Reporting',
  },
  {
    id: 'chartOfAccounts',
    title: 'Chart of Accounts',
    href: '/home/reports/chart-of-accounts',
  },
  {
    id: 'generalLedger',
    title: 'General Ledger',
    href: '/home/reports/general-ledger',
  },
  {
    id: 'journal',
    title: 'Journal',
    href: '/home/reports/journal',
  },
  {
    id: 'cashFlow',
    title: 'Cash Flow',
    href: '/home/reports/cash-flow',
  },
  {
    id: 'balanceSheet',
    title: 'Balance Sheet',
    href: '/home/reports/balance-sheet',
  },
  {
    id: 'profitLoss',
    title: 'Profit & Loss',
    href: '/home/reports/profit-loss',
  },
  {
    id: 'masterData',
    title: 'Master data',
  },
  {
    id: 'projectList',
    title: 'Projects',
    href: '/home/projects',
  },
  {
    id: 'productList',
    title: 'Products',
    href: '/home/products',
  },
  {
    id: 'contact',
    title: 'Contacts',
    href: '/home/contacts',
  },
];

export default function MenuSidebar(props) {
  const { sidebarOpen, handleSidebarOpen } = props;
  return (
    <section
      className="menusidebar-mobile d-flex flex-column"
      style={{
        position: 'fixed',
        bottom: 0,
        left: sidebarOpen ? 0 : '-110vw',
        zIndex: 102,
      }}
    >
      <div className="topLogo">
        <Icon name="logo" classSvg="icon-logo" />
      </div>
      <div className="topbar d-flex flex-row mb-3"></div>

      <ul className="main-menu list-unstyled">
        {mainMenu.map((item, i) => (
          <li key={i} className={`${item.href ? 'menu-items' : 'menu-title'}`}>
            {item.href ? (
              <NavLink
                className="menu-item"
                to={item.href}
                onClick={handleSidebarOpen}
                activeClassName="active"
              >
                <M id={`app.${item.id}`} defaultMessage={item.title} />
              </NavLink>
            ) : (
              <M id={`app.${item.id}`} defaultMessage={item.title} />
            )}
          </li>
        ))}
      </ul>

      {/*<ul className="bottom-menu mt-auto list-unstyled list-inline text-uppercase">
        <li className="list-inline-item" key={0}>
          <a href="/blog/terms-of-use/">Terms &amp; Conditions</a>
        </li>

        <li className="list-inline-item" key={1}>
          <a href="/blog/faq/">Privacy Policy</a>
        </li>
      </ul>*/}
    </section>
  );
}
