export const CREATE_FLASH = 'flash/CREATE_FLASH';
export const DELETE_FLASH = 'flash/DELETE_FLASH';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FLASH:
      return { ...action.payload };
    case DELETE_FLASH:
      return initialState;
    default:
      return state;
  }
};

function flashTimeout(dispatch) {
  setTimeout(() => {
    dispatch({
      type: DELETE_FLASH,
    });
  }, 3000);
}

// Action
export const createFlash = flash => {
  return dispatch => {
    dispatch({
      type: CREATE_FLASH,
      payload: {
        id: Date.now(),
        message: flash.message || flash,
        type: flash.type || 'success',
        persistent: flash.persistent,
      },
    });

    if (!flash.persistent) {
      flashTimeout(dispatch);
    }
  };
};

export const createError = flash => {
  return dispatch => {
    dispatch({
      type: CREATE_FLASH,
      payload: {
        id: Date.now(),
        type: flash.type || 'danger',
        message: flash.message || flash,
        persistent: flash.persistent,
      },
    });

    if (!flash.persistent) {
      flashTimeout(dispatch);
    }
  };
};

export const deleteFlash = id => {
  return dispatch => {
    dispatch({
      type: DELETE_FLASH,
    });
  };
};
