import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
// import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
import { connect } from 'react-redux';

import ProductForm from 'component/ProductForm';
import Loading from 'component/Global/Loading';

export function ProductsDetail(props) {
  const { _getProducts, idToken, company } = props;

  const [isLoading, setIsLoading] = useState(true);

  const id = get(props, 'match.params.id');
  const one = get(props, 'products.one');

  const isNew = id === 'new';
  const title = isNew ? 'New Item' : 'Update Item';
  let initialValues = {
    name: '',
    user: props.uid,
    company: company.id,
    price: '',
    data: {
      hello: '',
      number: '',
    },
  };

  useEffect(() => {
    if (!isNew) {
      if (one && one.id === id) {
        setIsLoading(false);
      } else {
        _getProducts(id, idToken).then(() => {
          setIsLoading(false);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [id]); //eslint-disable-line

  if (id !== 'new' && one) {
    initialValues = {
      id: one.id,
      name: one.name,
      company: company.id,
      price: one.price,
      user: props.uid,
    };
  }

  if (!company) {
    return '';
  }

  function handleSubmit(data) {
    setIsLoading(true);

    const request = {
      name: data.name,
      company: company.id,
      price: data.price,
      user: props.uid,
      data: {
        hello: 'Foo',
        number: 23,
      },
    };

    if (isNew) {
      return props._postProduct(request).then(data => {
        props._push(`/home/products`);
      });
    } else {
      request.id = id;
      return props._updateProduct(request).then(data => {
        setIsLoading(false);
      });
    }
  }

  function handleDelete() {
    return props._deleteProduct(id).then(() => {
      props._push(`/home/products`);
    });
  }

  if (isLoading) return <Loading></Loading>;

  return (
    <>
      <Helmet>
        <title>Aiclo Accounting - Home - Products - {title}</title>
      </Helmet>
      <div className="container">
        <div className="row mb-4 justify-content-md-center">
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="d-flex flex-row justify-content-start align-items-center mb-3">
              <Link
                to={`/home/products`}
                className="btn btn-link text-left p-0"
                style={{ minWidth: 'auto' }}
              >
                <i
                  className="fa fa-chevron-left"
                  style={{ fontSize: '12px' }}
                ></i>{' '}
                Back
              </Link>
              <h3 className="page-title ml-2">
                |{' '}
                {isNew ? (
                  <M id="app.newProducts" defaultMessage="New products" />
                ) : (
                  <M id="app.updateProducts" defaultMessage="Update products" />
                )}
              </h3>
            </div>
            <div className="card auths">
              <div className="card-body">
                <ProductForm
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  isNew={isNew}
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    products: state.products,
  };
}

export default connect(mapStateToProps)(ProductsDetail);
