import { firestore } from 'config/firebase';
// import { push } from 'connected-react-router';

import { snapshotToArray, resolver } from 'helper';

import { createError, createFlash } from './flasher';
import { SUCCESSFUL_LOGOUT } from './auth';

export const LISTEN_COMPANY = 'firestore/LISTEN_COMPANY';
export const PATCH_COMPANY = 'firestore/PATCH_COMPANY';

const initialState = {
  id: null,
};

const companyRef = firestore.collection('company');

export default (state = initialState, action) => {
  switch (action.type) {
    case LISTEN_COMPANY:
      return { ...action.payload };
    case PATCH_COMPANY:
      return {
        ...state,
        ...action.payload,
      };
    case SUCCESSFUL_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// Action
export function createOrUpdateCompany(data) {
  return function(dispatch) {
    if (data.id) {
      return companyRef
        .doc(data.id)
        .update({ ...data })
        .then(docRef => {
          dispatch(createFlash('Company updated.'));
          dispatch({
            type: PATCH_COMPANY,
            payload: data,
          });
          return data;
        })
        .catch(error => {
          throw new Error(error.message);
        });
    } else {
      return companyRef
        .add(data)
        .then(docRef => {
          const result = { ...data, id: docRef.id };
          dispatch(createFlash('Company added.'));
          dispatch({
            type: PATCH_COMPANY,
            payload: result,
          });
          return result;
        })
        .catch(error => {
          dispatch(createError(error.message));
          throw new Error(error.message);
        });
    }
  };
}

export function patchCompany(data) {
  return function(dispatch) {
    return companyRef
      .doc(data.id)
      .update({ ...data })
      .then(docRef => {
        dispatch(createFlash('Company updated.'));
        dispatch({
          type: PATCH_COMPANY,
          payload: data,
        });
        return data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  };
}

export function listenCompanyByContributor(id) {
  return function(dispatch) {
    companyRef
      .where('contributor', 'array-contains', id)
      .onSnapshot(snapshot => {
        if (snapshotToArray(snapshot)[0]) {
          dispatch({
            type: LISTEN_COMPANY,
            payload: snapshotToArray(snapshot)[0],
          });
        }
      });
    return resolver();
  };
}

export function getCompanyByContributor(id) {
  return function(dispatch) {
    return companyRef
      .where('contributor', 'array-contains', id)
      .get()
      .then(querySnapshot => {
        let result;
        querySnapshot.forEach(function(doc) {
          result = doc.data();
          result.id = doc.id;
        });
        dispatch({
          type: LISTEN_COMPANY,
          payload: result,
        });
        return result;
      });
  };
}
