import React, { Fragment } from 'react';
import { Formik, FieldArray, Form, FastField, ErrorMessage } from 'formik';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';

import DateField from 'component/Global/DateField';
import TimeField from 'component/Global/TimeField';
import SelectField from 'component/SelectField';
import MoneyFormGroup from 'component/MoneyFormGroup';
import AsyncSelectField from 'component/Global/AsyncSelectField';

export default function ExpenseForm(props) {
  const { isNew, handleDelete } = props;

  const ValidationSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
    // income: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
    // expense: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
    // wallets: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Required'),
    //   })
    // ),
  });

  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={{
        ...props.initialValues,
      }}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            ...values,
            lines: values.lines.map(x => ({
              ...x,
              amount: parseInt(
                String(x.amount)
                  .split(',')
                  .join(''),
                10
              ),
            })),
          })
          .then(() => actions.setSubmitting(false))
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-sm-12 col-md-4 border-right">
              <div className="form-group">
                <label htmlFor="code">Kode Jurnal</label>
                <FastField
                  className={`form-control ${errors.date &&
                    touched.date &&
                    'error'}`}
                  name="code"
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="projectId">Project</label>
                <FastField
                  className={`form-control ${errors.project &&
                    touched.project &&
                    'error'}`}
                  component={AsyncSelectField}
                  resolver={props.projectApi}
                  name="projectId"
                />
                <ErrorMessage
                  name="projectId"
                  component="small"
                  className="form-text text-danger project-error"
                ></ErrorMessage>
              </div>
              <div className="form-group">
                <label htmlFor="contactId">Contact</label>
                <FastField
                  className={`form-control ${errors.contact &&
                    touched.contact &&
                    'error'}`}
                  component={AsyncSelectField}
                  resolver={props.contactApi}
                  name="contactId"
                ></FastField>
                <ErrorMessage
                  name="contactId"
                  component="small"
                  className="form-text text-danger contact-error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <FastField className="form-control" name="account">
                  {fieldProps => (
                    <SelectField {...fieldProps} options={props.accounts} />
                  )}
                </FastField>
                <ErrorMessage
                  name="account"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Tanggal</label>
                <FastField
                  className="form-control"
                  placeholder="Date and time"
                  name="date"
                  component={DateField}
                />
                <small className="form-text text-success">
                  Use the format : yyyy-mm-dd
                </small>
                <ErrorMessage
                  name="date"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">Waktu</label>
                <FastField
                  className={`form-control ${errors.time &&
                    touched.time &&
                    'error'}`}
                  name="time"
                  component={TimeField}
                />
                <ErrorMessage
                  name="time"
                  component="small"
                  className="form-text text-danger time-error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <FastField
                  className={`form-control ${errors.description &&
                    touched.description &&
                    'error'}`}
                  as="textarea"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  component="small"
                  className="form-text text-danger description-error"
                />
              </div>
            </div>
            {/*<p>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setShowDescription(x => !x)}
                type="button"
              >
                {showDescription ? 'Hide' : 'Show'} description
              </button>
            </p>
            {showDescription && (
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <FastField
                  className="form-control"
                  as="textarea"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            )}*/}
            <div className="col-sm-12 col-md-8">
              <table className="table">
                <thead>
                  <tr className="">
                    <th className="border-top-0" style={{ width: '220px' }}>
                      Account
                    </th>
                    <th className="border-top-0">Description</th>
                    <th className="border-top-0">Amount</th>
                    <th className="border-top-0"></th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="lines"
                    render={arrayHelpers => (
                      <Fragment>
                        {values.lines &&
                          values.lines.map((friend, index) => (
                            <MoneyFormGroup
                              key={index}
                              index={index}
                              account={{
                                label: 'Account',
                                key: `lines.${index}.account`,
                                options: props.accounts,
                              }}
                              name={{
                                label: 'Description',
                                key: `lines.${index}.description`,
                              }}
                              amount={{
                                label: 'Amount',
                                key: `lines.${index}.amount`,
                              }}
                              arrayHelpers={arrayHelpers}
                              allowEmpty
                            />
                          ))}
                        <tr className="">
                          <td colSpan="5">
                            <button
                              type="button"
                              className="btn btn-link px-0 text-left"
                              onClick={() =>
                                arrayHelpers.push({
                                  account: props.accounts[0]
                                    ? props.accounts[0].id
                                    : '',
                                  description: '',
                                  amount: '10000',
                                })
                              }
                            >
                              Add other item
                            </button>
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  />
                </tbody>
              </table>
              <ErrorMessage
                name="expense"
                component="small"
                className="form-text text-muted"
              />
            </div>
          </div>
          <div className="action-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {!isNew && (
              <button
                type="button"
                onClick={handleDelete}
                className="btn btn-outline-danger"
                disabled={isSubmitting}
              >
                <M id="app.delete" defaultMessage="Delete" />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
