import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  passwordConfirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export default function LoginForm(props) {
  return (
    <div>
      <Formik
        className="form"
        validationSchema={ValidationSchema}
        initialValues={{
          password: '',
          passwordConfirm: '',
        }}
        onSubmit={(values, actions) => {
          props
            .onSubmit(values.password)
            .then(updatedUser => {
              actions.setSubmitting(false);
              actions.resetForm();
            })
            .catch(er => {
              actions.setSubmitting(false);
              console.error(er);
            });
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field className="form-control" type="password" name="password" />
              <ErrorMessage
                name="password"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <div className="form-group">
              <label htmlFor="passwordConfirm">Password confirmation</label>
              <Field
                className="form-control"
                type="password"
                name="passwordConfirm"
              />
              <ErrorMessage
                name="passwordConfirm"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
