export const singaporean = [
  {
    code: 'DS',
    name: 'Deemed Supplies',
    percetage: 7,
  },

  {
    code: 'BL',
    name: 'Disallowed expenses',
    percetage: 7,
  },

  {
    code: 'ES33',
    name: 'Exempt',
    percetage: 0,
  },

  {
    code: 'ESN33',
    name: 'Exempt',
    percetage: 0,
  },

  {
    code: 'EP',
    name: 'Exempt Purchases',
    percetage: 0,
  },

  {
    code: 'TX-IES',
    name: 'Exempt Purchases',
    percetage: 0,
  },

  {
    code: 'IGDS',
    name: 'Import under the import GST deferment scheme',
    percetage: 7,
  },

  {
    code: 'IM',
    name: 'Imports',
    percetage: 7,
  },

  {
    code: 'ME',
    name: 'Imports under a special scheme',
    percetage: 0,
  },

  {
    code: '.',
    name: 'No Tax',
    percetage: 0,
  },

  {
    code: 'NR',
    name: 'Non-GST purchases',
    percetage: 0,
  },

  {
    code: 'OP',
    name: 'Out-of-scope purchases',
    percetage: 0,
  },

  {
    code: 'TX-N33',
    name: 'Standard Rated Purchases',
    percetage: 7,
  },

  {
    code: 'TX',
    name: 'Standard Rated Purchases',
    percetage: 7,
  },

  {
    code: 'TX-RE',
    name: 'Standard Rated Purchases',
    percetage: 7,
  },

  {
    code: 'TX-ESS',
    name: 'Standard Rated Purchases',
    percetage: 7,
  },

  {
    code: 'TXCA',
    name: 'Standard Rated Purchases',
    percetage: 7,
  },

  {
    code: 'SR',
    name: 'Standard Rated Supply',
    percetage: 7,
  },

  {
    code: 'SRCA-S',
    name: 'Standard Rated Supply',
    percetage: 0,
  },

  {
    code: 'SRCA-C',
    name: 'Standard Rated Supply',
    percetage: 7,
  },
  {
    code: 'ZP',
    name: 'Zero Rate Purchases',
    percetage: 0,
  },

  {
    code: 'ZR',
    name: 'Zero Rate Supply',
    percetage: 0,
  },
];

export const indonesian = [
  {
    code: 'PPN',
    name: 'Pajak Pertambahan Nilai',
    percentage: 10,
  },

  {
    code: 'PPh 23-4',
    name: 'PPh Pasal 23 Non NPWP',
    percentage: -4,
  },

  {
    code: 'PPh 23-2',
    name: 'PPh Pasal 23 NPWP',
    percentage: -2,
  },

  {
    code: 'PPh 4.2',
    name: 'PPh Pasal 4 Ayat 2',
    percentage: -1,
  },
];

const taxes = {
  Indonesian: indonesian,
  Singaporean: singaporean,
};

export default taxes;
