// import moment from 'moment';
import axios from 'axios';

import { SUCCESSFUL_LOGOUT } from './auth';
import { createFlash, createError } from './flasher';
import { patchCompany } from './company';
import { toQueryString } from 'helper';

export const GET_ACCOUNT = 'api/GET_ACCOUNT';
export const GET_ACCOUNTS = 'api/GET_ACCOUNTS';
export const GET_BALANCE = 'api/GET_BALANCE';
export const GET_ACTIVE = 'api/GET_ACTIVE';
export const UPDATE_ACCOUNT = 'api/UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'api/DELETE_ACCOUNT';

const initialState = {
  list: [],
  one: null,
  active: [],
  balance: [],
};

const apiUrl = process.env.REACT_APP_TRANSACTION_API;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        list: [],
        one: action.payload,
      };
    case GET_ACCOUNTS:
      return {
        ...state,
        list: action.payload,
        one: null,
      };
    case GET_BALANCE:
      return {
        ...state,
        balance: action.payload,
      };
    case GET_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };
    case SUCCESSFUL_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export function getAccounts(id = null, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const requestUrl = id ? `${apiUrl}/accounts/${id}` : `${apiUrl}/accounts`;
    const request = {
      method: 'GET',
      url: requestUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        if (id) {
          dispatch({
            type: GET_ACCOUNT,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_ACCOUNTS,
            payload: data.data,
          });
        }
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function getBalance(params, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const requestUrl = `${apiUrl}/accounts/a/balance${
      typeof params === 'string' ? params : toQueryString(params)
    }`;
    const request = {
      method: 'GET',
      url: requestUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch({
          type: GET_BALANCE,
          payload: data.data,
        });
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function getActive(params, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const requestUrl = `${apiUrl}/accounts/a/active${
      typeof params === 'string' ? params : toQueryString(params)
    }`;
    const request = {
      method: 'GET',
      url: requestUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch({
          type: GET_ACTIVE,
          payload: data.data,
        });
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export async function _postAccount(data, token) {
  // const token = await getToken();
  const request = {
    method: 'POST',
    url: `${apiUrl}/accounts`,
    data,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
  return axios(request);
}

export function postAccount(data, token) {
  return async function(dispatch) {
    return _postAccount(data, token)
      .then(data => {
        dispatch(createFlash('Success'));
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function updateAccount(data, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'PUT',
      url: `${apiUrl}/accounts/${data.id}`,
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function deleteAccount(id, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'DELETE',
      url: `${apiUrl}/accounts/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: DELETE_ACCOUNT,
          payload: id,
        });
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function registerAccountChart(tree, uid, companyId, token) {
  return async function(dispatch) {
    let result = [];
    for (var lvl1 of tree) {
      for (var lvl2 of lvl1.children) {
        for (var lvl3 of lvl2.children) {
          const data = {
            code: lvl3.code,
            name: lvl3.name,
            currency: lvl3.currency,
            company: companyId,
            user: uid,
            data: {
              ...lvl3.data,
              slug: lvl3.slug,
            },
          };
          result.push(await _postAccount(data, token));
        }
      }
    }

    const savedTree = tree.map((x, i) => ({
      ...x,
      children: x.children.map((xx, ii) => ({
        ...xx,
        children: xx.children.map((xxx, iii) => {
          return result.find(xxxx => xxxx.data.name === xxx.name).data;
        }),
      })),
    }));

    await dispatch(
      patchCompany({
        id: companyId,
        accounts: savedTree,
      })
    );

    return savedTree;
  };
}
