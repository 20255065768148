import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';

const COLORS = ['#24CAE2', '#FFBF01', '#F04057'];

const profitLoss = [
  {
    name: 'Page A',
    expense: 4000,
    income: 2400,
  },
  {
    name: 'Page B',
    expense: 3000,
    income: 1398,
  },
  {
    name: 'Page C',
    expense: 2000,
    income: 9800,
  },
  {
    name: 'Page D',
    expense: 2780,
    income: 3908,
  },
  {
    name: 'Page E',
    expense: 1890,
    income: 4800,
  },
  {
    name: 'Page F',
    expense: 2390,
    income: 3800,
  },
  {
    name: 'Page G',
    expense: 3490,
    income: 4300,
  },
];

export default function BarChartWidget(props) {
  const { name, ...label } = props.data ? props.data[0] : profitLoss[0];
  const labelKey = Object.keys(label);
  return (
    <div className="card card-dash h-100">
      <div className="card-body">
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <h2 className="blok-title mb-0">{props.title}</h2>
          {
            // <form>
            //   <div className="form-group mb-0">
            //     <select className="form-control">
            //       <option>Current week</option>
            //       <option>Current month</option>
            //       <option>Current year</option>
            //     </select>
            //   </div>
            // </form>
          }
        </div>
        <ResponsiveContainer width="100%" height="auto" aspect={1.8}>
          <BarChart
            data={props.data || profitLoss}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {labelKey.map((entry, index) => (
              <Bar dataKey={entry} fill={COLORS[index % COLORS.length]} />
            ))}
          </BarChart>
        </ResponsiveContainer>
        <p className="mb-0">in Rp1.000.000</p>
      </div>
    </div>
  );
}
