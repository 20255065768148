import React, { Fragment, useState } from 'react';
import TaxForm from 'component/TaxForm';
import shortid from 'shortid';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';

export default function Taxes(props) {
  const [onEdit, setEdit] = useState(null);
  const taxes = get(props, 'company.taxes');

  function handleNewTax(data) {
    const newTax = {
      ...data,
      id: shortid.generate(),
    };
    const result = taxes ? [...taxes, newTax] : [newTax];

    return props._createOrUpdateCompany({
      ...props.company,
      taxes: result,
    });
  }

  function handleUpdateTax(data) {
    return props._createOrUpdateCompany({
      ...props.company,
      taxes: [...taxes.map(x => (x.id === data.id ? data : x))],
    });
  }

  function handleDeleteTax(data) {
    return props._createOrUpdateCompany({
      ...props.company,
      taxes: [...taxes.filter(x => x.id !== data)],
    });
  }

  function handleStageTax(data) {
    setEdit(onEdit === data ? null : data);
  }

  return (
    <Fragment>
      <h2>
        <M id="app.listOfTaxes" defaultMessage="List of taxes" />
      </h2>
      <div className="row">
        <div className="col-8">
          {taxes &&
            taxes.map((x, i) => {
              return (
                <div key={i} className="card mb-2">
                  <div className="card-header d-flex">
                    <span>{x.name}</span>
                    <button
                      className="btn btn-sm ml-auto btn-outline-secondary"
                      onClick={() => handleStageTax(x.id)}
                    >
                      <M id="app.edit" defaultMessage="Edit" />
                    </button>
                  </div>
                  <div className="card-body">
                    {onEdit === x.id && (
                      <Fragment>
                        <TaxForm
                          onSubmit={handleUpdateTax}
                          onDelete={() => handleDeleteTax(x.id)}
                          initialValues={x}
                        />
                      </Fragment>
                    )}
                    {onEdit !== x.id && (
                      <Fragment>
                        <strong>{x.code}</strong> <span>{x.percentage} %</span>
                      </Fragment>
                    )}
                  </div>
                </div>
              );
            })}
          <div className="card mb-2">
            <div className="card-header d-flex">
              <span>
                <M id="app.addNew" defaultMessage="Add new" />
              </span>
            </div>
            <div className="card-body">
              <TaxForm onSubmit={handleNewTax} />
            </div>
          </div>
        </div>
        <div className="col-4" />
      </div>
    </Fragment>
  );
}
