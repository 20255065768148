import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

// const defaultValues = {
//   name: '',
// };

export default function VendorForm(props) {
  const { isNew, onDelete } = props;
  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={{
        ...props.initialValues,
      }}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            ...values,
          })
          .then(() => {
            // actions.setSubmitting(false)
          })
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Project Name</label>
            <Field
              className={`form-control ${errors.name &&
                touched.name &&
                'error'}`}
              type="text"
              name="name"
              placeholder="Enter Project name"
            />
            <ErrorMessage
              name="name"
              component="small"
              className="form-text text-danger name-error"
            />
          </div>
          <div className="action-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {!isNew && (
              <button
                type="button"
                onClick={onDelete}
                className="btn btn-outline-danger"
                disabled={isSubmitting}
              >
                <M id="app.delete" defaultMessage="Delete" />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
