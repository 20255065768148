import React, { Fragment, useState, useEffect } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';

import DateRangeForm from 'component/DateRangeForm';
import FullPageLoader from 'component/FullPageLoader';
import Modal from 'component/Modal';
import ReportTool from 'component/ReportTool';

import { toQueryObject, exportToCsv, exportToPdf } from 'helper';

const docDefinition = {
  content: [
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', '*', '*', 'auto', 'auto'],
      },
    },
  ],
};

export default function ReportGeneralLedger(props) {
  const pageQuery = get(props, 'location.search');
  const transactions = get(props, 'transactions.list');
  const balance = get(props, 'accounts.balance');
  const query = toQueryObject(pageQuery);
  const preset = query.preset || 'this-month';
  const start = query.start || null;
  const end = query.end || null;
  const [loading, setLoading] = useState(true);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [datePicker, setDatePicker] = useState(false);

  let startDate;
  let endDate;
  let title;
  let m;

  switch (preset) {
    case 'this-month':
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'last-month':
      m = moment().subtract(1, 'months');
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'this-year':
      m = moment();
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    case 'last-year':
      m = moment().subtract(1, 'years');
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    default:
      // default to this-month
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
  }
  if (start && end) {
    title = `${moment(start).format('DD MMMM YYYY')} - ${moment(end).format(
      'DD MMMM YYYY'
    )}`;
  }

  const baseQueryParameter = {
    company: props.company.id,
    limit: 20,
    offset: 0,
    includeLines: 1,
    start: start || startDate,
    end: end || endDate,
  };

  const initialRange = {
    start: start || startDate,
    end: end || endDate,
  };

  useEffect(() => {
    props._throttleTransactions(baseQueryParameter).then(() => {
      setLoading(false);
    });
  }, [startDate, endDate, start, end]); //eslint-disable-line

  useEffect(() => {
    props
      ._getBalance({
        company: props.company.id,
        date: startDate,
      })
      .then(data => {
        setLoadingBalance(false);
        // setInitialBalance(data);
      });
  }, [startDate]); //eslint-disable-line

  function handleCustomRange(data) {
    setDatePicker(false);
    setLoading(true);
    props
      ._push(
        `/home/reports/general-ledger/?start=${data.start}&end=${data.end}`
      )
      .then(() => {
        setLoading(false);
      });
  }

  function generateTable() {
    const csvTable = [
      ['Type', 'Date', 'Description', 'Project', 'Debit', 'Credit'],
    ];

    accountsCalculated.forEach(x => {
      csvTable.push([x.account.name, '', '', '', '', '']);
      x.lines.forEach(xx => {
        csvTable.push([
          xx.transaction.type.toUpperCase(),
          moment(xx.transaction.date).format('DD MMMM YYYY'),
          xx.description,
          '',
          xx.amount > 0 ? xx.amount : 0,
          xx.amount < 0 ? -xx.amount : 0,
        ]);
      });
    });
    return csvTable;
  }

  function handleDownloadPdf() {
    docDefinition.content[0].table.body = generateTable();
    exportToPdf('general-ledger.pdf', docDefinition);
  }

  function handleDownloadCsv() {
    exportToCsv('general-ledger.csv', generateTable());
  }

  const byAccount = {};
  for (var trx of transactions) {
    const { lines, ...detail } = trx;
    if (lines) {
      for (var line of lines) {
        if (byAccount[line.account.id]) {
          const { account, ...lineDetail } = line;
          byAccount[line.account.id].lines.push({
            ...lineDetail,
            transaction: detail,
          });
        } else {
          const { account, ...lineDetail } = line;
          byAccount[line.account.id] = {
            account,
            lines: [
              {
                ...lineDetail,
                transaction: detail,
              },
            ],
          };
        }
      }
    }
  }
  const accounts = [];
  for (var account in byAccount) {
    accounts.push(byAccount[account]);
  }
  const accountsCalculated = accounts.map(x => {
    const startBalance =
      (!loadingBalance &&
        balance.find(xx => xx.id === x.account.id) &&
        balance.find(xx => xx.id === x.account.id).balance_at_date) ||
      0;
    const mutation = x.lines.reduce((sum, xx) => {
      return sum + xx.amount;
    }, 0);
    return {
      ...x,
      lines: x.lines.sort((a, b) => a.transaction.date > b.transaction.date),
      startBalance,
      mutation,
      endBalance: startBalance + mutation,
    };
  });

  // debugger;

  return (
    <Fragment>
      <h2>
        <M id="app.generalLedger" defaultMessage="General Ledger" />
      </h2>
      <h4>Periode : {title}</h4>

      <div className="mb-5">
        <table className="table table-sm">
          <thead className="thead-light">
            <tr>
              <th>
                <M id="app.type" defaultMessage="Type" />
              </th>
              <th>
                <M id="app.date" defaultMessage="Date" />
              </th>
              <th>
                <M id="app.description" defaultMessage="Description" />
              </th>
              <th>
                <M id="app.project" defaultMessage="Project" />
              </th>
              <th className="text-right">
                <M id="app.debit" defaultMessage="Debit" />
              </th>
              <th className="text-right">
                <M id="app.credit" defaultMessage="Credit" />
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.length === 0 && (
              <tr>
                <td>no entry</td>
              </tr>
            )}
            {accountsCalculated.map(x => (
              <Fragment key={x.account.id}>
                <tr className="table-secondary">
                  <td colSpan="6">{x.account.name.toUpperCase()}</td>
                </tr>
                {x.lines &&
                  x.lines.map(xx => {
                    return (
                      <tr key={xx.id}>
                        <td>{xx.transaction.type.toUpperCase()}</td>
                        <td>
                          {moment(xx.transaction.date).format('DD MMMM YYYY')}
                        </td>
                        <td>{xx.description}</td>
                        <td></td>
                        <td className="text-right code">
                          {xx.amount > 0 && xx.amount.toLocaleString()}
                        </td>
                        <td className="text-right code">
                          {xx.amount < 0 && (-xx.amount).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
                <tr className="table-secondary">
                  <td colSpan="6">
                    {!loadingBalance && (
                      <Fragment>
                        <span className="badge badge-secondary">
                          <span>SALDO AWAL : </span>
                          <span>{x.startBalance.toLocaleString()}</span>
                        </span>{' '}
                        <span className="badge badge-primary float-right mt-1">
                          <span>SALDO AKHIR : </span>
                          <span>{x.endBalance.toLocaleString()}</span>
                        </span>{' '}
                      </Fragment>
                    )}
                    <span className="badge badge-info">
                      <span>MUTASI : </span>
                      <span>{x.mutation.toLocaleString()}</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6">&nbsp;</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <ReportTool
        baseUrl={`/home/reports/general-ledger`}
        onOpenPicker={() => setDatePicker(true)}
        onDownloadPdf={handleDownloadPdf}
        onDownloadCsv={handleDownloadCsv}
      />

      <FullPageLoader show={loading} />
      <Modal show={datePicker} onClose={() => setDatePicker(false)}>
        <DateRangeForm
          onSubmit={handleCustomRange}
          initialValues={initialRange}
        />
      </Modal>
    </Fragment>
  );
}
