import React, { Fragment } from 'react';

export default function Credit(props) {
  return (
    <div className="container">
      <h1>Credit</h1>
      <Fragment>
        <div>
          Icons made by{' '}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </Fragment>
    </div>
  );
}
