export const defaultAccounts = [
  {
    name: 'Harta',
    children: [
      {
        name: 'Kas',
        cashFlow: 'cashBank',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Kas Kecil',
          },
          {
            name: 'Kas',
          },
        ],
      },
      {
        name: 'Bank',
        cashFlow: 'cashBank',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Bank',
          },
        ],
      },
      {
        name: 'Piutang Usaha',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Piutang Usaha',
          },
        ],
      },
      {
        name: 'Piutang Lain',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Piutang Giro',
          },
          {
            name: 'Piutang Karyawan',
          },
          {
            name: 'Piutang Lain',
          },
          {
            name: 'Cadangan Kerugian Piutang',
          },
        ],
      },
      {
        name: 'Uang Muka Dibayar',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Uang Muka Pembelian',
          },
          {
            name: 'Uang Muka Pembelian Harta Tetap',
          },
        ],
      },
      {
        name: 'Pajak Dibayar Dimuka',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Pajak Dibayar Dimuka',
          },
        ],
      },
      {
        name: 'Biaya Dibayar Dimuka',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Sewa Dibayar di Muka',
          },
          {
            name: 'Asuransi Dibayar di Muka',
          },
          {
            name: 'Sewa Dibayar di Muka Lain',
          },
        ],
      },
      {
        name: 'Biaya Belum Ditagihkan',
        cashFlow: 'operating',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Biaya Belum Ditagihkan',
          },
        ],
      },
      {
        name: 'Investasi',
        cashFlow: 'investing',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Investasi Saham',
          },
        ],
      },
      {
        name: 'Harta Tetap Berwujud',
        cashFlow: 'investing',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Harta Tetap',
          },
          {
            name: 'Tanah',
          },
          {
            name: 'Gedung',
          },
          {
            name: 'Mesin & Peralatan',
          },
          {
            name: 'Kendaraan',
          },
          {
            name: 'Harta Lainnya',
          },
        ],
      },
      {
        name: 'Akumulasi Penyusutan Harta Tetap',
        cashFlow: 'investing',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Akumulasi Penyusutan Harta Tetap',
          },
          {
            name: 'Accumulated Depreciation Gedung',
          },
          {
            name: 'Accumulated Depreciation Mesin & Peralatan',
          },
          {
            name: 'Accumulated Depreciation Kendaraan',
          },
          {
            name: 'Accumulated Depreciation Harta Lainnya',
          },
        ],
      },
      {
        name: 'Harta Tetap Tidak Berwujud',
        cashFlow: 'investing',
        balanceSheet: 'assets',
        children: [
          {
            name: 'Hak Merek',
          },
          {
            name: 'Hak Cipta',
          },
          {
            name: 'Good Will',
          },
        ],
      },
    ],
  },
  {
    name: 'Kewajiban',
    children: [
      {
        name: 'Utang Usaha',
        cashFlow: 'operating',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Utang Usaha',
          },
        ],
      },
      {
        name: 'Utang Lain',
        cashFlow: 'operating',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Persediaan Dikirim Belum Ditagihkan',
          },
          {
            name: 'Utang Giro',
          },
          {
            name: 'Utang Gaji & Upah',
          },
          {
            name: 'Utang Komisi Penjualan',
          },
        ],
      },
      {
        name: 'Uang Muka Diterima',
        cashFlow: 'operating',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Uang Muka Penjualan',
          },
        ],
      },
      {
        name: 'Pendapatan Belum Ditagihkan',
        cashFlow: 'operating',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Pendapatan Belum Ditagihkan',
          },
        ],
      },
      {
        name: 'Utang Pajak',
        cashFlow: 'operating',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Utang Pajak',
          },
        ],
      },
      {
        name: 'Utang Jangka Panjang',
        cashFlow: 'financing',
        balanceSheet: 'liabilities',
        children: [
          {
            name: 'Utang Bank',
          },
          {
            name: 'Utang Pembiayaan',
          },
        ],
      },
    ],
  },
  {
    name: 'Modal',
    children: [
      {
        name: 'Modal',
        cashFlow: 'financing',
        balanceSheet: 'equity',
        children: [
          {
            name: 'Modal Disetor',
          },
          {
            name: 'Saham Biasa',
          },
        ],
      },
      {
        name: 'Laba',
        cashFlow: 'financing',
        balanceSheet: 'equity',
        children: [
          {
            name: 'Laba ditahan',
          },
          {
            name: 'Laba Tahun Berjalan',
          },
        ],
      },
    ],
  },
  {
    name: 'Pendapatan',
    children: [
      {
        name: 'Pendapatan Usaha',
        profitLoss: 'income',
        cashFlow: 'operating',
        balanceSheet: 'equity',
        children: [
          {
            name: 'Pendapatan Jasa',
          },
          {
            name: 'Potongan Penjualan',
          },
        ],
      },
      {
        name: 'Pendapatan Lain',
        profitLoss: 'income',
        cashFlow: 'operating',
        balanceSheet: 'equity',
        children: [
          {
            name: 'Pendapatan Lain',
          },
        ],
      },
    ],
  },
  {
    name: 'Beban Atas Pendapatan',
    children: [
      {
        name: 'Beban Atas Pendapatan',
        profitLoss: 'expenseOnIncome',
        cashFlow: 'operating',
        children: [
          {
            name: 'Biaya Jasa',
          },
          {
            name: 'Potongan Pembelian',
          },
        ],
      },
    ],
  },
  {
    name: 'Beban Operasional',
    children: [
      {
        name: 'Beban Pemasaran Dan Penjualan',
        profitLoss: 'operationalExpense',
        cashFlow: 'operating',
        children: [
          {
            name: 'Beban Iklan & Promosi',
          },
          {
            name: 'Beban Komisi Penjualan',
          },
          {
            name: 'Beban Piutang Tak Tertagih',
          },
        ],
      },
      {
        name: 'Beban Administrasi Dan Umum',
        profitLoss: 'operationalExpense',
        cashFlow: 'operating',
        children: [
          {
            name: 'Beban Gaji & Upah',
          },
          {
            name: 'Beban Staff Ahli & Perizinan',
          },
          {
            name: 'Beban Sewa Kantor',
          },
          {
            name: 'Beban Listrik',
          },
          {
            name: 'Beban Air',
          },
          {
            name: 'Beban Telepon',
          },
          {
            name: 'Beban Internet',
          },
          {
            name: 'Beban Perlengkapan',
          },
        ],
      },
      {
        name: 'Beban Operasional Lain',
        profitLoss: 'operationalExpense',
        cashFlow: 'operating',
        children: [
          {
            name: 'Beban Lain',
          },
        ],
      },
    ],
  },
  {
    name: 'Beban Non Operasional',
    children: [
      {
        name: 'Beban Penyusutan',
        profitLoss: 'nonOperationalExpense',
        cashFlow: 'investing',
        children: [
          {
            name: 'Penyusutan Harta Tetap',
          },
          {
            name: 'Depreciation Gedung',
          },
          {
            name: 'Depreciation Mesin & Peralatan',
          },
          {
            name: 'Depreciation Kendaraan',
          },
          {
            name: 'Depreciation Harta Lainnya',
          },
        ],
      },
    ],
  },
  {
    name: 'Pendapatan Lain',
    children: [
      {
        name: 'Pendapatan Luar Usaha',
        profitLoss: 'otherIncome',
        cashFlow: 'operating',
        children: [
          {
            name: 'Laba (Rugi) Selisih Kurs - Unrealize',
          },
          {
            name: 'Laba (Rugi) Selisih Kurs - Realize',
          },
          {
            name: 'Laba (Rugi) Penjualan Harta Tetap',
          },
        ],
      },
    ],
  },
  {
    name: 'Beban Lain',
    children: [
      {
        name: 'Beban Luar Usaha',
        profitLoss: 'otherExpense',
        cashFlow: 'operating',
        children: [
          {
            name: 'Beban Bunga Bank',
          },
          {
            name: 'Beban Jasa Bank',
          },
        ],
      },
      {
        name: 'Beban Pajak',
        profitLoss: 'otherExpense',
        cashFlow: 'operating',
        children: [
          {
            name: 'Beban Pajak Penghasilan',
          },
        ],
      },
    ],
  },
];

export default defaultAccounts;
