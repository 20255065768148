import React, { Fragment, useEffect } from 'react';
import AccountChartEditor from 'component/AccountChartEditor';

import get from 'lodash/get';
import shortid from 'shortid';

import { defaultAccounts } from 'constant';

export default function ProfileAccountClassification(props) {
  const accounts = defaultAccounts || get(props, 'company.accounts');

  useEffect(() => {
    props._getActive({
      company: props.company.id,
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    // save id if accounts doesn't have id
    if (props.company.accounts && !props.company.accounts[0].id) {
      const accountsWithId = props.company.accounts.map(x => ({
        ...x,
        id: shortid.generate(),
        children: x.children.map(xx => ({
          ...xx,
          id: shortid.generate(),
        })),
      }));

      props
        ._createOrUpdateCompany({
          id: props.company.id,
          accounts: accountsWithId,
        })
        .then(() => {});
    }
  }, [accounts]); //eslint-disable-line

  // if (!props.company.accounts[0].id) {
  //   return <p>loading</p>;
  // }

  return (
    <Fragment>
      <AccountChartEditor
        company={props.company}
        accounts={props.accounts}
        onPostAccount={props._postAccount}
        onUpdateAccount={props._updateAccount}
        onDeleteAccount={props._deleteAccount}
        onUpdateCompany={props._createOrUpdateCompany}
      />
    </Fragment>
  );
}
