import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getContacts,
  postContact,
  updateContact,
  deleteContact,
} from 'reducer/contacts';

import Detail from './Detail';
import List from './List';

export function Contacts(props) {
  return (
    <Switch>
      <Route
        render={params => <Detail {...props} {...params} />}
        path="/home/contacts/:id"
        exact
      />
      <Route
        render={params => <List {...props} {...params} />}
        path="/home/contacts"
        exact
      />
    </Switch>
  );
}

function mapDispatchToProps(dispatch, props) {
  return {
    _getContacts: (id, params) =>
      dispatch(getContacts(id, props.token, params)),
    _postContact: data => dispatch(postContact(data, props.token)),
    _updateContact: data => dispatch(updateContact(data, props.token)),
    _deleteContact: data => dispatch(deleteContact(data, props.token)),
  };
}

export default connect(null, mapDispatchToProps)(Contacts);
