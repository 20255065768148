import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';

export default function ReportTool(props) {
  return (
    <nav className="navbar fixed-bottom navbar-light bg-light">
      <div className="container">
        {!props.hideDateFilter && (
          <Fragment>
            <form className="form-inline">
              <Link
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                to={`${props.baseUrl}`}
              >
                <M id="app.thisMonth" defaultMessage="This month" />
              </Link>{' '}
              <Link
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                to={`${props.baseUrl}?preset=last-month`}
              >
                <M id="app.lastMonth" defaultMessage="Last month" />
              </Link>{' '}
              <Link
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                to={`${props.baseUrl}?preset=this-year`}
              >
                <M id="app.thisYear" defaultMessage="This year" />
              </Link>{' '}
              <Link
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                to={`${props.baseUrl}?preset=last-year`}
              >
                <M id="app.lastYear" defaultMessage="Last year" />
              </Link>{' '}
              <button
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                onClick={props.onOpenPicker}
              >
                <M
                  id="app.setCustomPeriode"
                  defaultMessage="Set custom periode"
                />
              </button>
            </form>
          </Fragment>
        )}
        {(props.onDownloadPdf || props.onDownloadCsv) && (
          <form className="form-inline ml-auto">
            {props.onDownloadPdf && (
              <button
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                onClick={props.onDownloadPdf}
              >
                <M id="app.download" defaultMessage="Download PDF" />
              </button>
            )}{' '}
            {props.onDownloadCsv && (
              <button
                type="button"
                className="btn btn-secondary btn-sm mr-2"
                onClick={props.onDownloadCsv}
              >
                <M id="app.download" defaultMessage="Download CSV" />
              </button>
            )}
          </form>
        )}
      </div>
    </nav>
  );
}
