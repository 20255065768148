import { intlReducer, updateIntl } from 'react-intl-redux';
import locales from 'locales';

const initialState = {
  defaultLocale: 'en',
  locale: 'en',
};

export default (state = initialState, action) => {
  return intlReducer(state, action);
};

// Action
export const switchLocale = locale => {
  const data = {
    locale,
    messages: locales[locale],
  };
  return dispatch => {
    dispatch(updateIntl(data));
  };
};
