import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import './TimeField.scss';

import Pop from 'component/Global/Pop';

import { timeMask } from 'helper';

export default function DateSelect({ field, form }) {
  const dateParts = field.value.split(':');

  const [active, setActive] = useState(false);
  const [hour, setHour] = useState(dateParts[1]);
  const [minute, seMinute] = useState(dateParts[2]);

  const hourOption = [];
  for (let i = 0; i <= 23; i++) {
    hourOption.push(i);
  }
  const minuteOption = [];
  for (let i = 0; i <= 59; i++) {
    minuteOption.push(i);
  }

  useEffect(() => {
    const newDateParts = field.value.split(':');
    setHour(newDateParts[0]);
    seMinute(newDateParts[1]);
  }, [field.value]); //eslint-disable-line

  useEffect(() => {
    if (hour && minute && field.value !== `${hour}:${minute}`) {
      form.setFieldValue(field.name, `${hour}:${minute}`);
    }
  }, [hour, minute]); //eslint-disable-line

  function handleShow() {
    setActive(true);
  }

  function pad(x) {
    return x < 10 ? `0${x}` : `${x}`;
  }

  function handleHide() {
    setActive(false);
  }

  return (
    <div className="time-field">
      <Pop show={active} onClose={handleHide}>
        <div className="card picker">
          <div className="card-body p-2">
            <div className="form-row">
              <div className="col">
                <select
                  name=""
                  id=""
                  className="form-control form-control-sm"
                  value={hour}
                  onChange={ev => setHour(ev.target.value)}
                >
                  {hourOption.map((x, i) => (
                    <option key={x} value={pad(i + 1)}>
                      {pad(i + 1)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  name=""
                  id=""
                  className="form-control form-control-sm"
                  value={minute}
                  onChange={ev => seMinute(ev.target.value)}
                >
                  {minuteOption.map(x => (
                    <option key={x} value={pad(x)}>
                      {pad(x)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Pop>
      <MaskedInput
        {...field}
        onClick={handleShow}
        className="form-control"
        type="text"
        autoComplete="none"
        keepCharPositions={true}
        mask={timeMask}
      />
    </div>
  );
}
