import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import jwt from 'jsonwebtoken';
import moment from 'moment';

import { createError } from 'reducer/flasher';
import { logout, getToken } from 'reducer/auth';

export function Private(props) {
  useEffect(() => {
    if (!props.uid) {
      props.push('/login');
    }
    if (props.token) {
      const decoded = jwt.decode(props.token);
      const now = moment();
      const expire = moment.unix(decoded.exp);
      if (now.isAfter(expire)) {
        props.getToken();
      }
    }
  });

  return props.uid ? <Fragment>{props.children}</Fragment> : '';
}

function mapStateToProps(state) {
  return {
    uid: get(state, 'auth.user.uid'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createError: message => dispatch(createError(message)),
    push: to => dispatch(push(to)),
    logout: () => dispatch(logout()),
    getToken: () => dispatch(getToken()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Private);
