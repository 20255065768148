import React, { Fragment } from 'react';

export default function AccountGroup(props) {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th colSpan="2">
            <div className="py-3 text-center">{props.title}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.items.children
          .filter(x => x.balance !== 0)
          .map(x => (
            <Fragment key={x.id}>
              <tr className="table-secondary">
                <td colSpan="2">{x.name}</td>
              </tr>
              {x.children
                .filter(xx => xx.balance !== 0)
                .map(xx => (
                  <tr key={xx.id}>
                    <td>{xx.name}</td>
                    <td className="text-right">
                      {xx.balance.toLocaleString()}
                    </td>
                  </tr>
                ))}
              <tr className="text-info">
                <td>Total {x.name}</td>
                <td className="text-right">{x.balance.toLocaleString()}</td>
              </tr>
            </Fragment>
          ))}
        <tr className="text-info font-weight-bold">
          <td>
            <div className="py-3">Total {props.title}:</div>
          </td>
          <td className=" text-right">
            <div className="py-3">{props.items.balance.toLocaleString()}</div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
