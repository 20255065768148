import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'component/Global/Icons';

export default function CashFlowWidget(props) {
  const cashBank = props.cashFlow.items.cashBank;
  const linkTo = '/home/reports/cash-flow';
  const title = 'Cash Flow';
  const icon = 'icon-laba-minus';
  const note =
    cashBank.balance > 0
      ? 'Bisnis Anda Tampak Baik'
      : 'Bisnis Anda Tampak Tidak Baik';

  return (
    <div className="card card-dash">
      <div className="card-body">
        <div className="card-dash__condition">
          <div className="card-dash__summary">
            <div className="card-dash__content">
              <h2>{note}</h2>
              <Icon name="laba" classSvg={icon} />
              <div className="card-dash__amount">
                {props.initial !== 0 && (
                  <>
                    <span className="">
                      <sup>{props.currency}</sup>
                      {(props.initial || 0).toLocaleString()}
                    </span>
                    <i className="fas fa-arrow-right mx-2"></i>
                  </>
                )}
                <span className="">
                  <sup>{props.currency}</sup>
                  {(props.initial - cashBank.balance || 0).toLocaleString()}
                </span>
              </div>
              <p className="card-dash__text">
                <Link to={linkTo}>{title}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
