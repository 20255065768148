import React, { useState, useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import './DateField.scss';

import Pop from 'component/Global/Pop';

import { dateMask } from 'helper';
import { months } from 'constant';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy-mm-dd');
// const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy-mm-dd HH:MM');

export default function DateSelect({ field, form }) {
  const now = new Date();
  const yearMin = now.getFullYear() - 10;
  const yearMax = now.getFullYear();
  const dateParts = field.value.split('-');

  const [active, setActive] = useState(false);
  const [year, setYear] = useState(dateParts[0]);
  const [month, setMonth] = useState(dateParts[1]);
  const [day, setDay] = useState(dateParts[2]);
  const maxDay = new Date(year, month, 0).getDate();

  const dayOption = [];
  for (let i = 1; i <= maxDay; i++) {
    dayOption.push(i);
  }
  const yearOption = [];
  for (let i = yearMax; i > yearMin; i--) {
    yearOption.push(i);
  }

  useEffect(() => {
    const newDateParts = field.value.split('-');
    setYear(newDateParts[0]);
    setMonth(newDateParts[1]);
    if (parseInt(newDateParts[2], 10) > maxDay) {
      setDay(maxDay);
    } else {
      setDay(newDateParts[2]);
    }
  }, [field.value]); //eslint-disable-line

  useEffect(() => {
    const value = `${year}-${month}-${
      parseInt(day, 10) > maxDay ? maxDay : day
    }`;

    form.setFieldValue(field.name, value);
  }, [year, month, day]); //eslint-disable-line

  function handleShow() {
    setActive(true);
  }

  function pad(x) {
    return x < 10 ? `0${x}` : `${x}`;
  }

  function handleHide() {
    setActive(false);
  }

  return (
    <div className="date-field">
      <Pop show={active} onClose={handleHide}>
        <div className="card picker">
          <div className="card-body p-2">
            <div className="form-row">
              <div className="col">
                <select
                  name=""
                  id=""
                  className="form-control form-control-sm"
                  value={year}
                  onChange={ev => setYear(parseInt(ev.target.value, 10))}
                >
                  {yearOption.map(x => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  name=""
                  id=""
                  className="form-control form-control-sm"
                  value={month}
                  onChange={ev => setMonth(ev.target.value)}
                >
                  {months.map((x, i) => (
                    <option key={x} value={pad(i + 1)}>
                      {pad(i + 1)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col">
                <select
                  name=""
                  id=""
                  className="form-control form-control-sm"
                  value={day}
                  onChange={ev => setDay(ev.target.value)}
                >
                  {dayOption.map(x => (
                    <option key={x} value={pad(x)}>
                      {pad(x)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </Pop>
      <MaskedInput
        {...field}
        onClick={handleShow}
        className="form-control"
        type="text"
        autoComplete="none"
        keepCharPositions={true}
        mask={dateMask}
        pipe={autoCorrectedDatePipe}
      />
    </div>
  );
}
