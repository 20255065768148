import React, { Fragment, useEffect } from 'react';
import { FormattedMessage as M } from 'react-intl';

import AccountChartEditor from 'component/AccountChartEditor';

export default function OnBoardingAccountCustomisation(props) {
  useEffect(() => {
    props._getActive({
      company: props.company.id,
    });
  }, []); //eslint-disable-line

  return (
    <Fragment>
      <p>
        <M id="app.accountingSettings" defaultMessage="Accounting Settings" />
      </p>
      <p>
        Create at least 1 account inside a category inside a top category to
        start.
      </p>
      <AccountChartEditor
        company={props.company}
        accounts={props.accounts}
        onPostAccount={props._postAccount}
        onUpdateAccount={props._updateAccount}
        onDeleteAccount={props._deleteAccount}
        onUpdateCompany={props._createOrUpdateCompany}
      />
    </Fragment>
  );
}
