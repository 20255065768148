import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({});

export default function ProfileForm(props) {
  return (
    <div>
      <Formik
        className="form"
        validationSchema={ValidationSchema}
        initialValues={{
          displayName: props.initialValues.displayName || '',
          photoURL: props.initialValues.photoURL || '',
        }}
        onSubmit={(values, actions) => {
          props
            .onSubmit({ ...values })
            .then(updatedUser => {
              actions.setSubmitting(false);
            })
            .catch(er => {
              actions.setSubmitting(false);
              console.error(er);
            });
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="displayName">Display name</label>
              <Field className="form-control" type="text" name="displayName" />
              <ErrorMessage
                name="displayName"
                component="small"
                className="form-text text-muted"
              />
            </div>
            {
              <div className="form-group">
                <label htmlFor="photoURL">Photo URL</label>
                <Field className="form-control" type="text" name="photoURL" />
                <ErrorMessage
                  name="photoURL"
                  component="small"
                  className="form-text text-muted"
                />
              </div>
            }
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
