import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function snapshotToArray(snapshot) {
  const result = [];
  snapshot.forEach(doc => {
    result.push({
      ...doc.data(),
      id: doc.id,
    });
  });
  return result;
}

export function getNumberMask(prefix = '', suffix = '') {
  return createNumberMask({
    allowNegative: true,
    allowDecimal: true,
    prefix,
    suffix,
  });
}

export const dateMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  // ' ',
  // /\d/,
  // /\d/,
  // ':',
  // /\d/,
  // /\d/,
];

export const timeMask = [/\d/, /\d/, ':', /\d/, /\d/];

export function getBalance(trx, acc) {
  if (!acc) {
    return trx.reduce((sum, x, i) => {
      if (x.from && x.from.indexOf('ac') === 0) {
        return sum - x.amount;
      }
      if (x.to && x.to.indexOf('ac') === 0) {
        return sum + x.amount;
      }
      return sum;
    }, 0);
  } else {
    return trx.reduce((sum, x, i) => {
      if (x.from === acc.code) {
        return sum - x.amount;
      }
      if (x.to === acc.code) {
        return sum + x.amount;
      }
      return sum;
    }, 0);
  }
}

// export function getBalanceByCurrency(trx, accounts) {
//   const balanceObject = trx.reduce((sum, x, i) => {
//     // const trxCurrency =

//     return sum;
//   }, {});
// }

export function getAccount(household, trx) {
  if (!household || !trx) {
    return false;
  }
  if (getTrxType(trx) !== 'transfer') {
    let accountCode;
    if (trx.from && trx.from.indexOf('ac') === 0) {
      accountCode = trx.from;
    } else {
      accountCode = trx.to;
    }
    return [household.accounts.find(x => x.code === accountCode)];
  } else {
    return [
      household.accounts.find(x => x.code === trx.from),
      household.accounts.find(x => x.code === trx.to),
    ];
  }
}

export function getCategory(household, trx) {
  let categoryCode;
  if (trx.from && trx.from.indexOf('ac') === 0) {
    categoryCode = trx.to;
  } else if (trx.to && trx.to.indexOf('ac') === 0) {
    categoryCode = trx.from;
  }

  if (!categoryCode) {
    return null;
  }

  const category =
    categoryCode.indexOf('in') === 0 ? household.income : household.expense;
  return category.find(x => x.code === categoryCode);
}

export function getTrxType(trx) {
  if (
    trx.from &&
    trx.from.indexOf('ac') === 0 &&
    trx.to &&
    trx.to.indexOf('ac') === 0
  ) {
    return 'transfer';
  }
  if (trx.note === 'Initial balance') {
    return 'initial balance';
  }
  if (trx.to && trx.to.indexOf('ac') === 0) {
    return 'income';
  }
  if (trx.from && trx.from.indexOf('ac') === 0) {
    return 'expense';
  }
  return '';
}

export function getTrxContext(trx) {
  const type = getTrxType(trx);
  switch (type) {
    case 'transfer':
      return `secondary ${type}`;
    case 'initial balance':
      return ` ${type}`;
    case 'balance-transfer':
      return ` ${type}`;
    case 'income':
      return ` ${type}`;
    case 'expense':
      return `danger ${type}`;
    default:
      return '';
  }
}

export function getTrfNote(household, trx) {
  if (
    trx.from &&
    trx.from.indexOf('ac') === 0 &&
    trx.to &&
    trx.to.indexOf('ac') === 0
  ) {
    const accTo = household.accounts.find(x => x.code === trx.to);
    return `Transfer to <strong>${accTo.name}</strong>`;
  }
  return 'transfer';
}

export function dateToInput(raw) {
  const format = moment.unix(raw).format('DD/MM/YYYY HH:mm');
  return format;
}

export function dateToDateObject(raw) {
  const format = moment.unix(raw.seconds).toDate();
  return format;
}

export function byDate(a, b) {
  return a.date < b.date;
}

export function resolver(argument = true) {
  return new Promise(resolve => resolve(argument));
}

export function rejecter(argument = false) {
  return new Promise((resolve, reject) => reject(argument));
}

export function waiter(timeout = 1000, argument = true) {
  return new Promise(resolve => {
    setTimeout(() => resolve(argument), timeout);
  });
}

export function accountTreeToArray(tree) {
  if (!tree || !tree.reduce) {
    return;
  }
  return tree.reduce((sum, x, i) => {
    let result = [...sum];
    for (var xx of x.children) {
      result = [...result, ...xx.children];
    }
    return result;
  }, []);
}

export function toQueryString(params = {}) {
  let string = '';
  for (var key in params) {
    if (params[key] !== '') {
      string = `${string}&${key}=${params[key]}`;
    }
  }
  return string.replace('&', '?');
}

export function toQueryObject(string = '') {
  const array = string.substr(1).split('&');
  const result = {};
  for (var part of array) {
    const keyValue = part.split('=');
    result[keyValue[0]] = keyValue[1];
  }
  return result;
}

export function exportToCsv(filename, rows) {
  var processRow = function(row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function exportToPdf(filename, docDefinition) {
  pdfMake.createPdf(docDefinition).download(filename);
}
