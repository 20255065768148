import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
import { connect } from 'react-redux';

import { accountTreeToArray } from 'helper';
import LedgerForm from 'component/Transactions/LedgerForm';
import Loading from 'component/Global/Loading';

export function Detail(props) {
  const { _getTransactions, idToken, company } = props;

  const [isLoading, setIsLoading] = useState(true);

  const id = get(props, 'match.params.id');
  const one = get(props, 'transactions.one');
  const projects = get(props, 'project.list') || [];
  const contacts = get(props, 'contacts.list') || [];
  const accounts = accountTreeToArray(company.accounts || []).map(x => ({
    ...x,
    name: `${x.code} - ${x.name}`,
  }));

  const month = moment().format('YYYY-MM');

  const isNew = id === 'new';
  const title = isNew ? 'New Item' : 'Update Item';
  let initialValues = {
    contactId: null,
    projectId: null,
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm'),
    description: '',
    code: '',
    lines: [
      {
        account: accounts[0] ? accounts[0].id : '',
        // description: '',
        debit: 100000,
        credit: '',
        currency: company.defaultCurrency,
      },
      {
        account: accounts[1] ? accounts[1].id : '',
        // description: '',
        debit: '',
        credit: 100000,
        currency: company.defaultCurrency,
      },
    ],
  };

  useEffect(() => {
    if (!isNew) {
      _getTransactions(id, idToken).then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [id]); //eslint-disable-line

  if (!isLoading && id !== 'new' && one) {
    initialValues = {
      id: one.id,
      date: moment.parseZone(one.date).format('YYYY-MM-DD'),
      time: moment.parseZone(one.date).format('HH:mm'),
      projectId: one.project,
      contactId: one.contact,
      code: one.code,
      description: one.description,
      lines: one.lines.map(x => ({
        account: x.accountId ? x.accountId : '',
        // description: x.description,
        debit: x.amount < 0 ? -x.amount : 0,
        credit: x.amount > 0 ? x.amount : 0,
        currency: x.currency,
      })),
    };
  }

  if (!company) {
    return '';
  }

  if (!company.accounts) {
    return (
      <div>
        Please create chart of account{' '}
        <Link to="/home/profile/account-classification">here</Link> to continue.
      </div>
    );
  }

  function handleSubmit(data) {
    setIsLoading(true);

    const request = {
      type: 'ledger',
      user: props.uid,
      company: company.id,
      date: `${data.date} ${data.time}`,
      code: data.code,
      description: data.description,
      projectId:
        data.projectId && typeof data.projectId === 'object'
          ? data.projectId.id
          : data.projectId,
      contactId:
        data.contactId && typeof data.contactId === 'object'
          ? data.contactId.id
          : data.contactId,
      lines: data.lines.map((x, i) => ({
        order: i,
        accountId: x.account,
        amount: x.debit ? -x.debit : x.credit,
        // description: x.description,
      })),
      data: {
        amount: data.lines.reduce((sum, x) => {
          return sum + (x.debit || 0);
        }, 0),
      },
    };

    if (isNew) {
      return props._postTransaction(request).then(data => {
        props._push(`/home/ledgers/`);
        // setIsLoading(false);
      });
    } else {
      request.id = id;
      return props._updateTransaction(request).then(data => {
        props._push(`/home/ledgers/`);
        // setIsLoading(false);
      });
    }
  }

  function handleDelete() {
    return props._deleteTransaction(id).then(() => {
      props._push(`/home/ledgers`);
    });
  }

  function handleProjectApi(data) {
    return props
      ._readProject({ search: data, company: company.id, limit: 10, offset: 0 })
      .then(res => {
        return res.data.map(x => ({ label: x.name, value: x.id }));
      });
  }

  function handleContactApi(data) {
    return props
      ._readContact({ search: data, company: company.id, limit: 10, offset: 0 })
      .then(res => {
        return res.data.map(x => ({ label: x.name, value: x.id }));
      });
  }

  if (isLoading) {
    // isLoading
    return <Loading></Loading>;
  }

  return (
    <>
      <Helmet>
        <title>Aiclo Accounting - Home - Ledger - {title}</title>
      </Helmet>
      <div className="row mb-4 justify-content-md-center">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="d-flex flex-row justify-content-start align-items-center mb-3">
            <Link
              to={`/home/ledgers/?month=${month}`}
              className="btn btn-link text-left p-0"
              style={{ minWidth: 'auto' }}
            >
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: '12px' }}
              ></i>{' '}
              Back
            </Link>
            <h3 className="page-title ml-2">
              |{' '}
              {isNew ? (
                <M id="app.newGeneralLedger" defaultMessage="New Ledger" />
              ) : (
                <M
                  id="app.updateGeneralLedger"
                  defaultMessage="Update Ledger"
                />
              )}
            </h3>
          </div>
          <div className="card auths">
            <div className="card-body">
              <LedgerForm
                onSubmit={handleSubmit}
                accounts={company.accounts}
                contacts={contacts}
                projects={projects}
                initialValues={initialValues}
                currency={company.defaultCurrency}
                isNew={isNew}
                handleDelete={handleDelete}
                projectApi={handleProjectApi}
                contactApi={handleContactApi}
              />
            </div>
          </div>
        </div>
      </div>
      {/*<Link
        to={`/home/ledgers/?month=${month}`}
        className="btn btn-outline-secondary"
      >
        Back
      </Link>
      <Fragment>
        <h2>
          {isNew ? (
            <M id="app.newGeneralLedger" defaultMessage="New Ledger" />
          ) : (
            <M
              id="app.updateGeneralLedger"
              defaultMessage="Update Ledger"
            />
          )}
        </h2>
        <LedgerForm
          onSubmit={handleSubmit}
          accounts={company.accounts}
          contacts={contacts}
          projects={projects}
          initialValues={initialValues}
          currency={company.defaultCurrency}
        />
        {!isNew && (
          <button
            type="submit"
            onClick={handleDelete}
            className="btn btn-outline-danger"
            disabled={isSubmitting}
          >
            <M id="app.delete" defaultMessage="Delete" />
          </button>
        )}
      </Fragment>*/}
    </>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    project: state.projects,
    transactions: state.transactions,
    contacts: state.contacts,
  };
}

export default connect(mapStateToProps)(Detail);
