import React from 'react';
import { Formik, Field, Form, ErrorMessage, FastField } from 'formik';
import MaskedInput from 'react-text-mask';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';
import { getNumberMask } from 'helper';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
});

// const defaultValues = {
//   name: '',
// };

export default function VendorForm(props) {
  const { isNew, handleDelete } = props;
  const numberMask = getNumberMask('');
  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={{
        ...props.initialValues,
      }}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            ...values,
            price: parseInt(
              String(values.price)
                .split(',')
                .join(''),
              10
            ),
          })
          .then(() => {
            // actions.setSubmitting(false)
          })
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Product Name</label>
            <Field
              className={`form-control ${errors.name &&
                touched.name &&
                'error'}`}
              type="text"
              name="name"
              id="name"
              placeholder="Enter Product name"
            />
            <ErrorMessage
              name="name"
              component="small"
              className="form-text text-danger name-error"
            />
          </div>
          <div className="form-group">
            <label htmlFor="price">Product price</label>
            <FastField name="price" id="price" placeholder="Enter price">
              {({ field }) => (
                <MaskedInput
                  {...field}
                  onFocus={event => event.target.select()}
                  className={`form-control ${errors.price &&
                    touched.price &&
                    'error'}`}
                  type="text"
                  autoComplete="none"
                  placeholder="Enter price"
                  mask={numberMask}
                />
              )}
            </FastField>
            <ErrorMessage
              name="price"
              component="small"
              className="form-text text-danger price-error"
            />
          </div>
          <div className="action-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {!isNew && (
              <button
                type="button"
                onClick={handleDelete}
                className="btn btn-outline-danger"
                disabled={isSubmitting}
              >
                <M id="app.delete" defaultMessage="Delete" />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
