import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'component/Global/Icons';

export default function ProfitLossWidget(props) {
  const {
    expenseOnIncome,
    income,
    nonOperationalExpense,
    operationalExpense,
    otherExpense,
    otherIncome,
  } = props.profitLoss.items;
  const profit = Math.abs(income.balance + otherIncome.balance);
  const loss =
    expenseOnIncome.balance +
    nonOperationalExpense.balance +
    operationalExpense.balance +
    otherExpense.balance;
  const sum = profit - loss;

  const note =
    sum > 0 ? 'Bisnis Anda Tampak Baik' : 'Bisnis Anda Tampak Tidak Baik';

  const icon = sum > 0 ? 'icon-laba-plus' : 'icon-laba-minus';

  return (
    <div className="card card-dash">
      <div className="card-body">
        <div className="card-dash__condition">
          <div className="card-dash__summary-right">
            <div className="content content-plus">
              <Icon name="laba-plus" classSvg="icon-laba-plus" />
              <div className="content-items">
                <div className="content-amount">
                  <sup>{props.currency}</sup>
                  {profit.toLocaleString()}
                </div>
                <p className="content-text">Pemasukan</p>
              </div>
            </div>
            <div className="content content-minus">
              <Icon name="laba-minus" classSvg="icon-laba-minus" />
              <div className="content-items">
                <div className="content-amount">
                  <sup>{props.currency}</sup>
                  {loss.toLocaleString()}
                </div>
                <p className="content-text">Pengeluaran</p>
              </div>
            </div>
          </div>
          <div className="card-dash__summary">
            <div className="card-dash__content">
              <h2>{note}</h2>
              <Icon name="laba" classSvg={icon} />
              <div className="card-dash__amount">
                <sup>{props.currency}</sup>
                {sum.toLocaleString()}
              </div>
              <p className="card-dash__text">
                <Link to={'/home/reports/profit-loss'}>Laba Rugi</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
