import React from 'react';
import { FormattedMessage as M } from 'react-intl';

export default function Loading(props) {
  return (
    <div
      className="text-center"
      style={{ padding: '10rem 0', fontSize: '25px', color: '#24CAE2' }}
    >
      <M id="app.loading" defaultMessage="Loading..." />
      {''}
      <i className="fa fa-circle-o-notch fa-spin fa-fw" />
    </div>
  );
}
