import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

import Loading from 'component/Global/Loading';
import Private from 'containers/Private';
import Dashboard from 'containers/Dashboard';
import OnBoarding from 'containers/OnBoarding';
import Contacts from 'containers/Contacts';
import Products from 'containers/Products';
import Projects from 'containers/Projects';
import Profile from 'containers/Profile';
//new pages V
// import Product from 'containers/Product';
// import Project from 'containers/Project';
// import Contact from 'containers/Contact';

import Expenses from 'containers/Expenses';
import Transfers from 'containers/Transfers';
import Incomes from 'containers/Incomes';
import Ledgers from 'containers/Ledgers';
import MenuSidebar from 'component/MenuSidebar';

import Reports from 'containers/Reports';

import { getCompanyByContributor } from 'reducer/company';

export function Home(props) {
  const { uid, _getCompanyByContributor } = props;
  const { handleSidebarOpen, sidebarOpen } = props;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (uid) {
      const listenCompany = _getCompanyByContributor(uid);

      Promise.all([listenCompany]).then(() => {
        setLoading(false);
      });
    }
  }, [uid]); // eslint-disable-line

  return (
    <Private {...props}>
      <div className="container-fluid py-2">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Helmet>
              <title>Aiclo Accounting - Home</title>
            </Helmet>
            <Switch>
              <Route
                render={params => <Dashboard {...props} {...params} />}
                path="/home"
                exact
              />
              <Route
                render={params => <OnBoarding {...props} {...params} />}
                path="/home/on-boarding/:step?"
                exact
              />
              <Route
                render={params => <Contacts {...props} {...params} />}
                path="/home/contacts/:id?/:edit?"
                exact
              />
              <Route
                render={params => <Products {...props} {...params} />}
                path="/home/products/:id?/:edit?"
                exact
              />
              <Route
                render={params => <Projects {...props} {...params} />}
                path="/home/projects/:id?/:edit?"
                exact
              />
              <Route
                render={params => <Profile {...props} {...params} />}
                path="/home/profile/:page?"
              />
              <Route
                render={params => <Ledgers {...props} {...params} />}
                path="/home/ledgers"
              />
              <Route
                render={params => <Incomes {...props} {...params} />}
                path="/home/incomes"
              />
              {
                // <Route
                //   render={params => <Product {...props} {...params} />}
                //   path="/home/product"
                // />
                // <Route
                //   render={params => <Project {...props} {...params} />}
                //   path="/home/project"
                // />
                // <Route
                //   render={params => <Contact {...props} {...params} />}
                //   path="/home/contact"
                // />
              }
              <Route
                render={params => <Expenses {...props} {...params} />}
                path="/home/expenses"
              />
              <Route
                render={params => <Transfers {...props} {...params} />}
                path="/home/transfers"
              />
              <Route
                render={params => <Reports {...props} {...params} />}
                path="/home/reports"
              />
            </Switch>
            <MenuSidebar
              sidebarOpen={sidebarOpen}
              handleSidebarOpen={handleSidebarOpen}
            />
          </>
        )}
      </div>
    </Private>
  );
}

function mapStateToProps(state) {
  return {
    uid: get(state, 'auth.user.uid'),
    token: get(state, 'auth.idToken'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    _getCompanyByContributor: id => dispatch(getCompanyByContributor(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
