import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getTransactions,
  postTransaction,
  updateTransaction,
  deleteTransaction,
  throttleTransactions,
} from 'reducer/transactions';

import { readProject } from 'reducer/projects';
import { readContact } from 'reducer/contacts';

import ExpenseDetail from 'containers/Expenses/Detail';
import ExpenseList from 'containers/Expenses/List';

export function Expenses(props) {
  return (
    <Switch>
      <Route
        render={params => <ExpenseDetail {...props} {...params} />}
        path="/home/expenses/:id"
        exact
      />
      <Route
        render={params => <ExpenseList {...props} {...params} />}
        path="/home/expenses"
        exact
      />
    </Switch>
  );
}

function mapDispatchToProps(dispatch, props) {
  return {
    _getTransactions: (id, params) =>
      dispatch(getTransactions(id, props.token, params)),
    _postTransaction: data => dispatch(postTransaction(data, props.token)),
    _updateTransaction: data => dispatch(updateTransaction(data, props.token)),
    _deleteTransaction: data => dispatch(deleteTransaction(data, props.token)),
    _throttleTransactions: data =>
      dispatch(throttleTransactions(data, props.token)),
    _readProject: params => readProject(null, props.token, params),
    _readContact: params => readContact(null, props.token, params),
  };
}

export default connect(null, mapDispatchToProps)(Expenses);
