import React from 'react';

export default function AccountChart({ accounts }) {
  return (
    <div className="d-flex overflow-auto profile-account-classification">
      {accounts.map((x, i) => {
        return (
          <div className={`${i > 0 ? 'ml-1' : ''} column`} key={x.id}>
            <div className="card">
              <div className="card-header py-2">
                <strong>{i + 1}</strong> <span>{x.name}</span>
              </div>
              <div className="card-body p-2">
                <div className="task-list">
                  {x.children.map((xx, ii) => {
                    return (
                      <div
                        className="list-group no-animated no-fadeInDown mb-2"
                        key={xx.id}
                      >
                        <div className="list-group-item list-group-item-secondary py-2">
                          <span>
                            <strong>
                              {i + 1}.{ii + 1}
                            </strong>{' '}
                            <span>{xx.name}</span>
                          </span>
                          {(xx.balanceSheet ||
                            xx.cashFlow ||
                            xx.profitLoss) && (
                            <div>
                              {xx.balanceSheet === 'assets' && (
                                <span className="badge badge-success mr-2">
                                  <i className="fas fa-balance-scale" /> Asset
                                </span>
                              )}
                              {xx.balanceSheet === 'liabilities' && (
                                <span className="badge badge-danger mr-2">
                                  <i className="fas fa-balance-scale" />{' '}
                                  Liabilities
                                </span>
                              )}
                              {xx.balanceSheet === 'equity' && (
                                <span className="badge badge-info mr-2">
                                  <i className="fas fa-balance-scale" /> Equity
                                </span>
                              )}
                              {xx.cashFlow === 'operating' && (
                                <span className="badge badge-secondary mr-2">
                                  <i className="fas fa-money-bill-wave" />{' '}
                                  Operating
                                </span>
                              )}
                              {xx.cashFlow === 'cashBank' && (
                                <span className="badge badge-secondary mr-2">
                                  <i className="fas fa-money-bill-wave" /> Cash
                                  - Bank
                                </span>
                              )}
                              {xx.cashFlow === 'investing' && (
                                <span className="badge badge-secondary mr-2">
                                  <i className="fas fa-money-bill-wave" />{' '}
                                  Investing
                                </span>
                              )}
                              {xx.cashFlow === 'financing' && (
                                <span className="badge badge-secondary mr-2">
                                  <i className="fas fa-money-bill-wave" />{' '}
                                  Financing
                                </span>
                              )}
                              {xx.profitLoss === 'income' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Income
                                </span>
                              )}
                              {xx.profitLoss === 'otherIncome' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Other
                                  Income
                                </span>
                              )}
                              {xx.profitLoss === 'expenseOnIncome' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Expense on
                                  Income
                                </span>
                              )}
                              {xx.profitLoss === 'operationalExpense' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Operational
                                  Expense
                                </span>
                              )}
                              {xx.profitLoss === 'nonOperationalExpense' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Non
                                  Operational Expense
                                </span>
                              )}
                              {xx.profitLoss === 'otherExpense' && (
                                <span className="badge badge-warning mr-2">
                                  <i className="fas fa-chart-bar" /> Other
                                  Expense
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                        {xx.children.map((xxx, iii) => (
                          <div className="list-group-item py-2" key={xxx.id}>
                            <span>
                              <strong>
                                {i + 1}.{ii + 1}.{iii + 1}
                              </strong>{' '}
                              <span>{xxx.name}</span>
                            </span>
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
