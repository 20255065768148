import React, { Fragment, useEffect, useRef } from 'react';

export default function Modal(props) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          props.onClose
        ) {
          props.onClose();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useEffect(() => {
    if (props.show) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [props.show]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (!props.show) {
    return '';
  }

  return (
    <Fragment>
      <div
        className="modal fade show d-block"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modal"
        aria-hidden="false"
      >
        <div className="modal-dialog" role="document" ref={wrapperRef}>
          <div className="modal-content">
            {props.title && (
              <div className="modal-header">
                <h5 className="modal-title">{props.title}</h5>
                {props.onClose && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={props.onClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
            )}
            <div className="modal-body">{props.children}</div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </Fragment>
  );
}
