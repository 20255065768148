import React, { Fragment } from 'react';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';
import shortid from 'shortid';

import OnBoarding1Form from 'component/OnBoarding1Form';

import taxes from 'constant/taxes';

export default function OnBoardingCompany(props) {
  const company = get(props, 'company') || {};
  const uid = get(props, 'uid');

  function handleSubmit(data) {
    // replace taxes property based on taxSystem
    if (data.taxSystem) {
      data.taxes = taxes[data.taxSystem].map(x => ({
        ...x,
        id: shortid.generate(),
      }));
    }
    // else create new contributor list
    data.contributor = [uid];

    return props._createOrUpdateCompany(data).then(() => {
      props._push('/home/on-boarding/2');
    });
  }

  return (
    <Fragment>
      <p>
        <M id="app.companySettings" defaultMessage="Company Settings" />
      </p>
      <OnBoarding1Form
        onSubmit={handleSubmit}
        initialValues={{
          ...company,
        }}
      />
    </Fragment>
  );
}
