import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MemoryRouter } from 'react-router';

import createRootReducer from 'reducer';

const storageKey = process.env.REACT_APP_STORAGE_KEY;
const persistConfig = {
  key: storageKey,
  storage,
  blacklist: ['router'],
};

const persistedReducer = history =>
  persistReducer(persistConfig, createRootReducer(history));

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

export const store = createStore(
  persistedReducer(history),
  composeEnhancer(applyMiddleware(routerMiddleware(history), thunk))
);

export const persistor = persistStore(store);

export const TestWrapper = ({ children, path }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MemoryRouter initialEntries={path}>{children}</MemoryRouter>
    </PersistGate>
  </Provider>
);

export default store;
