export default {
  'app.account': 'Akun',
  'app.accountingSettings': 'Penyesuaian Akuntansi',
  'app.addLine': 'Tambah baris',
  'app.addNew': 'Buat baru',
  'app.alreadyHaveAnAccount': 'Sudah punya akub ?',
  'app.amount': 'Jumlah',
  'app.back': 'Kembali',
  'app.cashBank': 'Kas - Setara kas',
  'app.company': 'Perusahaan',
  'app.companyProfile': 'Profil Perusahaan',
  'app.companySettings': 'Penyesuaian Perusahaan',
  'app.contact': 'Kontak',
  'app.contacts': 'Kontak',
  'app.createCompany': 'Buat Perusahaan',
  'app.date': 'Tanggal',
  'app.delete': 'Hapus',
  'app.detail': 'Detail',
  'app.dontHaveAnAccount': 'Belum punya akun ?',
  'app.edit': 'Sunting',
  'app.expenseAccount': 'Akun Pengeluaran',
  'app.expenseSettings': 'Penyesuaian Pengeluaran',
  'app.forgotYourPassword': 'Lupa password?',
  'app.goToDashboard': 'Ke Dashboard',
  'app.journal': 'Jurnal Umum',
  'app.id': 'ID',
  'app.incomeAccount': 'Akun Pemasukan',
  'app.incomeSettings': 'Penyesuaian Pemasukan',
  'app.listOfContacts': 'Daftar Kontak',
  'app.listOfExpense': 'Daftar Pengeluaran',
  'app.listOfIncome': 'Daftar Pemasukan',
  'app.listOfProducts': 'Daftar Produk',
  'app.listOfProjects': 'Daftar Proyek',
  'app.listOfTaxes': 'Daftar Pajak',
  'app.listOfWallets': 'Daftar Akun Kas',
  'app.loading': 'Memuat...',
  'app.login': 'Masuk',
  'app.loginHere': 'Masuk di sini.',
  'app.logOut': 'Keluar',
  'app.newExpense': 'Pengeluaran Baru',
  'app.newIncome': 'Pemasukan Baru',
  'app.newTransfer': 'Transfer Baru',
  'app.note': 'Catatan',
  'app.products': 'Produk',
  'app.profile': 'Profil',
  'app.project': 'Proyek',
  'app.projectList': 'Daftar Proyek',
  'app.productList': 'Daftar Produk',
  'app.register': 'Daftar akun',
  'app.registerHere': 'Daftar disini.',
  'app.resetPassword': 'Buat password baru',
  'app.submit': 'Kirim',
  'app.taxes': 'Pajak',
  'app.toggleLanguage': 'Ganti bahasa',
  'app.update': 'Perbarui',
  'app.updateIncome': 'Perbarui Pemasukan',
  'app.userAccount': 'Akun Pengguna',
  'app.wallet': 'Akun Kas',
  'app.wallets': 'Akun Kas',
  'app.youAreAlreadyLoggedIn': 'Kamu sudah masuk,',
  'dashboard.onBoarding': 'Buat perusahaan untuk memulai.',
  'landingPage.welcome': 'Selamat datang di Aiclo',
  'onBoarding.finish': 'Selesai',
  'app.dashboard': 'Beranda',
  'app.addNewData': 'Tambah data baru',
};
