import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  isVendor: Yup.string().required('Required'),
});

// const defaultValues = {
//   name: '',
// };

export default function VendorForm(props) {
  const { isNew, handleDelete } = props;

  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={{
        ...props.initialValues,
      }}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            ...values,
          })
          .then(() => {
            // actions.setSubmitting(false)
          })
          .catch(() => actions.setSubmitting(false));
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Contact Name</label>
            <Field
              className={`form-control ${errors.name &&
                touched.name &&
                'error'}`}
              type="text"
              name="name"
              id="name"
              placeholder="Enter contact name"
            />
            <ErrorMessage
              name="name"
              component="small"
              className="form-text text-danger name-error"
            />
          </div>
          <div className="form-group">
            <label htmlFor="price">Is Vendor</label>

            <div className="row">
              <Field
                name="isVendor"
                render={({ field }) => (
                  <>
                    {[
                      { label: 'Yes', val: true },
                      { label: 'No', val: false },
                    ].map((x, i) => (
                      <div className="col-3" key={i}>
                        <div className="form-check">
                          <input
                            {...field}
                            className="form-check-input"
                            id={x.label}
                            value={x.val}
                            name="isVendor"
                            type="radio"
                            defaultChecked={values.isVendor === x.val}
                          />
                          <label htmlFor={x.label}>{x.label}</label>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
            <ErrorMessage
              name="isVendor"
              component="small"
              className="form-text text-danger isVendor-error"
            />
          </div>
          <div className="action-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {!isNew && (
              <button
                type="button"
                onClick={handleDelete}
                className="btn btn-outline-danger"
                disabled={isSubmitting}
              >
                <M id="app.delete" defaultMessage="Delete" />
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
