import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getProject,
  postProject,
  updateProject,
  deleteProject,
} from 'reducer/projects';

import ProjectsDetail from 'containers/Projects/Detail';
import ProjectsList from 'containers/Projects/List';

export function Project(props) {
  return (
    <Switch>
      <Route
        render={params => <ProjectsDetail {...props} {...params} />}
        path="/home/projects/:id"
        exact
      />
      <Route
        render={params => <ProjectsList {...props} {...params} />}
        path="/home/projects"
        exact
      />
    </Switch>
  );
}

function mapDispatchToProps(dispatch, props) {
  return {
    _getProject: (id, params) => dispatch(getProject(id, props.token, params)),
    _postProject: data => dispatch(postProject(data, props.token)),
    _updateProject: data => dispatch(updateProject(data, props.token)),
    _deleteProject: data => dispatch(deleteProject(data, props.token)),
  };
}

export default connect(null, mapDispatchToProps)(Project);
