import React, { Fragment, useState } from 'react';
import { Prompt } from 'react-router';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';
import shortid from 'shortid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Modal from 'component/Modal';
import FullPageLoader from 'component/FullPageLoader';

import CategoryForm from 'component/CategoryForm';
import AccountForm from 'component/AccountForm';
import { InnerList } from 'component/AccountClassificationColumn';

import './AccountChartEditor.scss';

export default function AccountChartEditor(props) {
  const accounts = get(props, 'company.accounts') || [];
  const activeAccounts = get(props, 'accounts.active');
  const [submitting, setSubmitting] = useState(false);
  const [activeTask, setActiveTask] = useState(false);
  const [activeColumn, setActiveColumn] = useState(false);
  const [activeTab, setActiveTab] = useState('detail');
  const [activeItem, setActiveItem] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [temporaryPosition, setTemporaryPosition] = useState(false);

  const defaultTasks = accounts.reduce((sum, x, i) => {
    for (var lvl2 of x.children) {
      lvl2.children = lvl2.children.map(xx => ({
        ...xx,
        active: activeAccounts && activeAccounts.indexOf(xx.id) >= 0,
      }));
      sum[lvl2.id] = lvl2;
    }
    return sum;
  }, {});

  const defaultColumns = accounts.reduce((sum, x, i) => {
    sum[x.id] = {
      ...x,
      taskIds: x.children.map(xx => xx.id),
    };
    return sum;
  }, {});

  const [columnOrder, setColumnOrder] = useState(accounts.map(x => x.id));
  const [columns, setColumns] = useState(defaultColumns);
  const [tasks, setTasks] = useState(defaultTasks);

  function handleDrag(data) {
    const { destination, source, draggableId, type } = data;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      setColumnOrder(newColumnOrder);
      setHasChanges(true);
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const newTaskIds = Array.from(sourceColumn.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...sourceColumn,
        taskIds: newTaskIds,
      };

      const newColumns = {
        ...columns,
        [newColumn.id]: newColumn,
      };

      setColumns(newColumns);
    } else {
      const sourceColumn = columns[source.droppableId];
      const destinationColumn = columns[destination.droppableId];
      const newSourceTaskIds = Array.from(sourceColumn.taskIds);
      const newDestinationTaskIds = Array.from(destinationColumn.taskIds);
      newSourceTaskIds.splice(source.index, 1);
      newDestinationTaskIds.splice(destination.index, 0, draggableId);

      const newSourceColumn = {
        ...sourceColumn,
        taskIds: newSourceTaskIds,
      };
      const newDestinationColumn = {
        ...destinationColumn,
        taskIds: newDestinationTaskIds,
      };

      const newColumns = {
        ...columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestinationColumn.id]: newDestinationColumn,
      };

      setColumns(newColumns);
    }

    setHasChanges(true);
  }

  function handleSubmitColumn(data) {
    if (columns[activeColumn]) {
      setColumns(x => ({
        ...columns,
        [activeColumn]: {
          ...columns[activeColumn],
          ...data,
        },
      }));
    } else {
      const newId = shortid.generate();
      setColumns({
        ...columns,
        [newId]: {
          id: newId,
          taskIds: [],
          children: [],
          ...data,
        },
      });

      setColumnOrder(x => [...x, newId]);
    }
    setActiveColumn(false);
    setHasChanges(true);
  }

  function handleSubmitTask(data) {
    if (tasks[activeTask]) {
      setTasks(x => {
        return {
          ...x,
          [activeTask]: {
            ...tasks[activeTask],
            ...data,
          },
        };
      });
    } else {
      const newId = shortid.generate();
      const newTask = {
        id: newId,
        children: [],
        ...data,
      };
      setTasks({
        ...tasks,
        [newId]: newTask,
      });

      const columnId = columnOrder[temporaryPosition[0]];
      setColumns({
        ...columns,
        [columnId]: {
          ...columns[columnId],
          taskIds: [...columns[columnId].taskIds, newId],
        },
      });
    }
    setActiveTask(false);
    setHasChanges(true);
  }

  function handleSubmitItem(data) {
    const action = data.id ? props.onUpdateAccount : props.onPostAccount;
    const isNew = data.id ? false : true;
    return action({
      ...data,
      company: props.company.id,
    }).then(account => {
      const columnId = columnOrder[temporaryPosition[0]];
      const taskId = columns[columnId].taskIds[temporaryPosition[1]];

      const update = {
        ...tasks,
        [taskId]: {
          ...tasks[taskId],
          children: isNew
            ? [...tasks[taskId].children, account]
            : tasks[taskId].children.map(x => {
                if (x.id !== data.id) {
                  return x;
                } else {
                  return { ...x, ...account };
                }
              }),
        },
      };
      setTasks(update);
      setTemporaryPosition(false);
      setActiveTask(false);
      setActiveItem(false);
      setHasChanges(true);
      setActiveTab('detail');
      handleSaveChanges(update);
    });
  }

  function handleRemoveItem(id) {
    const columnId = columnOrder[temporaryPosition[0]];
    const taskId = columns[columnId].taskIds[temporaryPosition[1]];
    const newTasks = {
      ...tasks,
      [taskId]: {
        ...tasks[taskId],
        children: tasks[taskId].children.filter(xxx => xxx.id !== id),
      },
    };

    return props.onDeleteAccount(id).then(() => {
      handleSaveChanges(newTasks);
      setTasks(tasks => {
        return newTasks;
      });
      setActiveTask(false);
      setActiveTab('detail');
    });
  }

  function handleRemoveColumn(id) {
    const newOrder = columnOrder.filter(x => x !== id);
    setColumnOrder(newOrder);
    setActiveColumn(false);
    setHasChanges(true);
  }

  function handleRemoveTask(id) {
    const columnId = columnOrder[temporaryPosition[0]];
    const update = {
      ...columns,
      [columnId]: {
        ...columns[columnId],
        taskIds: columns[columnId].taskIds.filter(x => x !== id),
        children: columns[columnId].children.filter(x => x.id !== id),
      },
    };
    setColumns(update);
    setActiveTask(false);
    setHasChanges(true);
  }

  function handleStageTask(id, parentIndex, index = null) {
    setActiveTask(id);
    setTemporaryPosition([parentIndex, index]);
  }

  function handleStageItem(a, b, c) {
    // const columnId = columnOrder[a];
    // const taskId = columns[columnId].taskIds[b]
  }

  function handleSaveChanges(taskUpdate = null) {
    setSubmitting(true);
    const updateSource = taskUpdate || tasks;
    const update = columnOrder.map(x => ({
      id: columns[x].id,
      name: columns[x].name,
      children: columns[x].taskIds.map(xx => {
        return {
          id: updateSource[xx].id,
          name: updateSource[xx].name,
          children: updateSource[xx].children.map(xxx => {
            delete xxx.active;
            return xxx;
          }),
          cashFlow: updateSource[xx].cashFlow || '',
          balanceSheet: updateSource[xx].balanceSheet || '',
          profitLoss: updateSource[xx].profitLoss || '',
        };
      }),
    }));

    props
      .onUpdateCompany({
        id: props.company.id,
        accounts: update,
      })
      .then(() => {
        setHasChanges(false);
        setSubmitting(false);
      });
  }

  return (
    <Fragment>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {hasChanges && (
        <nav className="navbar fixed-bottom navbar-light bg-light">
          <div className="container">
            <div className="navbar-text">You have unsaved changes</div>
            <form className="form-inline">
              <button
                className="btn btn-success my-2 my-sm-0"
                type="button"
                onClick={() => handleSaveChanges(null)}
              >
                <M id="app.saveChanges" defaultMessage="Save Changes" />
              </button>
            </form>
          </div>
        </nav>
      )}

      <DragDropContext onDragEnd={handleDrag}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {provided => (
            <div
              className="d-flex overflow-auto profile-account-classification"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columnOrder.map((x, i) => {
                const column = columns[x];

                return (
                  <InnerList
                    key={column.id}
                    index={i}
                    taskMap={tasks}
                    onStageTask={handleStageTask}
                    onStageColumn={setActiveColumn}
                    onStageItem={handleStageItem}
                    {...column}
                  />
                );
              })}
              {provided.placeholder}
              <div className="mr-1 column">
                <div className="card">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => setActiveColumn('new')}
                  >
                    Add new top category
                  </button>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Modal show={activeTask} onClose={() => setActiveTask(false)}>
        <ul className="nav nav-tabs mb-2">
          <li className="nav-item">
            <a //eslint-disable-line
              className={`nav-link ${activeTab === 'detail' ? 'active' : ''}`}
              onClick={() => setActiveTab('detail')}
            >
              Detail
            </a>
          </li>
          {activeTask !== 'new' && (
            <li className="nav-item">
              <a //eslint-disable-line
                className={`nav-link ${
                  activeTab === 'children' ? 'active' : ''
                }`}
                onClick={() => setActiveTab('children')}
              >
                Account Items
              </a>
            </li>
          )}
        </ul>
        {activeTab === 'detail' && (
          <Fragment>
            <CategoryForm
              initialValues={tasks[activeTask]}
              onSubmit={handleSubmitTask}
            />
            <br />
            {activeTask &&
              tasks[activeTask] &&
              tasks[activeTask].children.length === 0 && (
                <p>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => handleRemoveTask(activeTask)}
                  >
                    Remove category
                  </button>
                </p>
              )}
          </Fragment>
        )}
        {activeTab === 'children' && (
          <Fragment>
            <div className="list-group mb-2">
              {activeTask &&
                tasks[activeTask].children.map((x, i) => (
                  <div key={i} className="list-group-item">
                    {activeItem !== x.id && (
                      <Fragment>
                        <button
                          className="btn btn-text float-right py-0"
                          onClick={() => setActiveItem(x.id)}
                        >
                          <i className="fas fa-cog" />
                        </button>
                        <strong>
                          {temporaryPosition[0] + 1}.{temporaryPosition[1] + 1}.
                          {i + 1}
                        </strong>{' '}
                        {x.name}{' '}
                        {x.active && <i className="fas fa-lock fa-xs" />}
                      </Fragment>
                    )}
                    {activeItem === x.id && (
                      <Fragment>
                        <button
                          className="btn btn-text float-right py-0"
                          onClick={() => setActiveItem(false)}
                        >
                          <i className="fas fa-times" />
                        </button>
                        <AccountForm
                          initialValues={x}
                          onSubmit={handleSubmitItem}
                          onDelete={
                            !x.active ? () => handleRemoveItem(x.id) : null
                          }
                        />
                      </Fragment>
                    )}
                  </div>
                ))}
            </div>
            <hr />
            <p>Create new item</p>
            <div className="card">
              <div className="card-body">
                <AccountForm onSubmit={handleSubmitItem} />
              </div>
            </div>
          </Fragment>
        )}
      </Modal>
      <Modal
        show={activeColumn}
        onClose={() => {
          setActiveColumn(false);
          setActiveTab('detail');
        }}
      >
        <AccountForm
          initialValues={columns[activeColumn]}
          onSubmit={handleSubmitColumn}
          onDelete={
            activeColumn &&
            activeColumn !== 'new' &&
            columns[activeColumn].children.length === 0
              ? () => handleRemoveColumn(activeColumn)
              : false
          }
        />
      </Modal>
      <FullPageLoader show={submitting} />
    </Fragment>
  );
}
