import React from 'react';
import { FastField, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';

import { currencies } from 'constant';
import { getNumberMask } from 'helper';
import SelectField from 'component/SelectField';

export default function MoneyFormGroup(props) {
  function handleFocus(event) {
    event.target.select();
  }

  const numberMask = getNumberMask('');

  return (
    <tr className="">
      {/*<label>{props.label}</label>*/}
      {props.account && (
        <td className="">
          <label className="sr-only" htmlFor={props.account.key}>
            {props.account.label}
          </label>
          <FastField
            className="form-control"
            placeholder={props.account.label}
            name={props.account.key}
            component={SelectField}
          >
            {fieldProps => (
              <SelectField {...fieldProps} options={props.account.options} />
            )}
          </FastField>
          {
            // <FastField
            //   className="form-control"
            //   placeholder={props.account.label}
            //   as="select"
            //   name={props.account.key}
            // >
            //   {props.account.options.map(x => (
            //     <option key={x.id} value={x.id}>
            //       {x.name}
            //     </option>
            //   ))}
            // </FastField>
          }
          <ErrorMessage
            name={props.account.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.name && (
        <td className="">
          <label className="sr-only" htmlFor="name">
            {props.name.label}
          </label>
          <FastField
            className="form-control"
            placeholder={props.name.label}
            type="text"
            name={props.name.key}
          />
          <ErrorMessage
            name={props.name.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.code && (
        <td className="">
          <label className="sr-only" htmlFor={props.code.key}>
            {props.code.label}
          </label>
          <FastField
            className="form-control"
            placeholder={props.code.label}
            type="text"
            name={props.code.key}
          />
          <ErrorMessage
            name={props.code.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.amount && (
        <td className="">
          <label className="sr-only" htmlFor="amount">
            {props.amount.label}
          </label>
          <FastField name={props.amount.key} placeholder={props.amount.label}>
            {({ field }) => (
              <MaskedInput
                {...field}
                onFocus={event => event.target.select()}
                className="form-control"
                type="text"
                autoComplete="none"
                placeholder={props.amount.label}
                mask={numberMask}
              />
            )}
          </FastField>
          <ErrorMessage
            name={props.amount.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.debit && (
        <td className="">
          <label className="sr-only" htmlFor="debit">
            {props.debit.label}
          </label>
          <FastField name={props.debit.key} placeholder={props.debit.label}>
            {({ field }) => (
              <MaskedInput
                {...field}
                onFocus={handleFocus}
                className="form-control"
                type="text"
                autoComplete="none"
                placeholder={props.debit.label}
                mask={numberMask}
              />
            )}
          </FastField>
          <ErrorMessage
            name={props.debit.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.credit && (
        <td className="">
          <label className="sr-only" htmlFor="credit">
            {props.credit.label}
          </label>
          <FastField name={props.credit.key} placeholder={props.credit.label}>
            {({ field }) => (
              <MaskedInput
                {...field}
                onFocus={handleFocus}
                className="form-control"
                type="text"
                autoComplete="none"
                placeholder={props.credit.label}
                mask={numberMask}
              />
            )}
          </FastField>
          <ErrorMessage
            name={props.credit.key}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      {props.currency && (
        <td className="">
          <label className="sr-only" htmlFor="amount">
            Currency
          </label>
          <FastField
            className="form-control"
            component="select"
            name={props.currency}
          >
            {currencies.map(x => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </FastField>
          <ErrorMessage
            name={props.currency}
            component="small"
            className="form-text text-muted"
          />
        </td>
      )}
      <td className="">
        <button
          type="button"
          className="btn btn-sm btn-danger"
          style={{ minWidth: 'auto', borderRadius: 0 }}
          onClick={() => props.arrayHelpers.remove(props.index)}
        >
          <i className="fa fa-trash"></i>
        </button>
      </td>
    </tr>
  );
}
