// import moment from 'moment';
import axios from 'axios';

import { SUCCESSFUL_LOGOUT } from './auth';
import { createFlash, createError } from './flasher';
import { toQueryString } from 'helper';

export const GET_TRANSACTION = 'api/GET_TRANSACTION';
export const GET_TRANSACTIONS = 'api/GET_TRANSACTIONS';
export const APPEND_TRANSACTIONS = 'api/APPEND_TRANSACTIONS';
export const UPDATE_TRANSACTION = 'api/UPDATE_TRANSACTION';
export const DELETE_TRANSACTION = 'api/DELETE_TRANSACTION';
export const RESET_TRANSACTIONS = 'api/RESET_TRANSACTIONS';

const initialState = {
  list: [],
  pagination: null,
  one: null,
};

const apiUrl = process.env.REACT_APP_TRANSACTION_API;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTION:
      return {
        ...state,
        one: action.payload,
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        list: action.payload,
        pagination: action.meta,
        // one: null,
      };
    case APPEND_TRANSACTIONS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
        pagination: action.meta,
        // one: null,
      };
    case SUCCESSFUL_LOGOUT:
      return initialState;
    case RESET_TRANSACTIONS:
      return initialState;
    default:
      return state;
  }
};

// Action
export function getTransactions(id = null, token, params) {
  return async function(dispatch) {
    // const token = await getToken();
    const requestUrl = id
      ? `${apiUrl}/transactions/${id}`
      : `${apiUrl}/transactions${
          typeof params === 'string' ? params : toQueryString(params)
        }`;
    const request = {
      method: 'GET',
      url: requestUrl,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        if (id) {
          dispatch({
            type: GET_TRANSACTION,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_TRANSACTIONS,
            payload: data.data,
            meta: {
              count: parseInt(data.headers['x-count'], 10),
              limit: parseInt(data.headers['x-limit'], 10),
              offset: parseInt(data.headers['x-offset'], 10),
            },
          });
        }
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function postTransaction(data, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'POST',
      url: `${apiUrl}/transactions`,
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: GET_TRANSACTION,
          payload: data.data,
        });
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function updateTransaction(data, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'PUT',
      url: `${apiUrl}/transactions/${data.id}`,
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: GET_TRANSACTION,
          payload: data.data,
        });
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function deleteTransaction(id, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'DELETE',
      url: `${apiUrl}/transactions/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: DELETE_TRANSACTION,
          payload: id,
        });
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function throttleTransactions(params, token) {
  return async function(dispatch) {
    // reset all data
    dispatch({ type: RESET_TRANSACTIONS });

    // query data
    // const token = await getToken();
    let total = 0;
    let received = 0;

    do {
      // query
      const requestUrl = `${apiUrl}/transactions${toQueryString(params)}`;
      const request = {
        method: 'GET',
        url: requestUrl,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      let response = await axios(request);

      total = total || parseInt(response.headers['x-count'], 10);

      received += response.data.length;
      params.offset = params.offset + params.limit;

      dispatch({
        type: APPEND_TRANSACTIONS,
        payload: response.data,
        meta: {
          count: parseInt(response.headers['x-count'], 10),
          limit: parseInt(response.headers['x-limit'], 10),
          offset: parseInt(response.headers['x-offset'], 10),
        },
      });
    } while (received < total);

    return true;
  };
}
