import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import get from 'lodash/get';
import { FormattedMessage as M } from 'react-intl';
// import shortid from 'shortid';
import { Switch, Route } from 'react-router-dom';

import AccountCustomisation from './AccountCustomisation';
import AccountInit from './AccountInit';
import Company from './Company';

import { createOrUpdateCompany } from 'reducer/company';
import { registerAccountChart } from 'reducer/accounts';
// import { initialContact } from 'reducer/contacts';
// import { initialProduct } from 'reducer/products';
// import { initialProject } from 'reducer/projects';

import {
  getActive,
  postAccount,
  updateAccount,
  deleteAccount,
} from 'reducer/accounts';

export function OnBoarding(props) {
  return (
    <Switch>
      <Route
        render={params => <Company {...props} {...params} />}
        path="/home/on-boarding"
        exact
      />
      <Route
        render={params => <AccountInit {...props} {...params} />}
        path="/home/on-boarding/2"
        exact
      />
      <Route
        render={params => <AccountCustomisation {...props} {...params} />}
        path="/home/on-boarding/3"
        exact
      />
      <Route
        render={params => (
          <Fragment>
            <p>
              <M id="onBoarding.finish" defaultMessage="You are all set" />
            </p>
            <p>
              <Link className="btn btn-primary" to="/home">
                <M id="app.goToDashboard" defaultMessage="Go to Dashboard" />
              </Link>
            </p>
          </Fragment>
        )}
        path="/home/on-boarding/4"
        exact
      />
    </Switch>
  );
}

function mapStateToProps(state) {
  return {
    uid: state.auth.user.uid,
    company: state.company,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    // _initialContact: (companyId, userId) =>
    //   dispatch(initialContact(companyId, userId)),
    // _initialProduct: (companyId, userId) =>
    //   dispatch(initialProduct(companyId, userId)),
    // _initialProject: (companyId, userId) =>
    //   dispatch(initialProject(companyId, userId)),
    _createOrUpdateCompany: (data, uid) =>
      dispatch(createOrUpdateCompany(data, uid)),
    _registerAccountChart: (tree, uid, companyId) =>
      dispatch(registerAccountChart(tree, uid, companyId, props.token)),
    _getActive: data => dispatch(getActive(data, props.token)),
    _postAccount: data => dispatch(postAccount(data, props.token)),
    _updateAccount: data => dispatch(updateAccount(data, props.token)),
    _deleteAccount: data => dispatch(deleteAccount(data, props.token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
