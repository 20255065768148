import React, { PureComponent } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

export class TaskInnerList extends PureComponent {
  render() {
    return this.props.tasks.map((xx, ii) => (
      <Task
        key={xx.id}
        {...xx}
        index={ii}
        parentIndex={this.props.parentIndex}
        onStageTask={this.props.onStageTask}
        onStageItem={this.props.onStageItem}
      />
    ));
  }
}

export function Column(props) {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          className="mr-1 column"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="card">
            <div className="card-header py-2" {...provided.dragHandleProps}>
              <button
                className="btn btn-text float-right btn-sm p-0"
                onClick={() => props.onStageColumn(props.id)}
              >
                <i className="fas fa-cog" />
              </button>
              <strong>{props.index + 1}</strong> <span>{props.name}</span>
            </div>
            <div className="card-body p-2">
              <Droppable droppableId={props.id} type="task">
                {provided => (
                  <TaskList
                    {...provided.droppableProps}
                    innerRef={provided.innerRef}
                  >
                    <TaskInnerList
                      tasks={props.tasks}
                      onStageTask={props.onStageTask}
                      onStageItem={props.onStageItem}
                      parentIndex={props.index}
                    />
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
              {
                <button
                  className="btn btn-block btn-outline-secondary animated fadeInDown mb-2"
                  onClick={() => props.onStageTask('new', props.index)}
                >
                  Add new sub category
                </button>
              }
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

export function TaskList(props) {
  return (
    <div className="task-list" ref={props.innerRef}>
      {props.children}
    </div>
  );
}

export function Task(props) {
  return (
    <Draggable draggableId={props.id} index={props.index}>
      {provided => (
        <div
          className="list-group no-animated no-fadeInDown mb-2"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div
            className="list-group-item list-group-item-secondary py-2"
            {...provided.dragHandleProps}
          >
            <button
              className="btn btn-text float-right btn-sm p-0"
              onClick={() =>
                props.onStageTask(props.id, props.parentIndex, props.index)
              }
            >
              <i className="fas fa-cog" />
            </button>
            <span>
              <strong>
                {props.parentIndex + 1}.{props.index + 1}
              </strong>{' '}
              {props.name}
            </span>
            {(props.balanceSheet || props.cashFlow || props.profitLoss) && (
              <div>
                {props.balanceSheet === 'assets' && (
                  <span className="badge badge-success mr-2">
                    <i className="fas fa-balance-scale" /> Asset
                  </span>
                )}
                {props.balanceSheet === 'liabilities' && (
                  <span className="badge badge-danger mr-2">
                    <i className="fas fa-balance-scale" /> Liabilities
                  </span>
                )}
                {props.balanceSheet === 'equity' && (
                  <span className="badge badge-info mr-2">
                    <i className="fas fa-balance-scale" /> Equity
                  </span>
                )}
                {props.cashFlow === 'operating' && (
                  <span className="badge badge-secondary mr-2">
                    <i className="fas fa-money-bill-wave" /> Operating
                  </span>
                )}
                {props.cashFlow === 'cashBank' && (
                  <span className="badge badge-secondary mr-2">
                    <i className="fas fa-money-bill-wave" /> Cash - Bank
                  </span>
                )}
                {props.cashFlow === 'investing' && (
                  <span className="badge badge-secondary mr-2">
                    <i className="fas fa-money-bill-wave" /> Investing
                  </span>
                )}
                {props.cashFlow === 'financing' && (
                  <span className="badge badge-secondary mr-2">
                    <i className="fas fa-money-bill-wave" /> Financing
                  </span>
                )}
                {props.profitLoss === 'income' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Income
                  </span>
                )}
                {props.profitLoss === 'otherIncome' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Other Income
                  </span>
                )}
                {props.profitLoss === 'expenseOnIncome' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Expense on Income
                  </span>
                )}
                {props.profitLoss === 'operationalExpense' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Operational Expense
                  </span>
                )}
                {props.profitLoss === 'nonOperationalExpense' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Non Operational Expense
                  </span>
                )}
                {props.profitLoss === 'otherExpense' && (
                  <span className="badge badge-warning mr-2">
                    <i className="fas fa-chart-bar" /> Other Expense
                  </span>
                )}
              </div>
            )}
          </div>
          {props.children.map((x, i) => (
            <div className="list-group-item py-2" key={i}>
              <button
                className="btn btn-text text-left"
                onClick={() =>
                  props.onStageItem(props.parentIndex, props.index, i)
                }
              >
                <strong>
                  {props.parentIndex + 1}.{props.index + 1}.{i + 1}
                </strong>{' '}
                {x.name} {x.active && <i className="fas fa-lock fa-xs"></i>}
              </button>
            </div>
          ))}
        </div>
      )}
    </Draggable>
  );
}

export class InnerList extends PureComponent {
  render() {
    const { taskMap, index, ...column } = this.props;
    const tasks = column.taskIds.map(taskId => taskMap[taskId]);
    return <Column tasks={tasks} index={index} {...column} />;
  }
}
