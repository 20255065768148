import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';

import { PersistGate } from 'redux-persist/integration/react';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { history, store, persistor } from 'config/store';
import 'assets/scss/main.scss';
import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'assets/scss/custom.scss';

Sentry.init({
  dsn:
    'https://9e304a10436b407791e94540bb0f5ece@o104928.ingest.sentry.io/5394814',
});

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();

export default store;
