// import shortid from 'shortid';
import axios from 'axios';

import { SUCCESSFUL_LOGOUT } from './auth';
import { createFlash, createError } from './flasher';
import { toQueryString } from 'helper';

export const GET_PROJECT = 'api/GET_PROJECT';
export const GET_PROJECTS = 'api/GET_PROJECTS';
export const UPDATE_PROJECT = 'api/UPDATE_PROJECT';
export const DELETE_PROJECT = 'api/DELETE_PROJECT';

const initialState = {
  list: [],
  pagination: null,
  one: null,
};

const apiUrl = process.env.REACT_APP_TRANSACTION_API;

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT:
      return {
        list: [],
        one: action.payload,
      };
    case GET_PROJECTS:
      return {
        list: action.payload,
        pagination: action.meta,
        one: null,
      };
    case SUCCESSFUL_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export async function readProject(id = null, token, params) {
  // const token = await getToken();
  const requestUrl = id
    ? `${apiUrl}/projects/${id}`
    : `${apiUrl}/projects${
        typeof params === 'string' ? params : toQueryString(params)
      }`;
  const request = {
    method: 'GET',
    url: requestUrl,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  return axios(request);
}

// Action
export function getProject(id = null, token, params) {
  return async function(dispatch) {
    return readProject(id, token, params)
      .then(data => {
        if (id) {
          dispatch({
            type: GET_PROJECT,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_PROJECTS,
            payload: data.data,
            meta: {
              count: parseInt(data.headers['x-count'], 10),
              limit: parseInt(data.headers['x-limit'], 10),
              offset: parseInt(data.headers['x-offset'], 10),
            },
          });
        }
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function postProject(data, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'POST',
      url: `${apiUrl}/projects`,
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: GET_PROJECT,
          payload: data.data,
        });
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function updateProject(data, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'PUT',
      url: `${apiUrl}/projects/${data.id}`,
      data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: GET_PROJECT,
          payload: data.data,
        });
        return data.data;
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}

export function deleteProject(id, token) {
  return async function(dispatch) {
    // const token = await getToken();
    const request = {
      method: 'DELETE',
      url: `${apiUrl}/projects/${id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    return axios(request)
      .then(data => {
        dispatch(createFlash('Success'));
        dispatch({
          type: DELETE_PROJECT,
          payload: id,
        });
      })
      .catch(err => {
        dispatch(createError(err.message));
        console.error(err);
      });
  };
}
