import React from 'react';
import { Link } from 'react-router-dom';
// import { FormattedMessage as M } from 'react-intl';
// import MenuSidebar from './MenuSidebar';
import UserMenu from './UserMenu';

import burgerMenu from '../assets/images/menu.svg';
import closeMenu from '../assets/images/close-menu.svg';
import Icon from 'component/Global/Icons';
import './Header.scss';

export default function Header(props) {
  const { handleSidebarOpen, sidebarOpen } = props;
  // const [collapse, setCollapse] = useState(false);
  // const [active, setActive] = useState(false);
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  // function handleSidebarOpen() {
  //   setSidebarOpen(!sidebarOpen);
  // }
  function switchLocale() {
    if (props.locale === 'en') {
      props._switchLocale('id');
    } else {
      props._switchLocale('en');
    }
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-sm navbar-light mb-2 px-0"
        style={{ minHeight: '60px' }}
      >
        <div className="container-fluid">
          <div className="d-flex flex-row align-items-center justify-content-start">
            {props.email && (
              <button
                className={`close d-block my-3 mr-3`}
                type="button"
                onClick={handleSidebarOpen}
              >
                <img
                  src={sidebarOpen ? closeMenu : burgerMenu}
                  alt="burgerMenu"
                />
              </button>
            )}

            <Link className="navbar-brand" to={props.email ? '/home' : '/'}>
              Aiclo
            </Link>
          </div>

          {/*<button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setCollapse(!collapse)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>*/}
          {!props.email ? (
            <aside className="user-menu header__user-menu">
              <form className="d-inline-block">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={switchLocale}
                  style={{
                    minWidth: 'auto',
                    padding: '0 15px',
                  }}
                >
                  <Icon
                    name={`${props.locale === 'en' ? 'lang-en' : 'lang-ind'}`}
                    classSvg={`${
                      props.locale === 'en' ? 'icon-english' : 'icon-indonesia'
                    }`}
                  />
                </button>
              </form>
              <Link
                to="/login"
                className="btn btn-link pl-2 pr-3"
                style={{ minWidth: 'auto' }}
              >
                Log in
              </Link>
              <Link to="/register" className="btn btn-primary">
                Sign up
              </Link>
            </aside>
          ) : (
            <UserMenu {...props} switchLocale={switchLocale} />
          )}
          {/*<aside className="user-menu header__user-menu">
          <Link
            to="#"
            className={`user-menu__info-box ${active ? 'active' : ''}`}
            onClick={() => setActive(!active)}
          >
            <span className="user-menu__name">
              Hello, {props.displayName || props.email}
            </span>
          </Link>
          
          <ul className={`user-menu__menu${active ? '_active' : ''}`}>
            <li className="user-menu__item">
              <Link
                to="/home/profile"
                className={`user-menu__link`}
                onClick={() => setActive(!active)}
              >
                <M id="app.profile" defaultMessage="Profile" />
              </Link>
            </li>
            <li className="user-menu__item">
              <Link
                to="/home/profile/taxes"
                className={`user-menu__link`}
                onClick={() => setActive(!active)}
              >
                <i className="fa fa-percent"></i> <M id="app.taxes" defaultMessage="Taxes" />
              </Link>
            </li>
            <li className="user-menu__item">
              <Link
                to="/home/profile/account-classification"
                className={`user-menu__link`}
                onClick={() => setActive(!active)}
              >
                <i className="fa fa-cog"></i> <M id="app.setting" defaultMessage="Setting" />
              </Link>
            </li>
          </ul>
        </aside>*/}

          {/*{!props.email && (
          <>
            <Link to='/login'>
              <M id="app.login" defaultMessage="Login" />
            </Link>
            <form className="form-inline">
              <button
                className="btn btn-outline-success my-2 my-sm-0"
                type="button"
                onClick={switchLocale}
              >
                <M id="app.toggleLanguage" defaultMessage="Toggle Language" />
              </button>
            </form>
          </>
        )}
        {props.email && (
          <Fragment>
            <span className="navbar-text d-none d-sm-block">
              Hello {props.displayName || props.email}
            </span>
            <div
              className={`collapse navbar-collapse ${collapse ? 'show' : ''}`}
              id="navbarNav"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/home/profile">
                    <M id="app.profile" defaultMessage="Profile" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    onClick={ev => {
                      ev.preventDefault();
                      props._logout();
                    }}
                    to="/"
                  >
                    <M id="app.logOut" defaultMessage="Log out" />
                  </Link>
                </li>
              </ul>
            </div>
          </Fragment>
        )}*/}
        </div>
      </nav>
      {/*<MenuSidebar
      sidebarOpen={sidebarOpen}
      handleSidebarOpen={handleSidebarOpen}
    />
    */}
    </>
  );
}
