import React, { useState, useEffect, Fragment } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage as M } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import FilterForm from 'component/Transactions/FilterForm';
import Icon from 'component/Global/Icons';
import Loading from 'component/Global/Loading';
import { toQueryObject, toQueryString } from 'helper';

export function LedgerList({
  _deleteTransaction,
  _getTransactions,
  _push,
  _throttleTransactions,
  companyId,
  ...props
}) {
  // number of items to download
  const limit = 20;

  const [stageItem, setStageItem] = useState(false);
  const [showFilter, toggleShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const projects = get(props, 'project.list', []);
  const contacts = get(props, 'contacts.list', []);
  const accounts = get(props, 'company.accounts', []);
  const list = get(props, 'transactions.list', []);

  const queryObject = toQueryObject(get(props, 'location.search', {}));
  const baseQuery = {
    company: companyId,
    type: 'ledger',
    includeData: ['amount'],
  };
  let month = moment();

  // push to current month pageQuery by default
  if (!queryObject.month) {
    _push(`/home/ledgers?month=${month.format('YYYY-MM')}`);
  } else {
    month = moment(queryObject.month, 'YYYY-MM');
  }

  // convert month query to start end query
  queryObject.start = month.startOf('month').format('YYYY-MM-DD');
  queryObject.end = month.endOf('month').format('YYYY-MM-DD');
  queryObject.limit = limit;
  queryObject.offset = 0;
  delete queryObject.month;

  // combine baswquery and current queryObject
  const queryParameter = { ...baseQuery, ...queryObject };

  // apply queryObject to filter initial values
  let filterValues = {
    project: '',
    contact: '',
    start: '',
    end: '',
    account: '',
    search: '',
    ...queryObject,
  };

  // throttle data if start and end changes
  useEffect(() => {
    setLoading(true);
    _throttleTransactions(queryParameter).then(() => {
      setLoading(false);
    });
  }, [queryObject.start, queryObject.end]); //eslint-disable-line

  function closeModal() {
    setStageItem(false);
  }
  function handleDelete(id) {
    return _deleteTransaction(id).then(() => {
      _getTransactions(null, {
        ...queryParameter,
      });
    });
  }

  function handleFilter(data) {
    return _push(`${props.location.pathname}${toQueryString(data)}`);
  }

  function toggle() {
    toggleShow(showFilter => !showFilter);
  }

  function goTo(term) {
    switch (term) {
      case 'previousYear':
        month.subtract(1, 'years').endOf('year');
        break;
      case 'previousMonth':
        month.subtract(1, 'months');
        break;
      case 'nextMonth':
        month.add(1, 'months');
        break;
      case 'nextYear':
        month.add(1, 'years').endOf('year');
        break;
      default:
        // statements_def
        break;
    }
    const parameter = {
      month: month.format('YYYY-MM'),
    };
    return _push(`${props.location.pathname}${toQueryString(parameter)}`);
  }

  return (
    <Fragment>
      <Helmet>
        <title>Aiclo Accounting - Home - Journal</title>
      </Helmet>
      <div className="top-page">
        <h2 className="page-title">
          Ledgers |{' '}
          {month ? (
            <span>{month.format('MMMM, YYYY')}</span>
          ) : (
            <Fragment>
              {list.length > 0 && (
                <span>
                  {moment(list[0].date).format('DD MMMM YYYY')}
                  &nbsp;- &nbsp;
                  {moment(list[list.length - 1].date).format('DD MMMM YYYY')}
                </span>
              )}
            </Fragment>
          )}
        </h2>

        <ul className="breadcrumbs list-unstyled">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="current">
            <span>Ledgers</span>
          </li>
        </ul>
      </div>
      {/*<p>
        <Link className="btn btn-outline-secondary mr-2 mb-2" to={`/home`}>
          <M id="app.back" defaultMessage="Back" />
        </Link>
        <Link
          className="btn btn-secondary mr-2 mb-2"
          to={`/home/ledgers/new`}
        >
          <M id="app.newGeneralLedger" defaultMessage="New general ledger" />
        </Link>
      </p>*/}

      <div className="row">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                <Link
                  className="btn btn-outline-primary mr-3 btn-sm"
                  to={`/home/ledgers/new`}
                >
                  <M id="app.addNewData" defaultMessage="Add new data" />
                </Link>
                <div className="">
                  {month && (
                    <Fragment>
                      <button
                        onClick={() => goTo('previousYear')}
                        type="button"
                        className="btn btn-nextprev btn-sm mr-1 ml-0"
                      >
                        <i className="fa fa-chevron-left"></i>
                        <i className="fa fa-chevron-left"></i> Prev year
                      </button>
                      <button
                        onClick={() => goTo('previousMonth')}
                        type="button"
                        className="btn btn-nextprev btn-sm mx-1"
                      >
                        <i className="fa fa-chevron-left"></i> Prev month
                      </button>
                      <button
                        onClick={() => goTo('nextMonth')}
                        type="button"
                        className="btn btn-nextprev btn-sm mx-1"
                      >
                        Next month <i className="fa fa-chevron-right"></i>
                      </button>
                      <button
                        onClick={() => goTo('nextYear')}
                        type="button"
                        className="btn btn-nextprev btn-sm mx-1"
                      >
                        Next year <i className="fa fa-chevron-right"></i>
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </Fragment>
                  )}
                  <button
                    type="button"
                    title="Advance filter"
                    className="btn btn-sm btn-outline-warning ml-1 mr-0"
                    style={{ minWidth: 'auto', borderRadius: '5px' }}
                    onClick={toggle}
                  >
                    <i className="fa fa-filter"></i>
                  </button>
                </div>
              </div>
              {list.length > 0 && (
                <div className="animated slideInUp">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Journal Code</th>
                        <th width="25%">Description</th>
                        <th>Project</th>
                        <th>Contact</th>
                        <th className="text-right">Amount</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((x, i) => {
                        const amount = get(x, 'data.amount');
                        return (
                          <tr key={x.id}>
                            <td>{i + 1}.</td>
                            <td>
                              <Link to={`/home/ledgers/${x.id}`}>
                                {x.code || `(${x.id.substring(0, 8)})`}
                              </Link>
                            </td>
                            <td>{x.description || '-'}</td>
                            <td>{x.project ? x.project.name : '-'}</td>
                            <td>{x.contact ? x.contact.name : '-'}</td>
                            <td className="text-right">
                              {amount ? amount.toLocaleString() : 'n/a'}
                            </td>
                            <td>{moment(x.date).format('DD/MM/YYYY')}</td>
                            <td>
                              <Link
                                className="btn btn-sm btn-success mr-1"
                                to={`/home/ledgers/${x.id}`}
                                style={{ minWidth: 'auto', borderRadius: 0 }}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </Link>
                              <button
                                className="btn btn-sm btn-danger"
                                style={{ minWidth: 'auto', borderRadius: 0 }}
                                onClick={() => {
                                  setStageItem(x.id);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {loading && <Loading></Loading>}
                </div>
              )}
              {list.length === 0 && (
                <div className="text-center">
                  <hr />
                  <Icon name="no-data" classSvg="icon-no-data" />
                  <p className="mb-0 mt-4">
                    <strong>You don't have any transaction yet</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!showFilter && (
        <div className="modals modals__overlay">
          <FilterForm
            accounts={accounts}
            projects={projects}
            contacts={contacts}
            initialValues={filterValues}
            onSubmit={handleFilter}
            toggleShow={toggleShow}
            modalHeader="Advance filter"
          />
        </div>
      )}

      {stageItem && (
        <div className="modals modals__overlay">
          <div className="modals__content text-center">
            <h2 className="page-title mb-4">
              Are you sure want to delete this item?
            </h2>
            <p>ID : {stageItem}</p>
            <Icon name="sad" classSvg="icon-sad" />
            <div className="modals__action">
              <button
                onClick={closeModal}
                className="btn btn-sm btn-warning mr-2"
                style={{ minWidth: 'auto' }}
              >
                close
              </button>
              <button
                className="btn btn-sm btn-danger"
                style={{ minWidth: 'auto' }}
                onClick={() => {
                  handleDelete(stageItem);
                  closeModal();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    companyId: state.company.id,
    company: state.company,
    project: state.project,
    contacts: state.contact,
    transactions: state.transactions,
  };
}

export default connect(mapStateToProps)(LedgerList);
