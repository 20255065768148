import React, { Fragment } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

const expensesData = [
  { name: 'Biaya', value: 15 },
  { name: 'Pendapatan Lain', value: 30 },
  { name: 'Beban Lain', value: 55 },
];

const COLORS = ['#24CAE2', '#FFBF01', '#F04057'];

export default function PieChartWidget(props) {
  const data = props.data
    ? props.data
        .map(x => ({ name: x.name, value: Math.abs(x.balance / 1000000) }))
        .sort((a, b) => a.value < b.value)
    : expensesData;
  const sum = data.reduce((sum, x) => sum + x.value, 0);

  return (
    <div className="card card-dash h-100">
      <div className="card-body">
        <h2 className="blok-title mb-3">{props.title}</h2>
        <p className="txt-amount">In Rp1.000.000</p>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              outerRadius={60}
              fill="#8884d8"
              label
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        {''}
        {data.map(x => (
          <Fragment key={x.name}>
            <div className="label-progress d-flex flex-row justify-content-between align-items-center">
              <p>{x.name}</p>
              {sum && <p>{Math.floor((x.value / sum) * 1000) / 10}%</p>}
            </div>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${(x.value / sum) * 100}%` }}
              ></div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
