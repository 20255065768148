import React, { Fragment } from 'react';
import { FormattedMessage as M } from 'react-intl';

import ProfileForm from 'component/ProfileForm';
import EmailForm from 'component/EmailForm';
import PasswordForm from 'component/PasswordForm';

export default function UserProfile(props) {
  function switchLocale() {
    if (props.locale === 'en') {
      props._switchLocale('id');
    } else {
      props._switchLocale('en');
    }
  }

  return (
    <Fragment>
      <h2 className="page-title">User Profile</h2>
      <p>
        <small>{props.user.uid}</small>
      </p>
      <p>
        <button
          className="btn btn-outline-success my-2 my-sm-0"
          type="button"
          onClick={switchLocale}
        >
          <M id="app.toggleLanguage" defaultMessage="Toggle Language" />
        </button>
      </p>
      <div className="card mb-4">
        <div className="card-body">
          <ProfileForm
            onSubmit={props._updateProfile}
            initialValues={{ ...props.user }}
          />
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <EmailForm
            onSubmit={props._updateEmail}
            initialValues={{ ...props.user }}
          />
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <PasswordForm onSubmit={props._updatePassword} />
        </div>
      </div>
    </Fragment>
  );
}
