import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Please insert correct email format'),
  password: Yup.string().required('Required'),
});

export default function LoginForm(props) {
  const { btnText } = props;
  // const location = useLocation();

  return (
    <Formik
      validationSchema={ValidationSchema}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values, actions) => {
        props
          .onSubmit(values.email, values.password)
          .then(updatedUser => {
            actions.setSubmitting(false);
          })
          .catch(er => {
            actions.setSubmitting(false);
            console.error(er);
          });
      }}
    >
      {({ errors, status, touched, isSubmitting }) => (
        <Form className="form form-login">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field
              className={`form-control ${errors.email &&
                touched.email &&
                'error'}`}
              type="email"
              name="email"
              placeholder="Enter your email"
            />
            <ErrorMessage
              name="email"
              component="small"
              className="form-text text-danger email-error"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field
              className={`form-control ${errors.password &&
                touched.password &&
                'error'}`}
              type="password"
              name="password"
              placeholder="Enter your password"
            />
            <ErrorMessage
              name="password"
              component="small"
              className="form-text text-danger password-error"
            />
            <div className="my-4 text-link mx-0 d-flex justify-content-between">
              <Link className="text-muted" to="/login/reset-password">
                <M
                  id="app.forgotYourPassword"
                  defaultMessage="Forgot your password ?"
                />
              </Link>
              <Link className="text-muted" to="/register">
                <M
                  id="app.dontHaveAnAccount"
                  defaultMessage="Dont have an account ?"
                />
              </Link>
            </div>
          </div>
          <div className="action-btn">
            <button className="btn btn-warning mb-0" type="reset">
              Reset
            </button>
            <button
              className="btn btn-primary mb-0"
              type="submit"
              disabled={isSubmitting}
            >
              {btnText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
