import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
  percentage: Yup.number().required('Required'),
});

const defaultValues = {
  name: '',
  code: '',
  percentage: '',
};

export default function CompanyForm(props) {
  return (
    <Formik
      className="form"
      enableReinitialize
      validationSchema={ValidationSchema}
      initialValues={props.initialValues || defaultValues}
      onSubmit={(values, actions) => {
        // console.log(actions);
        actions.setSubmitting(true);
        props
          .onSubmit(values)
          .then(sample => {
            actions.setSubmitting(false);
            actions.resetForm();
          })
          .catch(er => {
            actions.setSubmitting(false);
            console.error(er);
          });
      }}
    >
      {({ errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Field className="form-control" type="text" name="name" />
            <ErrorMessage
              name="name"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <Field className="form-control" type="text" name="code" />
            <ErrorMessage
              name="code"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <div className="form-group">
            <label htmlFor="percentage">Percentage</label>
            <Field className="form-control" type="number" name="percentage" />
            <ErrorMessage
              name="percentage"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <div className="d-flex">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {props.onDelete && (
              <button
                className="btn btn-outline-danger  ml-auto"
                type="button"
                disabled={isSubmitting}
                onClick={props.onDelete}
              >
                Delete
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
