import React, { Fragment, useState, useEffect } from 'react';

import get from 'lodash/get';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
// import { Link } from 'react-router-dom';

import DateRangeForm from 'component/DateRangeForm';
import FullPageLoader from 'component/FullPageLoader';
import Modal from 'component/Modal';
import AccountGroup from 'component/Reports/AccountGroup';
import ReportTool from 'component/ReportTool';

import { toQueryObject, exportToCsv, exportToPdf } from 'helper';
import { makeBalanceSheet } from 'helper/accounting';

var docDefinition = {
  content: [
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['*', 'auto'],
      },
    },
  ],
};

export default function ReportBalanceSheet(props) {
  const pageQuery = get(props, 'location.search');
  const query = toQueryObject(pageQuery);
  const preset = query.preset || 'this-month';
  const start = query.start || null;
  const end = query.end || null;
  const [loading, setLoading] = useState(true);
  const [datePicker, setDatePicker] = useState(false);

  let startDate;
  let endDate;
  let title;
  let m;

  const balanceSheet = makeBalanceSheet(
    props.company.accounts,
    props.transactions.list
  );

  switch (preset) {
    case 'this-month':
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'last-month':
      m = moment().subtract(1, 'months');
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'this-year':
      m = moment();
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    case 'last-year':
      m = moment().subtract(1, 'years');
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    default:
      // default to this-month
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
  }
  if (start && end) {
    title = `${moment(start).format('DD MMMM YYYY')} - ${moment(end).format(
      'DD MMMM YYYY'
    )}`;
  }

  const baseQueryParameter = {
    company: props.company.id,
    limit: 20,
    offset: 0,
    includeLines: 1,
    start: start || startDate,
    end: end || endDate,
  };

  const initialRange = {
    start: start || startDate,
    end: end || endDate,
  };

  useEffect(() => {
    props._throttleTransactions(baseQueryParameter).then(() => {
      setLoading(false);
    });
  }, [startDate, endDate, start, end]); //eslint-disable-line

  function handleCustomRange(data) {
    setDatePicker(false);
    setLoading(true);
    props
      ._push(`/home/reports/balance-sheet/?start=${data.start}&end=${data.end}`)
      .then(() => {
        setLoading(false);
      });
  }

  function generateTable() {
    const keys = Object.keys(balanceSheet.items);
    const result = keys.reduce((sum, x, i) => {
      return [
        ...sum,
        [`# ${startCase(x)}`, ''],
        ...balanceSheet.items[x].children.reduce(
          (ssum, xx, ii) => [
            ...ssum,
            [`## ${startCase(xx.name)}`, ''],
            ...xx.children.map(xxx => [xxx.name, xxx.balance]),
            [`## Total ${startCase(xx.name)}`, xx.balance],
          ],
          []
        ),
        [`# Total ${startCase(x)}`, balanceSheet.items[x].balance],
      ];
    }, []);
    result.push(['# Summary', '']);

    result.push(['Total Assets', balanceSheet.items.assets.balance]);
    result.push([
      'Total Liabilities & Equity',
      balanceSheet.items.equity.balance +
        balanceSheet.items.liabilities.balance,
    ]);

    return result;
  }

  function handleDownloadPdf() {
    const data = generateTable().map(x => [
      x[0],
      { alignment: 'right', text: x[1].toLocaleString() },
    ]);
    docDefinition.content[0].table.body = [['Balance Sheet', ''], ...data];
    exportToPdf('balance-sheet.pdf', docDefinition);
  }

  function handleDownloadCsv() {
    exportToCsv('balance-sheet.csv', generateTable());
  }

  if (!balanceSheet) {
    return (
      <p>
        Please set your balance sheet accounts in your chart of account to see
        the report.
      </p>
    );
  }

  return (
    <Fragment>
      <h2>
        <M id="app.balanceSheet" defaultMessage="Balance Sheet" />
      </h2>
      <h4>Periode : {title}</h4>

      <div className="mb-5">
        <table className="table table-sm">
          <AccountGroup items={balanceSheet.items.assets} title="Assets" />
          <AccountGroup items={balanceSheet.items.equity} title="Equity" />
          <AccountGroup
            items={balanceSheet.items.liabilities}
            title="Liabilities"
          />
          <thead className="thead-light">
            <tr>
              <th colSpan="2">
                <div className="py-3 text-center">Summary</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td>
                <div className="py-3">Total Assets</div>
              </td>
              <td className="text-right">
                <div className="py-3">
                  {balanceSheet.items.assets.balance.toLocaleString()}
                </div>
              </td>
            </tr>
            <tr className="">
              <td>
                <div className="py-3">Total Liabilities & Equity</div>
              </td>
              <td className="text-right">
                <div className="py-3">
                  {(
                    balanceSheet.items.equity.balance +
                    balanceSheet.items.liabilities.balance
                  ).toLocaleString()}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ReportTool
        baseUrl={`/home/reports/balance-sheet`}
        onOpenPicker={() => setDatePicker(true)}
        onDownloadPdf={handleDownloadPdf}
        onDownloadCsv={handleDownloadCsv}
      />

      <FullPageLoader show={loading} />
      <Modal show={datePicker} onClose={() => setDatePicker(false)}>
        <DateRangeForm
          onSubmit={handleCustomRange}
          initialValues={initialRange}
        />
      </Modal>
    </Fragment>
  );
}
