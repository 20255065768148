export function makeReport(reportType) {
  return function(accounts = [], transactions = []) {
    const flatTransactions = transactions.reduce((sum, x, i) => {
      if (x.lines) {
        return [...sum, ...x.lines];
      }
      return sum;
    }, []);

    const level2 = accounts
      .reduce((sum, x, i) => [...sum, ...x.children], [])
      .filter(x => x[reportType]);

    const items = {};
    for (const parent of level2) {
      if (items[parent[reportType]]) {
        items[parent[reportType]].children.push(parent);
      } else {
        items[parent[reportType]] = {
          children: [parent],
          balance: 0,
        };
      }
    }
    const keys = Object.keys(items);
    for (const key of keys) {
      const t1 = items[key];
      for (const t2 of t1.children) {
        t2.balance = 0;
        for (const t3 of t2.children) {
          const balance = flatTransactions
            .filter(x => x.account.id === t3.id)
            .reduce((s, x, i) => s + x.amount, 0);
          t3.balance = balance;
          t2.balance += balance;
          t1.balance += balance;
        }
      }
    }

    return items;
  };
}

export function makeProfitLoss(accounts, transactions) {
  const items = makeReport('profitLoss')(accounts, transactions);
  for (const x in items) {
    items[x].balance = Math.abs(items[x].balance);
    for (const xx in items[x].children) {
      items[x].children[xx].balance = Math.abs(items[x].children[xx].balance);
      for (const xxx in items[x].children[xx].children) {
        items[x].children[xx].children[xxx].balance = Math.abs(
          items[x].children[xx].children[xxx].balance
        );
      }
    }
  }
  if (
    !items.income ||
    !items.expenseOnIncome ||
    !items.operationalExpense ||
    !items.nonOperationalExpense ||
    !items.otherIncome ||
    !items.otherExpense
  ) {
    return false;
  }

  const summary = {
    grossProfit: items.income.balance - items.expenseOnIncome.balance,
    operatingProft:
      items.income.balance -
      items.expenseOnIncome.balance -
      items.operationalExpense.balance,
    profit:
      items.income.balance -
      items.expenseOnIncome.balance -
      items.operationalExpense.balance -
      items.nonOperationalExpense.balance,
    netProfit:
      items.income.balance -
      items.expenseOnIncome.balance -
      items.operationalExpense.balance -
      items.nonOperationalExpense.balance +
      items.otherIncome.balance -
      items.otherExpense.balance,
  };
  return { items, summary };
}

export function makeBalanceSheet(accounts, transactions) {
  const items = makeReport('balanceSheet')(accounts, transactions);
  if (!items.assets || !items.liabilities || !items.equity) {
    return false;
  }
  const summary = {
    assets: items.assets,
    liabilities: items.liabilities,
    equity: items.equity,
  };
  return { items, summary };
}

export function makeCashFlow(accounts, transactions) {
  const items = makeReport('cashFlow')(accounts, transactions);
  for (const x in items) {
    items[x].balance = -items[x].balance;
    for (const xx in items[x].children) {
      items[x].children[xx].balance = -items[x].children[xx].balance;
      for (const xxx in items[x].children[xx].children) {
        items[x].children[xx].children[xxx].balance = -items[x].children[xx]
          .children[xxx].balance;
      }
    }
  }

  if (!items.operating || !items.investing || !items.financing) {
    return false;
  }
  return { items };
}

export function updateAccountBalance(accounts = [], balance = []) {
  for (const l1 of accounts) {
    for (const l2 of l1.children) {
      for (const l3 of l2.children) {
        const balanceUpdate = balance.find(x => x.id === l3.id);
        if (balanceUpdate) {
          // l3;
        }
      }
    }
  }
  return accounts;
}
