import React, { Fragment, useState } from 'react';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import { DragDropContext } from 'react-beautiful-dnd';

import SingleAccountForm from 'component/SingleAccountForm';

export default function ProfileAccounts(props) {
  const [chart, setChart] = useState(props.company.accounts);

  function toggle(i, ii = null) {
    const clone = cloneDeep(chart);
    const current =
      ii !== null
        ? get(chart, `[${i}].children[${ii}].hide`)
        : get(chart, `[${i}].hide`);
    if (ii !== null) {
      clone[i].children[ii].hide = !current;
    } else {
      clone[i].hide = !current;
    }
    setChart(clone);
  }

  function remove(i, ii, iii = null) {
    if (iii !== null) {
      const clone = cloneDeep(chart);
      const oldSet = get(chart, `[${i}].children[${ii}].children`);
      const parentCode = get(chart, `[${i}].children[${ii}].code`);
      const newSet = oldSet
        .filter((x, j) => j !== iii)
        .map((x, j) => ({ ...x, code: parentCode + j + 1 }));
      const completeSet = set(clone, `[${i}].children[${ii}].children`, newSet);
      setChart(completeSet);
    } else {
      const clone = chart.map((x, j) => ({
        ...x,
        children:
          i === j ? x.children.filter((xx, jj) => jj !== ii) : x.children,
      }));
      setChart(clone);
    }
  }

  function add(i, ii = null, data = {}) {
    const clone = cloneDeep(chart);
    if (ii !== null) {
      clone[i].children[ii].children.push({
        slug: '',
        name: '',
        code: 0,
        edit: true,
        ...data,
      });
    } else {
      clone[i].children.push({
        slug: '',
        name: '',
        code: 0,
        edit: true,
        ...data,
      });
    }
    setChart(clone);
  }

  function toggleForm(i, ii = null, iii = null) {
    // let clone = cloneDeep(chart);
    var clone = chart.map(x => ({
      ...x,
      edit: false,
      children: x.children.map(xx => ({
        ...xx,
        edit: false,
        children: xx.children.map(xxx => ({
          ...xxx,
          edit: false,
        })),
      })),
    }));

    if (iii !== null) {
      set(clone, `[${i}].children[${ii}].children[${iii}].edit`, true);
    } else if (ii !== null) {
      set(clone, `[${i}].children[${ii}].edit`, true);
    } else {
      set(clone, `[${i}].edit`, true);
    }
    setChart(clone);
  }

  function handleEdit(data, i, ii = null, iii = null) {
    const clone = cloneDeep(chart);
    let parentCode;
    if (iii !== null) {
      parentCode = get(clone, `[${i}].children[${ii}].code`);
      set(clone, `[${i}].children[${ii}].children[${iii}]`, {
        name: data.name,
        slug: camelCase(data.name),
        code: parentCode + iii + 1,
        edit: false,
      });
    } else if (ii !== null) {
      set(clone, `[${i}].children[${ii}]`, {
        name: data.name,
        slug: camelCase(data.name),
        edit: false,
        hide: false,
        code: data.code,
        children: clone[i].children[ii].children || [
          {
            name: data.name,
            slug: camelCase(data.name),
            code: data.code + 1,
          },
        ],
      });
      // clone[i].children = clone[i].children.sort((a, b) => a.code > b.code);
    } else {
      set(clone, `[${i}].name`, data.name);
      set(clone, `[${i}].slug`, camelCase(data.name));
      set(clone, `[${i}].edit`, false);
    }
    setChart(clone);
    return new Promise(res => res());
  }

  function toggleAllAccount() {
    let clone = cloneDeep(chart);
    const current = clone[0].children[0].hide;
    clone = clone.map(x => ({
      ...x,
      children: x.children.map(xx => ({
        ...xx,
        hide: !current,
      })),
    }));
    setChart(clone);
  }

  function toggleAllSubCategory() {
    let clone = cloneDeep(chart);
    const current = clone[0].hide;
    clone = clone.map(x => ({
      ...x,
      hide: !current,
    }));
    setChart(clone);
  }

  function handleDrag(data) {
    // console.log(data);
  }

  return (
    <Fragment>
      <h2>
        <M id="app.listOfAccounts" defaultMessage="List of accounts" />
      </h2>

      <nav className="navbar fixed-bottom navbar-light bg-light">
        <div className="container">
          <form className="form-inline">
            <button
              type="button"
              className="btn btn-secondary btn-sm mr-2"
              onClick={toggleAllSubCategory}
            >
              Toggle all sub categories
            </button>{' '}
            <button
              type="button"
              className="btn btn-secondary btn-sm mr-2"
              onClick={toggleAllAccount}
            >
              Toggle all accounts
            </button>
          </form>
        </div>
      </nav>

      <DragDropContext onDragEnd={handleDrag}>
        {chart.map((x, i) => {
          return (
            <Fragment key={i}>
              <div className="list-group animated fadeInDown mb-2">
                <div className="list-group-item list-group-item-secondary">
                  {x.edit ? (
                    <SingleAccountForm
                      onSubmit={data => handleEdit(data, i)}
                      initialValues={x}
                    />
                  ) : (
                    <Fragment>
                      <button
                        className="btn btn-outline-secondary btn-sm float-right ml-2"
                        onClick={() => toggle(i)}
                      >
                        Toggle
                      </button>
                      <button
                        className="btn btn-outline-secondary btn-sm float-right ml-2"
                        onClick={() => toggleForm(i)}
                      >
                        Edit
                      </button>
                      <p className="py-1 mb-0">
                        <strong>{x.code}</strong> {x.name}
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="ml-5">
                {!x.hide && (
                  <Fragment>
                    {x.children &&
                      x.children.map((xx, ii) => (
                        <Fragment key={ii}>
                          <div className="list-group animated fadeInDown mb-2">
                            <div
                              className="list-group-item list-group-item-secondary"
                              id={`sub-category-${i}-${ii}`}
                            >
                              {xx.edit ? (
                                <SingleAccountForm
                                  start={x.code}
                                  notIn={x.children
                                    .map(xxx => xxx.code)
                                    .filter(xxx => xxx !== xx.code)}
                                  onSubmit={data => handleEdit(data, i, ii)}
                                  initialValues={xx}
                                  extended
                                />
                              ) : (
                                <Fragment>
                                  <button
                                    className="btn btn-outline-danger btn-sm float-right ml-2"
                                    onClick={() => remove(i, ii)}
                                  >
                                    Remove
                                  </button>
                                  <button
                                    className="btn btn-outline-secondary btn-sm float-right ml-2"
                                    onClick={() => toggle(i, ii)}
                                  >
                                    Toggle
                                  </button>
                                  <button
                                    className="btn btn-outline-secondary btn-sm float-right ml-2"
                                    onClick={() => toggleForm(i, ii)}
                                  >
                                    Edit
                                  </button>
                                  <p className="py-1 mb-0">
                                    <strong>{xx.code}</strong> {xx.name}
                                  </p>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          {!xx.hide && (
                            <div className="ml-5">
                              {xx.children &&
                                xx.children.map((xxx, iii) => (
                                  <div
                                    className="list-group animated fadeInDown mb-2"
                                    key={iii}
                                  >
                                    <div className="list-group-item">
                                      {xxx.edit ? (
                                        <SingleAccountForm
                                          onSubmit={data =>
                                            handleEdit(data, i, ii, iii)
                                          }
                                          initialValues={xxx}
                                        />
                                      ) : (
                                        <Fragment>
                                          {iii === 0 && (
                                            <button
                                              className="btn btn-outline-secondary btn-sm float-right"
                                              onClick={() =>
                                                toggleForm(i, ii, iii)
                                              }
                                            >
                                              Edit
                                            </button>
                                          )}
                                          {iii > 0 && (
                                            <button
                                              className="btn btn-outline-danger btn-sm float-right"
                                              onClick={() => remove(i, ii, iii)}
                                            >
                                              Remove
                                            </button>
                                          )}
                                          {iii > 0 && (
                                            <button
                                              className="btn btn-outline-secondary btn-sm float-right mr-2"
                                              onClick={() =>
                                                toggleForm(i, ii, iii)
                                              }
                                            >
                                              Edit
                                            </button>
                                          )}
                                          <p className="py-1 mb-0">
                                            <strong>{xxx.code}</strong>{' '}
                                            {xxx.name}
                                          </p>
                                        </Fragment>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              {!xx.children ||
                                (xx.children && xx.children.length < 10 && (
                                  <button
                                    className="btn btn-block btn-outline-secondary animated fadeInDown mb-2"
                                    onClick={() => add(i, ii)}
                                  >
                                    Add new account
                                  </button>
                                ))}
                            </div>
                          )}
                        </Fragment>
                      ))}
                    <button
                      className="btn btn-block btn-outline-secondary animated fadeInDown mb-2"
                      onClick={() =>
                        add(i, null, {
                          code: x.children[x.children.length - 1].code + 10,
                        })
                      }
                    >
                      Add new sub category
                    </button>
                  </Fragment>
                )}
              </div>
            </Fragment>
          );
        })}
      </DragDropContext>
    </Fragment>
  );
}
