import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage as M } from 'react-intl';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

import ChartOfAccounts from './ChartOfAccounts';
import GeneralLedger from './GeneralLedger';
import Journal from './Journal';
import CashFlow from './CashFlow';
import ProfitLoss from './ProfitLoss';
import BalanceSheet from './BalanceSheet';
import { throttleTransactions } from 'reducer/transactions';
import { getBalance } from 'reducer/accounts';

export function Reports(props) {
  return (
    <div>
      <p>
        <Link className="btn btn-outline-secondary mr-2 mb-2" to={`/home`}>
          <M id="app.back" defaultMessage="Back" />
        </Link>
      </p>
      <Switch>
        <Route
          render={params => <ChartOfAccounts {...props} {...params} />}
          path="/home/reports/chart-of-accounts"
          exact
        />
        <Route
          render={params => <GeneralLedger {...props} {...params} />}
          path="/home/reports/general-ledger"
          exact
        />
        <Route
          render={params => <Journal {...props} {...params} />}
          path="/home/reports/journal"
          exact
        />
        <Route
          render={params => <CashFlow {...props} {...params} />}
          path="/home/reports/cash-flow"
          exact
        />
        <Route
          render={params => <BalanceSheet {...props} {...params} />}
          path="/home/reports/balance-sheet"
          exact
        />
        <Route
          render={params => <ProfitLoss {...props} {...params} />}
          path="/home/reports/profit-loss"
          exact
        />
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    transactions: state.transactions,
    accounts: state.accounts,
    user: state.auth.user,
    balance: state.accounts.balance,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    _throttleTransactions: params =>
      dispatch(throttleTransactions(params, props.token)),
    _getBalance: params => dispatch(getBalance(params, props.token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
