import React from 'react';
import { Formik, FastField, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({});

export default function AccountForm(props) {
  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={
        props.initialValues || {
          name: '',
        }
      }
      onSubmit={(values, actions) => {
        props.onSubmit(values);
      }}
      render={({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <FastField className="form-control" type="text" name="name" />
            <ErrorMessage
              name="name"
              component="small"
              className="form-text text-muted"
            />
          </div>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
          {props.onBack && (
            <button
              className="btn btn-link ml-2"
              type="button"
              disabled={isSubmitting}
              onClick={props.onBack}
            >
              Back
            </button>
          )}
          {props.onDelete && (
            <button
              className="btn btn-outline-danger ml-2"
              type="button"
              disabled={isSubmitting}
              onClick={props.onDelete}
            >
              Delete
            </button>
          )}
        </Form>
      )}
    />
  );
}
