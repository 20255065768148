import React, { Fragment } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function SingleAccountForm(props) {
  const ValidationSchema = Yup.object().shape({
    code: props.extended
      ? Yup.number()
          .required('Required')
          .notOneOf(props.notIn.map(x => x / 10))
          .min(props.start / 10 + 1)
          .max((props.start + 1000) / 10 - 1)
      : false,
    name: Yup.string().required('Required'),
  });

  return (
    <Formik
      className="form"
      validationSchema={ValidationSchema}
      initialValues={
        props.extended
          ? {
              ...props.initialValues,
              code: props.initialValues.code / 10,
            }
          : props.initialValues
      }
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        if (props.extended) {
          props.onSubmit({
            ...values,
            code: values.code * 10,
          });
        } else {
          props.onSubmit(values);
        }
      }}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form>
          <div className="form-inline">
            {props.extended && (
              <Fragment>
                <label className="mr-2" htmlFor="code">
                  Code
                </label>
                <div className="input-group mr-2">
                  <Field
                    className="form-control form-control"
                    placeholder="Code"
                    name="code"
                    style={{ maxWidth: 80, textAlign: 'right' }}
                    onClick={ev => ev.target.select()}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">0</div>
                  </div>
                </div>
              </Fragment>
            )}
            {!props.hideLabel && (
              <label className="mr-2" htmlFor="name">
                Name
              </label>
            )}{' '}
            <Field
              className="form-control form-control mr-2"
              placeholder="Name"
              name="name"
            />
            {props.extended && (
              <ErrorMessage
                name="code"
                component="span"
                className="form-text text-muted mr-2"
              />
            )}
            <ErrorMessage
              name="name"
              component="span"
              className="form-text text-muted mr-2"
            />
            {!props.hideSubmitButton && (
              <button
                className="btn btn-primary btn-sm ml-auto"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
