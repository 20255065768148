import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Switch, Route, NavLink } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';
// import { Helmet } from 'react-helmet';

import Private from './Private';

import Taxes from 'containers/Taxes';
import ProfileAccounts from 'containers/ProfileAccounts';
import UserProfile from 'containers/UserProfile';
import CompanyProfile from 'containers/CompanyProfile';
import ProfileAccountClassification from 'containers/ProfileAccountClassification';

import { updateProfile, updateEmail, updatePassword } from 'reducer/auth';
import { createOrUpdateCompany } from 'reducer/company';
import {
  getActive,
  postAccount,
  updateAccount,
  deleteAccount,
} from 'reducer/accounts';

export function Profile(props) {
  return (
    <Private {...props}>
      <div className="mb-5">
        <div className="top-page">
          <h2 className="page-title">
            <M id="app.profile" defaultMessage="Profile" />
          </h2>

          <ul className="breadcrumbs list-unstyled">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="current">
              <span>
                <M id="app.profile" defaultMessage="Profile" />
              </span>
            </li>
          </ul>
        </div>
        {''}
        <Fragment>
          <ul className="list-group menu list-group-horizontal">
            <li className="list-group-item">
              <NavLink
                to="/home/profile/user-account"
                className=""
                activeClassName="active"
              >
                <M id="app.userAccount" defaultMessage="User Account" />
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                to="/home/profile/company"
                className=""
                activeClassName="active"
              >
                <M id="app.company" defaultMessage="Company" />
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                to="/home/profile/taxes"
                className=""
                activeClassName="active"
              >
                <M id="app.taxes" defaultMessage="Taxes" />
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                to="/home/profile/account-classification"
                className=""
                activeClassName="active"
              >
                <M
                  id="app.accountClassification"
                  defaultMessage="Account Classification"
                />
              </NavLink>
            </li>
          </ul>

          {/*<Link to="/home/profile" className="btn btn-secondary">
            <M id="app.userAccount" defaultMessage="User Account" />
          </Link>{' '}
          <Link to="/home/profile/company" className="btn btn-secondary">
            <M id="app.company" defaultMessage="Company" />
          </Link>{' '}
          <Link to="/home/profile/taxes" className="btn btn-secondary">
            <M id="app.taxes" defaultMessage="Taxes" />
          </Link>{' '}
          <Link
            to="/home/profile/account-classification"
            className="btn btn-secondary"
          >
            <M
              id="app.accountClassification"
              defaultMessage="Account Classification"
            />
          </Link>{' '}*/}
        </Fragment>
        <hr />
        <Switch>
          <Route
            render={params => <Taxes {...props} {...params} />}
            path="/home/profile/taxes"
            exact
          />
          <Route
            render={params => <ProfileAccounts {...props} {...params} />}
            path="/home/profile/accounts"
            exact
          />
          <Route
            render={params => (
              <ProfileAccountClassification {...props} {...params} />
            )}
            path="/home/profile/account-classification"
            exact
          />
          <Route
            render={params => <CompanyProfile {...props} {...params} />}
            path="/home/profile/company"
            exact
          />
          <Route
            render={params => <UserProfile {...props} {...params} />}
            path="/home/profile/user-account"
            exact
          />
          <Route
            exact
            path="/home/profile"
            render={() => <Redirect to="/home/profile/user-account" />}
          />
        </Switch>
      </div>
    </Private>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    accounts: state.accounts,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    _updateProfile: data => dispatch(updateProfile(data)),
    _updateEmail: data => dispatch(updateEmail(data)),
    _updatePassword: data => dispatch(updatePassword(data)),
    _createOrUpdateCompany: data => dispatch(createOrUpdateCompany(data)),
    _getActive: data => dispatch(getActive(data, props.token)),
    _postAccount: data => dispatch(postAccount(data, props.token)),
    _updateAccount: data => dispatch(updateAccount(data, props.token)),
    _deleteAccount: data => dispatch(deleteAccount(data, props.token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
