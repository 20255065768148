import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';

import LoginForm from 'component/LoginForm';
import EmailForm from 'component/EmailForm';
import { loginEmail, resetPassword } from 'reducer/auth';

import '../assets/scss/auth.scss';
import aicloLogo from '../assets/images/aiclo-logo.svg';
import Close from '../assets/images/red-cross.svg';

export function Login(props) {
  useEffect(() => {
    if (props.uid) {
      props.push('/home');
    }
  });

  return (
    <div className="container">
      <div className="row mb-4 justify-content-md-center">
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="bg-comeback">
            <img src={aicloLogo} alt="Come Back" />
          </div>
          <div className="card auths">
            <div className="closeBack">
              <Link to="/">
                <img src={Close} alt="close page" />
              </Link>
            </div>
            <Switch>
              <Route path="/login/reset-password">
                <Fragment>
                  <div className="card-header border-0 pb-0">
                    <h3 className="title-profile mb-2">
                      <M
                        id="app.resetPassword"
                        defaultMessage="Reset password"
                      />
                    </h3>
                  </div>
                  <div className="card-body">
                    <EmailForm
                      onSubmit={props.resetPassword}
                      btnText="Submit"
                    />
                  </div>
                </Fragment>
              </Route>
              <Route exact path="">
                <Fragment>
                  <div className="card-header border-0 pb-0">
                    <h3 className="title-profile mb-2">
                      <M id="app.login" defaultMessage="Login" />
                    </h3>
                  </div>
                  <div className="card-body">
                    <LoginForm onSubmit={props.loginEmail} btnText="Login" />
                  </div>
                </Fragment>
              </Route>
            </Switch>
          </div>
        </div>

        {/*<div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto">
          <Switch>
            <Route path="/login/reset-password">
              <Fragment>
                <h1 className="text-center">
                  <M id="app.resetPassword" defaultMessage="Reset password" />
                </h1>
                <EmailForm onSubmit={props.resetPassword} />
                <hr />
                <p>
                  <M
                    id="app.dontHaveAnAccount"
                    defaultMessage="Don't have an account ?"
                  />
                  <br />
                  <Link to="/register">
                    <M id="app.registerHere" defaultMessage="Register here." />
                  </Link>
                </p>
              </Fragment>
            </Route>
            <Route exact path="">
              <Fragment>
                <h1 className="text-center">
                  <M id="app.login" defaultMessage="Login" />
                </h1>
                <LoginForm onSubmit={props.loginEmail} />
                <hr />
                <p>
                  <M
                    id="app.dontHaveAnAccount"
                    defaultMessage="Don't have an account ?"
                  />
                  <br />
                  <Link to="/register">
                    <M id="app.registerHere" defaultMessage="Register here." />
                  </Link>
                </p>
                <p>
                  <M
                    id="app.forgotYourPassword"
                    defaultMessage="Forgot your password?"
                  />
                  <br />
                  <Link to="/login/reset-password">
                    <M
                      id="app.resetPassword"
                      defaultMessage="Reset password."
                    />
                    <br />
                  </Link>
                </p>
              </Fragment>
            </Route>
          </Switch>
        </div>*/}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    uid: get(state, 'auth.user.uid'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginEmail: (email, password) => dispatch(loginEmail(email, password)),
    resetPassword: email => dispatch(resetPassword(email)),
    push: to => dispatch(push(to)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
