import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { businessType, taxSystem, currencies } from 'constant';

const ValidationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  businessType: Yup.string().required('Required'),
  defaultCurrency: Yup.string().required('Required'),
  taxSystem: Yup.string().required('Required'),
});

const defaultValues = {
  companyName: '',
  businessType: '',
  defaultCurrency: 'IDR',
  taxSystem: taxSystem[0],
};

export default function OnBoarding1Form(props) {
  return (
    <div>
      <Formik
        validationSchema={ValidationSchema}
        initialValues={
          { ...defaultValues, ...props.initialValues } || defaultValues
        }
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          props
            .onSubmit({
              ...values,
              onBoardingStep: 1,
            })
            .then(sample => {
              // actions.setSubmitting(false);
            })
            .catch(er => {
              actions.setSubmitting(false);
              console.error(er);
            });
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form className="form form-onboarding-1">
            <div className="form-group">
              <label htmlFor="companyName">Nama perusahaan</label>
              <Field className="form-control" type="text" name="companyName" />
              <ErrorMessage
                name="companyName"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <div className="form-group">
              <label htmlFor="businessType">Jenis bisnis</label>
              <Field
                className="form-control"
                component="select"
                name="businessType"
              >
                <option value={null}>Please select busines type</option>
                {businessType.map(x => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="businessType"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Default currency</label>
              <Field
                className="form-control"
                placeholder="Default currency"
                component="select"
                name="defaultCurrency"
              >
                <option value={null}>Please select default currency</option>
                {currencies.map(x => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="defaultCurrency"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <div className="form-group">
              <label htmlFor="taxSystem">Sistem pajak</label>
              <Field
                className="form-control"
                component="select"
                name="taxSystem"
              >
                <option value={null}>Please select tax system</option>
                {taxSystem.map(x => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="taxSystem"
                component="small"
                className="form-text text-muted"
              />
            </div>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
