import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage as M } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import get from 'lodash/get';
import Icon from 'component/Global/Icons';

import Pagination from 'component/Pagination';
import { toQueryObject } from 'helper';

export function ProductsList(props) {
  const [deleteItem, setDelete] = useState(false);
  const pageQuery = get(props, 'location.search');
  const products = get(props, 'products.list', []);
  const pagination = get(props, 'products.pagination');
  const query = toQueryObject(pageQuery);
  const offset = query.offset || 0;
  const limit = query.limit || 10;

  function closeModal() {
    setDelete(false);
  }

  const baseQueryParameter = {
    company: props.company.id,
  };

  useEffect(() => {
    props._getProducts(null, {
      ...baseQueryParameter,
      offset,
      limit,
    });
  }, [pageQuery]); //eslint-disable-line

  function handleDelete(id) {
    return props._deleteProduct(id).then(() => {
      props._getProducts(null, {
        ...baseQueryParameter,
        offset,
        limit,
      });
    });
  }

  const [show, toggleShow] = useState(true);
  function toggle() {
    toggleShow(show => !show);
  }

  return (
    <Fragment>
      <Helmet>
        <title>Aiclo Accounting - Home - Products</title>
      </Helmet>
      <div className="top-page">
        <h2 className="page-title">Products</h2>

        <ul className="breadcrumbs list-unstyled">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="current">
            <span>Products</span>
          </li>
        </ul>
      </div>

      <div className="card mb-4 filter">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between">
            <span className="mr-auto text-uppercase" onClick={toggle}>
              Advance filter
            </span>
            <div className="card-header-action">
              <Link
                className="btn btn-primary mr-3 btn-sm"
                to={`/home/products/new`}
              >
                <M id="app.addNewData" defaultMessage="Add new data" />
              </Link>
              <button
                type="button"
                onClick={toggle}
                className={`arrow ${!show ? 'show' : 'hide'}`}
              >
                <i className="fa fa-chevron-down" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {products.length === 0 && (
        <div className="card mb-4">
          <div
            className="card-body text-center"
            style={{ padding: '6rem 24px' }}
          >
            <Icon name="no-data-product" classSvg="icon-no-data" />
            <p className="mb-0 mt-4">
              <strong>You don't have any products yet</strong>
            </p>
          </div>
        </div>
      )}
      {products.length > 0 && (
        <div className="card mb-4">
          <div className="card-body">
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              <h2 className="blok-title mb-0">Products</h2>
            </div>
            <table className="table table-striped animated slideInUp">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {products &&
                  products.map((x, i) => {
                    return (
                      <tr key={x.id}>
                        <td>{i + 1}.</td>
                        <td>
                          <Link to={`/home/products/${x.id}`}>{x.name}</Link>
                        </td>
                        <td>
                          {x.price !== null ? x.price.toLocaleString() : '-'}
                        </td>
                        <td>
                          {moment(x.createdAt).format('DD/MM/YYYY - HH:mm')}
                        </td>
                        <td>
                          <Link
                            className="btn btn-sm btn-success mr-1"
                            to={`/home/products/${x.id}`}
                            style={{ minWidth: 'auto', borderRadius: 0 }}
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </Link>
                          <button
                            className="btn btn-sm btn-danger"
                            style={{ minWidth: 'auto', borderRadius: 0 }}
                            onClick={() => {
                              setDelete(x.id);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <Pagination {...pagination} path={props.location.pathname} />
            {deleteItem && (
              <div className="modals modals__overlay">
                <div className="modals__content text-center">
                  <h2 className="page-title mb-4">
                    Are you sure want to delete this item?
                  </h2>
                  <p>ID : {deleteItem}</p>
                  <Icon name="sad" classSvg="icon-sad" />
                  <div className="modals__action">
                    <button
                      onClick={closeModal}
                      className="btn btn-sm btn-warning mr-2"
                      style={{ minWidth: 'auto' }}
                    >
                      close
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      style={{ minWidth: 'auto' }}
                      onClick={() => {
                        handleDelete(deleteItem);
                        closeModal();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    products: state.products,
  };
}

export default connect(mapStateToProps)(ProductsList);
