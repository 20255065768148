import React, { Fragment, useEffect, useRef } from 'react';

export default function Pop(props) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          props.onClose
        ) {
          props.onClose();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (!props.show) {
    return '';
  }

  return (
    <Fragment>
      <div ref={wrapperRef}>{props.children}</div>
    </Fragment>
  );
}
