import React, { useState } from 'react';
import Select from 'react-select';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#4F4F4F',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export default function SelectField(props) {
  const groupOptions = props.options
    .reduce((sum, x, i) => {
      return [
        ...sum,
        ...x.children.map((xx, ii) => ({ ...xx, lvl1: i, lvl2: ii })),
      ];
    }, [])
    .map((x, i) => ({
      label: x.name,
      options: x.children.map((xx, ii) => ({
        value: xx.id,
        label: `${x.lvl1 + 1}.${x.lvl2 + 1}.${ii + 1} - ${xx.name}`,
      })),
    }));
  const flatOptions = groupOptions.reduce((sum, x) => {
    return [...sum, ...x.options];
  }, []);
  const [value, setValue] = useState(
    flatOptions.find(x => x.value === props.field.value)
  );

  function handleChange(val) {
    setValue(val);
    props.form.setFieldValue(props.field.name, val.value);
  }

  return (
    <div>
      <Select
        value={value}
        onChange={handleChange}
        options={groupOptions}
        formatGroupLabel={formatGroupLabel}
      />
    </div>
  );
}
