import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getProducts,
  postProduct,
  updateProduct,
  deleteProduct,
} from 'reducer/products';

import Detail from './Detail';
import List from './List';

export function Products(props) {
  return (
    <Switch>
      <Route
        render={params => <Detail {...props} {...params} />}
        path="/home/products/:id"
        exact
      />
      <Route
        render={params => <List {...props} {...params} />}
        path="/home/products"
        exact
      />
    </Switch>
  );
}

function mapDispatchToProps(dispatch, props) {
  return {
    _getProducts: (id, params) =>
      dispatch(getProducts(id, props.token, params)),
    _postProduct: data => dispatch(postProduct(data, props.token)),
    _updateProduct: data => dispatch(updateProduct(data, props.token)),
    _deleteProduct: data => dispatch(deleteProduct(data, props.token)),
  };
}

export default connect(null, mapDispatchToProps)(Products);
