import React, { Fragment, useState, useEffect } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';

import DateRangeForm from 'component/DateRangeForm';
import FullPageLoader from 'component/FullPageLoader';
import Modal from 'component/Modal';
import ReportTool from 'component/ReportTool';

import { toQueryObject, exportToCsv, exportToPdf } from 'helper';

const docDefinition = {
  content: [
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', '*', '*', 'auto', 'auto'],
      },
    },
  ],
};

export default function ReportJournal(props) {
  const pageQuery = get(props, 'location.search');
  const transactions = get(props, 'transactions.list');
  const query = toQueryObject(pageQuery);
  const preset = query.preset || 'this-month';
  const start = query.start || null;
  const end = query.end || null;
  const [loading, setLoading] = useState(true);
  const [datePicker, setDatePicker] = useState(false);

  let startDate;
  let endDate;
  let title;
  let m;

  switch (preset) {
    case 'this-month':
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'last-month':
      m = moment().subtract(1, 'months');
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'this-year':
      m = moment();
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    case 'last-year':
      m = moment().subtract(1, 'years');
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    default:
      // default to this-month
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
  }
  if (start && end) {
    title = `${moment(start).format('DD MMMM YYYY')} - ${moment(end).format(
      'DD MMMM YYYY'
    )}`;
  }

  const baseQueryParameter = {
    company: props.company.id,
    limit: 20,
    offset: 0,
    includeLines: 1,
    start: start || startDate,
    end: end || endDate,
  };

  const initialRange = {
    start: start || startDate,
    end: end || endDate,
  };

  useEffect(() => {
    props._throttleTransactions(baseQueryParameter).then(() => {
      setLoading(false);
    });
  }, [startDate, endDate, start, end]); //eslint-disable-line

  function handleCustomRange(data) {
    setDatePicker(false);
    setLoading(true);
    props
      ._push(`/home/reports/journal/?start=${data.start}&end=${data.end}`)
      .then(() => {
        setLoading(false);
      });
  }

  function generateTable() {
    const csvTable = [
      ['Type', 'Date', 'Description', 'Project', 'Debit', 'Credit'],
    ];

    transactions
      .sort((a, b) => a.date > b.date)
      .forEach(x => {
        csvTable.push([
          x.type.toUpperCase(),
          moment(x.date).format('DD MMMM YYYY'),
          x.description,
          '',
          '',
          '',
        ]);
        x.lines.forEach(xx => {
          csvTable.push([
            '',
            '',
            xx.account.name,
            '',
            xx.amount > 0 ? xx.amount : 0,
            xx.amount < 0 ? -xx.amount : 0,
          ]);
        });
      });
    return csvTable;
  }

  function handleDownloadPdf() {
    docDefinition.content[0].table.body = generateTable();
    exportToPdf('journal.pdf', docDefinition);
  }

  function handleDownloadCsv() {
    exportToCsv('journal.csv', generateTable());
  }

  return (
    <Fragment>
      <h2>
        <M id="app.journal" defaultMessage="Journal" />
      </h2>
      <h4>Periode : {title}</h4>

      <div className="mb-5">
        <table className="table table-sm">
          <thead className="thead-light">
            <tr>
              <th>
                <M id="app.type" defaultMessage="Type" />
              </th>
              <th>
                <M id="app.date" defaultMessage="Date" />
              </th>
              <th>
                <M id="app.description" defaultMessage="Description" />
              </th>
              <th>
                <M id="app.project" defaultMessage="Project" />
              </th>
              <th className="text-right">
                <M id="app.debit" defaultMessage="Debit" />
              </th>
              <th className="text-right">
                <M id="app.credit" defaultMessage="Credit" />
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.length === 0 && (
              <tr>
                <td>no entry</td>
              </tr>
            )}
            {transactions
              .sort((a, b) => a.date > b.date)
              .map((x, i) => {
                return (
                  <Fragment key={x.id}>
                    <tr className="table-secondary">
                      <td>{x.type.toUpperCase()}</td>
                      <td>{moment(x.date).format('DD MMMM YYYY')}</td>
                      <td>{x.description}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {x.lines &&
                      x.lines
                        .sort((a, b) => b.amount - a.amount)
                        .map(xx => {
                          return (
                            <tr key={xx.id}>
                              <td></td>
                              <td></td>
                              <td>{xx.account.name}</td>
                              <td></td>
                              <td className="text-right">
                                {xx.amount > 0 ? xx.amount.toLocaleString() : 0}
                              </td>
                              <td className="text-right">
                                {xx.amount < 0
                                  ? (-xx.amount).toLocaleString()
                                  : 0}
                              </td>
                            </tr>
                          );
                        })}
                    <tr>
                      <td colSpan="6">&nbsp;</td>
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>

      <ReportTool
        baseUrl={`/home/reports/journal`}
        onOpenPicker={() => setDatePicker(true)}
        onDownloadPdf={handleDownloadPdf}
        onDownloadCsv={handleDownloadCsv}
      />

      <FullPageLoader show={loading} />
      <Modal show={datePicker} onClose={() => setDatePicker(false)}>
        <DateRangeForm
          onSubmit={handleCustomRange}
          initialValues={initialRange}
        />
      </Modal>
    </Fragment>
  );
}
