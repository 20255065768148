import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
import { connect } from 'react-redux';

import { accountTreeToArray } from 'helper';
import TransferForm from 'component/TransferForm';
import Loading from 'component/Global/Loading';

export function TransferDetail(props) {
  const { _getTransactions, idToken, company } = props;

  const [isLoading, setIsLoading] = useState(true);

  const id = get(props, 'match.params.id');
  const one = get(props, 'transactions.one');
  const accounts = accountTreeToArray(company.accounts).map(x => ({
    ...x,
    name: `${x.code} - ${x.name}`,
  }));

  const month = moment().format('YYYY-MM');

  const isNew = id === 'new';
  const title = isNew ? 'New Item' : 'Update Item';
  let initialValues = {
    date: moment().format('YYYY-MM-DD'),
    time: moment().format('HH:mm'),
    from: accounts[0] ? accounts[0].id : '',
    to: accounts[1] ? accounts[1].id : '',
    amount: 100000,
    description: '',
  };

  useEffect(() => {
    if (!isNew) {
      if (one && one.id === id) {
        setIsLoading(false);
      } else {
        _getTransactions(id, idToken).then(() => {
          setIsLoading(false);
        });
      }
    } else {
      setIsLoading(false);
    }
  }, [id]); //eslint-disable-line

  if (!isLoading && id !== 'new' && one) {
    initialValues = {
      id: one.id,
      date: moment.parseZone(one.date).format('YYYY-MM-DD'),
      time: moment.parseZone(one.date).format('HH:mm'),
      code: one.code,
      from: one.lines[0].accountId,
      to: one.lines[1].accountId,
      amount: Math.abs(one.lines[0].amount),
      description: one.description,
    };
  }

  if (!company) {
    return '';
  }

  function handleSubmit(data) {
    setIsLoading(true);

    const request = {
      type: 'transfer',
      user: props.uid,
      company: company.id,
      date: `${data.date} ${data.time}`,
      description: data.description,
      lines: [
        {
          accountId: data.from,
          amount: -data.amount,
        },
        {
          accountId: data.to,
          amount: data.amount,
        },
      ],
    };

    if (isNew) {
      return props._postTransaction(request).then(data => {
        props._push(`/home/transfers`);
        // setIsLoading(false);
      });
    } else {
      request.id = id;
      return props._updateTransaction(request).then(data => {
        props._push(`/home/transfers`);
        // setIsLoading(false);
      });
    }
  }

  function handleDelete() {
    return props._deleteTransaction(id).then(() => {
      props._push(`/home/transfers`);
    });
  }

  if (isLoading) {
    // isLoading
    return <Loading></Loading>;
  }

  return (
    <Fragment>
      <Helmet>
        <title>Aiclo Accounting - Home - Transfer - {title}</title>
      </Helmet>
      <div className="row mb-4 justify-content-md-center">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="d-flex flex-row justify-content-start align-items-center mb-3">
            <Link
              to={`/home/transfers/?month=${month}`}
              className="btn btn-link text-left p-0"
              style={{ minWidth: 'auto' }}
            >
              <i
                className="fa fa-chevron-left"
                style={{ fontSize: '12px' }}
              ></i>{' '}
              Back
            </Link>
            <h3 className="page-title ml-2">
              |{' '}
              {isNew ? (
                <M id="app.newTransfer" defaultMessage="New Transfer" />
              ) : (
                <M id="app.updateTransfer" defaultMessage="Update Transfer" />
              )}
            </h3>
          </div>
          <div className="card auths">
            <div className="card-body">
              <TransferForm
                onSubmit={handleSubmit}
                accounts={company.accounts}
                initialValues={initialValues}
                isNew={isNew}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    transactions: state.transactions,
  };
}

export default connect(mapStateToProps)(TransferDetail);
