import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormattedMessage as M } from 'react-intl';
import get from 'lodash/get';

import LoginForm from 'component/LoginForm';
import { registerEmail } from 'reducer/auth';
import aicloLogo from '../assets/images/aiclo-logo.svg';
import Close from '../assets/images/red-cross.svg';

export function Register(props) {
  useEffect(() => {
    if (props.uid) {
      props.push('/home');
    }
  });

  return (
    <div className="container">
      <div className="row mb-4 justify-content-md-center">
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="bg-comeback">
            <img src={aicloLogo} alt="Come Back" />
          </div>
          <div className="card auths">
            <div className="closeBack">
              <Link to="/">
                <img src={Close} alt="close page" />
              </Link>
            </div>
            {!props.uid ? (
              <Fragment>
                <div className="card-header border-0 pb-0">
                  <h3 className="title-profile mb-2">
                    <M id="app.register" defaultMessage="Register account" />
                  </h3>
                </div>
                <div className="card-body">
                  <LoginForm
                    onSubmit={props.registerEmail}
                    btnText="Register"
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="card-header border-0 pb-0">
                  <h3 className="title-profile mb-2">
                    <M
                      id="app.youAreAlreadyLoggedIn"
                      defaultMessage="You are already logged in,"
                    />
                  </h3>
                </div>
                <div className="card-body">
                  <Link to="/home">
                    <M
                      id="app.goToDashboard"
                      defaultMessage="Go to dashboard"
                    />
                  </Link>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {/*<div
        className="row align-items-center"
        style={{ minHeight: 'calc(100vh - 40px)' }}
      >
        <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto">
          {!props.uid ? (
            <Fragment>
              <h1 className="text-center">
                <M id="app.register" defaultMessage="Register" />
              </h1>
              <LoginForm onSubmit={props.registerEmail} />
              <hr />
              <p>
                <M
                  id="app.alreadyHaveAnAccount"
                  defaultMessage="Already have an account ?"
                />
                <br />
                <Link to="/">
                  <M id="app.loginHere" defaultMessage="Login here." />
                </Link>
              </p>
            </Fragment>
          ) : (
            <Fragment>
              <p>
                <M
                  id="app.youAreAlreadyLoggedIn"
                  defaultMessage="You are already logged in,"
                />
                <br />
                <Link to="/home">
                  <M id="app.goToDashboard" defaultMessage="Go to dashboard" />
                </Link>
              </p>
            </Fragment>
          )}
        </div>
      </div>*/}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    uid: get(state, 'auth.user.uid'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    registerEmail: (email, password) =>
      dispatch(registerEmail(email, password)),
    push: to => dispatch(push(to)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
