import React, { Fragment } from 'react';
import { FormattedMessage as M } from 'react-intl';
// import cloneDeep from 'lodash/cloneDeep';

import AccountChart from 'component/AccountChart';

export default function ChartOfAccounts(props) {
  return (
    <Fragment>
      <h2>
        <M id="app.listOfAccounts" defaultMessage="List of accounts" />
      </h2>

      <AccountChart accounts={props.company.accounts} />
    </Fragment>
  );
}
