import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

import { FormattedMessage as M } from 'react-intl';

// import SalesWidget from 'component/Dashboard/SalesWidget';
import ProfitLossWidget from 'component/Dashboard/ProfitLossWidget';
import CashFlowWidget from 'component/Dashboard/CashFlowWidget';
import BarChartWidget from 'component/Dashboard/BarChartWidget';
import PieChartWidget from 'component/Dashboard/PieChartWidget';
// import LineChartWidget from 'component/Dashboard/LineChartWidget';

import DateRangeForm from 'component/DateRangeForm';
import FullPageLoader from 'component/FullPageLoader';
import Modal from 'component/Modal';
import ReportTool from 'component/ReportTool';

import { throttleTransactions } from 'reducer/transactions';
import { getBalance } from 'reducer/accounts';
import { toQueryObject } from 'helper';
import {
  makeProfitLoss,
  makeCashFlow,
  // makeCashFlow,
  // updateAccountBalance,
} from 'helper/accounting';
import './Dashboard.scss';

export function Dashboard(props) {
  const currency = get(props, 'company.defaultCurrency', '');
  const pageQuery = get(props, 'location.search');
  const transactions = get(props, 'transactions.list');
  const accounts = get(props, 'company.accounts', []);
  const balance = get(props, 'balance');
  const query = toQueryObject(pageQuery);
  const preset = query.preset || 'this-month';
  const start = query.start || null;
  const end = query.end || null;

  const [loading, setLoading] = useState(true);
  // const [loadingBalance, setLoadingBalance] = useState(true);
  const [datePicker, setDatePicker] = useState(false);

  let startMoment;
  let startDate;
  let endMoment;
  let endDate;
  let title;
  let m;

  switch (preset) {
    case 'this-month':
      startMoment = moment().startOf('month');
      startDate = startMoment.format('YYYY-MM-DD');
      endMoment = moment().endOf('month');
      endDate = endMoment.format('YYYY-MM-DD');
      title = startMoment.format('MMMM YYYY');
      break;
    case 'last-month':
      startMoment = moment()
        .subtract(1, 'months')
        .startOf('month');
      startDate = startMoment.format('YYYY-MM-DD');
      endMoment = moment()
        .subtract(1, 'months')
        .endOf('month');
      endDate = endMoment.format('YYYY-MM-DD');
      title = startMoment.format('MMMM YYYY');
      break;
    case 'this-year':
      startMoment = moment()
        .startOf('year')
        .startOf('month');
      startDate = startMoment.format('YYYY-MM-DD');
      endMoment = moment()
        .startOf('year')
        .endOf('month');
      endDate = endMoment.format('YYYY-MM-DD');
      title = startMoment.format('YYYY');
      break;
    case 'last-year':
      startMoment = moment()
        .subtract(1, 'years')
        .startOf('year')
        .startOf('month');
      startDate = startMoment.format('YYYY-MM-DD');
      endMoment = moment()
        .subtract(1, 'years')
        .startOf('year')
        .endOf('month');
      endDate = endMoment.format('YYYY-MM-DD');
      title = startMoment.format('YYYY');
      break;
    default:
      // default to this-month
      startMoment = moment().startOf('month');
      startDate = startMoment.format('YYYY-MM-DD');
      endMoment = moment().endOf('month');
      endDate = endMoment.format('YYYY-MM-DD');
      title = startMoment.format('MMMM YYYY');

      break;
  }
  if (start && end) {
    title = `${moment(start).format('DD MMMM YYYY')} - ${moment(end).format(
      'DD MMMM YYYY'
    )}`;
  }

  const baseQueryParameter = {
    company: props.company.id,
    limit: 20,
    offset: 0,
    includeLines: 1,
    start: start || startDate,
    end: end || endDate,
  };

  const initialRange = {
    start: start || startDate,
    end: end || endDate,
  };

  // get cash bank initial balance
  const cashBankAccounts = accounts.reduce((sum, x, i) => {
    let res = [];
    for (const level2 of x.children) {
      if (level2.cashFlow === 'cashBank') {
        res = [...res, ...level2.children];
      }
    }
    return [...sum, ...res];
  }, []);

  const cashBankInitialBalance = cashBankAccounts.reduce((sum, x, i) => {
    const accountBalance = balance.find(xx => xx.id === x.id);
    if (accountBalance) {
      return sum + accountBalance.balance_at_date;
    }
    return sum;
  }, 0);

  useEffect(() => {
    props._throttleTransactions(baseQueryParameter).then(() => {
      setLoading(false);
    });
  }, [startDate, endDate, start, end]); //eslint-disable-line

  useEffect(() => {
    props._getBalance({
      company: props.company.id,
      date: startDate,
    });
  }, [startDate]); //eslint-disable-line

  function handleCustomRange(data) {
    setDatePicker(false);
    setLoading(true);
    props._push(`/home/?start=${data.start}&end=${data.end}`).then(() => {
      setLoading(false);
    });
  }

  // construct time line
  const segment = 8;
  const timeSpan = moment.duration(endMoment.diff(startMoment)).days();
  const timeSpanWithEdge = timeSpan + 2;
  const timeUnit =
    timeSpan > segment ? Math.ceil(timeSpanWithEdge / segment) : 1;
  const timeLine = [];

  let timeS = startMoment.clone();
  for (var i = 0; i < segment; i++) {
    timeLine.push(timeS.format('YYYY-MM-DD'));
    timeS.add(timeUnit, 'days');
  }

  const flatTransaction = transactions.reduce((sum, x) => {
    return [
      ...sum,
      ...(x.lines ? x.lines.map(xx => ({ ...xx, date: x.date })) : []),
    ];
  }, []);

  // construct profit loss object
  let majorIncome, majorExpense, profitLossTimeLine;
  const profitLoss = makeProfitLoss(accounts, transactions);

  if (profitLoss) {
    // get major income
    majorIncome = profitLoss.items.income.children
      .reduce((sum, x, i) => [...sum, ...x.children], [])
      .sort((a, b) => b.balance - a.balance)
      .slice(0, 3);

    // get major expense
    majorExpense = profitLoss.items.expenseOnIncome.children
      .reduce((sum, x, i) => [...sum, ...x.children], [])
      .sort((a, b) => b.balance - a.balance)
      .slice(0, 3);

    const incomeAccounts = [
      ...profitLoss.items.income.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
      ...profitLoss.items.otherIncome.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
    ];

    const expenseAccounts = [
      ...profitLoss.items.expenseOnIncome.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
      ...profitLoss.items.nonOperationalExpense.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
      ...profitLoss.items.operationalExpense.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
      ...profitLoss.items.otherExpense.children.reduce((sum, x) => {
        return [...sum, ...x.children.map(xx => xx.id)];
      }, []),
    ];

    // construct profit loss timeline
    profitLossTimeLine = timeLine.map(x => {
      return {
        name: x,
        income:
          Math.abs(
            flatTransaction
              .filter(xx => {
                return (
                  moment.parseZone(xx.date) <= moment(x, 'YYYY-MM-DD') &&
                  incomeAccounts.includes(xx.account.id)
                );
              })
              .reduce((sum, xx) => sum + xx.amount, 0)
          ) / 1000,
        expense:
          flatTransaction
            .filter(xx => {
              return (
                moment.parseZone(xx.date) <= moment(x, 'YYYY-MM-DD') &&
                expenseAccounts.includes(xx.account.id)
              );
            })
            .reduce((sum, xx) => sum + xx.amount, 0) / 1000,
      };
    });
  }

  // construct balance sheet obejct
  const cashFlow = makeCashFlow(accounts, transactions);
  let cashBankTimeLine;
  if (cashFlow) {
    // construct profit loss timeline
    cashBankTimeLine = timeLine.map(x => {
      return {
        name: x,
        ...flatTransaction
          .filter(xx => {
            return (
              moment.parseZone(xx.date) <= moment(x, 'YYYY-MM-DD') &&
              cashBankAccounts.map(xxx => xxx.id).includes(xx.account.id)
            );
          })
          .reduce((sum, xx) => {
            if (sum[xx.account.name]) {
              sum[xx.account.name] += xx.amount;
            } else {
              sum[xx.account.name] = xx.amount;
            }
            return sum;
          }, {}),
      };
    });

    if (cashBankTimeLine && balance) {
      const initialBalance = cashBankAccounts.reduce((sum, x) => {
        const startingBalance = balance.find(xx => xx.name === x.name);
        return {
          ...sum,
          [x.name]: startingBalance ? startingBalance.balance_at_date : 0,
        };
      }, {});
      cashBankTimeLine[0] = {
        ...cashBankTimeLine[0],
        ...initialBalance,
      };
    }
  }

  return (
    <>
      {loading && <p>Loading</p>}
      {props.company.id ? (
        <>
          <div className="top-page">
            <h2 className="page-title">
              <M id="app.dashboard" defaultMessage="Dashboard" /> <>{title}</>
            </h2>

            <ul className="breadcrumbs list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">
                <span>
                  <M id="app.dashboard" defaultMessage="Dashboard" />
                </span>
              </li>
            </ul>
          </div>

          {
            // NOTE : applicable on phase 3 development
            // <section className="row mb-4">
            //   <div className="col-sm-12 col-md-12 col-lg-12">
            //     <h2 className="blok-title mb-3">Faktur</h2>
            //   </div>
            //   <div className="col-sm-12 col-md-6 col-lg-6 mb-sm-4 mb-lg-0">
            //     <SalesWidget type="penjualan"></SalesWidget>
            //   </div>
            //   <div className="col-sm-12 col-md-6 col-lg-6">
            //     <SalesWidget type="pembelian"></SalesWidget>
            //   </div>
            // </section>
          }

          <section className="row mb-4">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-sm-4 mb-lg-0">
              <h2 className="blok-title mb-3">Laba Rugi</h2>
              {profitLoss ? (
                <ProfitLossWidget
                  profitLoss={profitLoss}
                  currency={currency}
                ></ProfitLossWidget>
              ) : (
                <p>
                  Please set your profit and loss accounts in your chart of
                  account to see the report.
                </p>
              )}
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <h2 className="blok-title mb-3">Cash & Bank</h2>
              {cashFlow ? (
                <CashFlowWidget
                  cashFlow={cashFlow}
                  initial={cashBankInitialBalance}
                  currency={currency}
                ></CashFlowWidget>
              ) : (
                <p>
                  Please set your balance sheet accounts in your chart of
                  account to see the report.
                </p>
              )}
            </div>
          </section>

          <section className="row mb-4">
            <div className="col-sm-12 col-md-12 col-lg-6 mb-sm-4 mb-lg-0">
              {profitLoss ? (
                <BarChartWidget
                  title="Profit and loss"
                  data={profitLossTimeLine}
                ></BarChartWidget>
              ) : (
                <p>
                  Please set your profit and loss accounts in your chart of
                  account to see the report.
                </p>
              )}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-4 mb-lg-0">
              {profitLoss ? (
                <PieChartWidget
                  title="Expenses"
                  data={majorExpense}
                ></PieChartWidget>
              ) : (
                <p>
                  Please set your profit and loss accounts in your chart of
                  account to see the report.
                </p>
              )}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mb-sm-4 mb-lg-0">
              {profitLoss ? (
                <PieChartWidget
                  title="Incomes"
                  data={majorIncome}
                ></PieChartWidget>
              ) : (
                <p>
                  Please set your profit and loss accounts in your chart of
                  account to see the report.
                </p>
              )}
            </div>
          </section>

          <section className="row mb-4">
            <div className="col-sm-12 col-md-6 col-lg-6 mb-sm-4 mb-lg-0">
              <BarChartWidget
                title="Bank accounts"
                data={cashBankTimeLine}
              ></BarChartWidget>
            </div>
            {
              // NOTE : applicable on phase 3 development
              // <div className="col-sm-12 col-md-6 col-lg-6 mb-sm-4 mb-lg-0">
              //   <LineChartWidget title="Sales"></LineChartWidget>
              // </div>
            }
          </section>
          <ReportTool
            baseUrl={`/home`}
            onOpenPicker={() => setDatePicker(true)}
          />

          <FullPageLoader show={loading} />
          <Modal show={datePicker} onClose={() => setDatePicker(false)}>
            <DateRangeForm
              onSubmit={handleCustomRange}
              initialValues={initialRange}
            />
          </Modal>
        </>
      ) : (
        <div>
          <p>
            <M
              id="dashboard.onBoarding"
              defaultMessage="Create your company to begin."
            />
          </p>
          <Link
            className="btn btn-primary btn-create-household"
            to="/home/on-boarding"
          >
            <M id="app.createCompany" defaultMessage="Create company" />
          </Link>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    company: state.company,
    transactions: state.transactions,
    balance: state.accounts.balance,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    _throttleTransactions: params =>
      dispatch(throttleTransactions(params, props.token)),
    _getBalance: params => dispatch(getBalance(params, props.token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
