import { push } from 'connected-react-router';
import { auth } from 'config/firebase';

import { createError, createFlash } from './flasher';

export const SUCCESSFUL_LOGIN = 'auth/SUCCESSFUL_LOGIN';
export const SUCCESSFUL_LOGOUT = 'auth/SUCCESSFUL_LOGOUT';
export const SET_TOKEN = 'auth/SET_TOKEN';
export const PATCH_PROFILE = 'auth/PATCH_PROFILE';

export let currentUser;

const initialState = {
  user: null,
  idToken: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESSFUL_LOGIN:
      return {
        ...state,
        user: { ...action.payload },
      };
    case SET_TOKEN:
      return {
        ...state,
        idToken: action.payload.idToken,
      };
    case PATCH_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SUCCESSFUL_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

// Action
export function registerEmail(email, password) {
  return function(dispatch) {
    return auth.createUserWithEmailAndPassword(email, password).catch(error => {
      dispatch(createError(error.message));
      throw new Error(error.message);
    });
  };
}

export function loginEmail(email, password) {
  return function(dispatch) {
    return auth.signInWithEmailAndPassword(email, password).catch(error => {
      dispatch(createError(error.message));
      throw new Error(error.message);
    });
  };
}

export function getToken() {
  return async function(dispatch) {
    const token = await new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          auth.currentUser
            .getIdToken(false)
            .then(function(idToken) {
              resolve(idToken);
            })
            .catch(function(error) {
              console.error(error);
            });
        }
      });
    });
    // const token = await auth.currentUser.getIdToken(false);
    return token;
  };
}

export function onAuthStateChanged() {
  return function(dispatch) {
    auth.onAuthStateChanged(function(user) {
      if (auth.currentUser) {
        auth.currentUser
          .getIdToken(false)
          .then(function(idToken) {
            dispatch({
              type: SET_TOKEN,
              payload: { idToken },
            });
          })
          .catch(function(error) {
            console.error(error);
          });

        dispatch({
          type: SUCCESSFUL_LOGIN,
          payload: {
            displayName: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            photoURL: user.photoURL,
            isAnonymous: user.isAnonymous,
            uid: user.uid,
            providerData: user.providerData,
          },
        });
      } else {
        dispatch({ type: SUCCESSFUL_LOGOUT });
      }
    });
  };
}

export function logout() {
  return function(dispatch) {
    auth.signOut().then(() => {
      dispatch({
        type: SUCCESSFUL_LOGOUT,
      });
      dispatch(push('/'));
    });
  };
}

export function updateProfile(data) {
  return function(dispatch) {
    const user = auth.currentUser;
    return user
      .updateProfile({
        displayName: data.displayName,
        photoURL: data.photoURL,
      })
      .then(() => {
        dispatch(createFlash('Profile updated.'));
        dispatch({
          type: PATCH_PROFILE,
          payload: {
            displayName: data.displayName,
            photoURL: data.photoURL,
          },
        });
      })
      .catch(error => {
        dispatch(createError(error.message));
        throw new Error(error.message);
      });
  };
}

export function updateEmail(email) {
  return function(dispatch) {
    const user = auth.currentUser;
    return user
      .updateEmail(email)
      .then(function() {
        dispatch(createFlash('Email updated.'));
      })
      .catch(error => {
        dispatch(createError(error.message));
        throw new Error(error.message);
      });
  };
}

export function updatePassword(password) {
  return function(dispatch) {
    const user = auth.currentUser;
    return user
      .updatePassword(password)
      .then(function() {
        dispatch(createFlash('Password updated.'));
      })
      .catch(error => {
        dispatch(createError(error.message));
        throw new Error(error.message);
      });
  };
}

export function resetPassword(email) {
  return function(dispatch) {
    const user = auth;
    return user
      .sendPasswordResetEmail(email)
      .then(function() {
        dispatch(createFlash('Password updated.'));
      })
      .catch(error => {
        dispatch(createError(error.message));
        throw new Error(error.message);
      });
  };
}
