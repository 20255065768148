import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .email('Please insert correct email format'),
});

export default function EmailForm(props) {
  return (
    <div>
      <Formik
        className="form"
        validationSchema={ValidationSchema}
        initialValues={{
          email: props.initialValues ? props.initialValues.email || '' : '',
        }}
        onSubmit={(values, actions) => {
          props
            .onSubmit(values.email)
            .then(updatedUser => {
              actions.setSubmitting(false);
            })
            .catch(er => {
              actions.setSubmitting(false);
              console.error(er);
            });
        }}
      >
        {({ errors, status, touched, isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                className="form-control"
                type="email"
                name="email"
                placeholder="Enter your email"
              />
              <ErrorMessage
                name="email"
                component="small"
                className="form-text text-muted"
              />
            </div>

            <div className="action-btn">
              <button className="btn btn-warning mb-0" type="reset">
                Reset
              </button>
              <button
                className="btn btn-primary mb-0"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
