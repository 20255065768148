import React, { Fragment, useState, useEffect } from 'react';

import get from 'lodash/get';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { FormattedMessage as M } from 'react-intl';
// import { Link } from 'react-router-dom';

import DateRangeForm from 'component/DateRangeForm';
import FullPageLoader from 'component/FullPageLoader';
import Modal from 'component/Modal';

import AccountGroup from 'component/Reports/AccountGroup';
import ReportTool from 'component/ReportTool';

import { toQueryObject, exportToCsv, exportToPdf } from 'helper';
import { makeCashFlow } from 'helper/accounting';

var docDefinition = {
  content: [
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['*', 'auto'],
      },
    },
  ],
};

export default function ReportCashFlow(props) {
  const pageQuery = get(props, 'location.search');
  // const transactions = get(props, 'transactions.list');
  const query = toQueryObject(pageQuery);
  const preset = query.preset || 'this-month';
  const start = query.start || null;
  const end = query.end || null;
  const [loading, setLoading] = useState(true);
  const [datePicker, setDatePicker] = useState(false);

  let startDate;
  let endDate;
  let title;
  let m;

  const cashFlow = makeCashFlow(
    props.company.accounts,
    props.transactions.list
  );

  // get cash bank initial balance
  const cashBankAccounts = props.company.accounts.reduce((sum, x, i) => {
    let res = [];
    for (const level2 of x.children) {
      if (level2.cashFlow === 'cashBank') {
        res = [...res, ...level2.children];
      }
    }
    return [...sum, ...res];
  }, []);

  const cashBankInitialBalance = cashBankAccounts.reduce((sum, x, i) => {
    const accountBalance = props.balance.find(xx => xx.id === x.id);
    if (accountBalance) {
      return sum + accountBalance.balance_at_date;
    }
    return sum;
  }, 0);

  switch (preset) {
    case 'this-month':
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'last-month':
      m = moment().subtract(1, 'months');
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
    case 'this-year':
      m = moment();
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    case 'last-year':
      m = moment().subtract(1, 'years');
      startDate = m.startOf('year').format('YYYY-MM-DD');
      endDate = m.endOf('year').format('YYYY-MM-DD');
      title = m.format('YYYY');
      break;
    default:
      // default to this-month
      m = moment();
      startDate = m.startOf('month').format('YYYY-MM-DD');
      endDate = m.endOf('month').format('YYYY-MM-DD');
      title = m.format('MMMM YYYY');
      break;
  }
  if (start && end) {
    title = `${moment(start).format('DD MMMM YYYY')} - ${moment(end).format(
      'DD MMMM YYYY'
    )}`;
  }

  const baseQueryParameter = {
    company: props.company.id,
    limit: 20,
    offset: 0,
    includeLines: 1,
    start: start || startDate,
    end: end || endDate,
  };

  const initialRange = {
    start: start || startDate,
    end: end || endDate,
  };

  useEffect(() => {
    props
      ._throttleTransactions({
        ...baseQueryParameter,
      })
      .then(() => {
        setLoading(false);
      });
  }, [startDate, endDate, start, end]); //eslint-disable-line

  useEffect(() => {
    props._getBalance({
      company: props.company.id,
      date: startDate,
    });
  }, [startDate]); //eslint-disable-line

  function handleCustomRange(data) {
    setDatePicker(false);
    setLoading(true);
    props
      ._push(`/home/reports/cash-flow/?start=${data.start}&end=${data.end}`)
      .then(() => {
        setLoading(false);
      });
  }

  function generateTable() {
    const keys = Object.keys(cashFlow.items).filter(x => x !== 'cashBank');
    const result = keys.reduce((sum, x, i) => {
      return [
        ...sum,
        [`# ${startCase(x)}`, ''],
        ...cashFlow.items[x].children.reduce(
          (ssum, xx, ii) => [
            ...ssum,
            [`## ${startCase(xx.name)}`, ''],
            ...xx.children.map(xxx => [xxx.name, xxx.balance]),
            [`## Total ${startCase(xx.name)}`, xx.balance],
          ],
          []
        ),
        [`# Total ${startCase(x)}`, cashFlow.items[x].balance],
      ];
    }, []);
    result.push(['# Summary', '']);

    result.push([
      'Net Increase Cash And Cash Equivalents',
      Math.abs(cashFlow.items.cashBank.balance),
    ]);

    result.push([
      'Cash And Cash Equivalents At Beginning Of The Period',
      cashBankInitialBalance,
    ]);
    result.push([
      'Cash And Cash Equivalents At End Of The Period',
      cashBankInitialBalance - cashFlow.items.cashBank.balance,
    ]);
    return result;
  }

  function handleDownloadPdf() {
    const data = generateTable().map(x => [
      x[0],
      { alignment: 'right', text: x[1].toLocaleString() },
    ]);
    docDefinition.content[0].table.body = [['Cash Flow', ''], ...data];
    exportToPdf('cashflow.pdf', docDefinition);
  }

  function handleDownloadCsv() {
    exportToCsv('cashflow.csv', generateTable());
  }

  // const lines = transactions.reduce((sum, x) => {
  //   return [...sum, ...x.lines];
  // }, []);

  if (!cashFlow) {
    return (
      <p>
        Please set your cash flow accounts in your chart of account to see the
        report.
      </p>
    );
  }

  return (
    <Fragment>
      <h2>
        <M id="app.cashFlow" defaultMessage="Cash Flow" />
      </h2>
      <h4>Periode : {title}</h4>

      <div className="mb-5">
        <table className="table table-sm">
          <AccountGroup items={cashFlow.items.operating} title="Operating" />
          <AccountGroup items={cashFlow.items.investing} title="Investing" />
          <AccountGroup items={cashFlow.items.financing} title="Financing" />

          <thead className="thead-light">
            <tr>
              <th colSpan="2">
                <div className="py-3 text-center">Summary</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="">
                <div className="py-3">
                  Net Increase Cash And Cash Equivalents
                </div>
              </td>
              <td className="text-right">
                <div className="py-3">
                  {Math.abs(cashFlow.items.cashBank.balance).toLocaleString()}
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="">
                <div className="py-3">
                  Cash And Cash Equivalents At Beginning Of The Period
                </div>
              </td>
              <td className="text-right">
                <div className="py-3">
                  {cashBankInitialBalance.toLocaleString()}
                </div>
              </td>
            </tr>
            <tr className="">
              <td className="">
                <div className="py-3">
                  Cash And Cash Equivalents At End Of The Period
                </div>
              </td>
              <td className="text-right">
                <div className="py-3">
                  {(
                    cashBankInitialBalance - cashFlow.items.cashBank.balance
                  ).toLocaleString()}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ReportTool
        baseUrl={`/home/reports/cash-flow`}
        onOpenPicker={() => setDatePicker(true)}
        onDownloadPdf={handleDownloadPdf}
        onDownloadCsv={handleDownloadCsv}
      />

      <FullPageLoader show={loading} />
      <Modal show={datePicker} onClose={() => setDatePicker(false)}>
        <DateRangeForm
          onSubmit={handleCustomRange}
          initialValues={initialRange}
        />
      </Modal>
    </Fragment>
  );
}
