import React from 'react';
import Modal from 'component/Modal';

export default function FullPageLoader(props) {
  return (
    <Modal show={props.show}>
      <div className="text-center">
        <p>
          <i className="fas fa-3x fa-cog fa-spin" />
        </p>
        <p className="mb-0">
          Loading... <i className="fa fa-circle-o-notch fa-spin fa-fw" />
        </p>
      </div>
    </Modal>
  );
}
