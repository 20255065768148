import React, { Fragment } from 'react';
import { FormattedMessage as M } from 'react-intl';

import CompanyForm from 'component/CompanyForm';

export default function CompanyProfile(props) {
  return (
    <Fragment>
      <h2>
        <M id="app.companyProfile" defaultMessage="Company Profile" />
      </h2>
      <p>
        <small>{props.company.id}</small>
      </p>
      <CompanyForm
        onSubmit={props._createOrUpdateCompany}
        initialValues={props.company}
      />
    </Fragment>
  );
}
