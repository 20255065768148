import React from 'react';
import { Formik, FastField, ErrorMessage, Form } from 'formik';
import MaskedInput from 'react-text-mask';
import { FormattedMessage as M } from 'react-intl';
import * as Yup from 'yup';

import DateField from 'component/Global/DateField';
import TimeField from 'component/Global/TimeField';
import SelectField from 'component/SelectField';
import { getNumberMask } from 'helper';

const ValidationSchema = Yup.object().shape({
  amount: Yup.string().required('Required'),
  from: Yup.string().required('Required'),
  to: Yup.string()
    .notOneOf([Yup.ref('from')], 'Please select other account')
    .required('Required'),
  description: Yup.string().required('Required'),
});

export default function TransferForm(props) {
  const { isNew, handleDelete, initialValues } = props;

  function handleFocus(event) {
    event.target.select();
  }

  return (
    <Formik
      className="form"
      enableReinitialize
      validationSchema={ValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        props
          .onSubmit({
            ...values,
            amount: parseInt(
              String(values.amount)
                .split(',')
                .join(''),
              10
            ),
          })
          .then(updatedUser => {
            // actions.setSubmitting(false);
            // actions.resetForm();
          })
          .catch(er => {
            actions.setSubmitting(false);
            console.error(er);
          });
      }}
      render={({
        values,
        errors,
        status,
        touched,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form>
          <div className="row">
            <div className="col-sm-12 col-md-4 border-right">
              <div className="form-group">
                <label htmlFor="code">Kode Jurnal</label>
                <FastField
                  className={`form-control ${errors.date &&
                    touched.date &&
                    'error'}`}
                  name="code"
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Date</label>
                <FastField
                  className="form-control"
                  placeholder="Enter Date"
                  name="date"
                >
                  {fieldProps => <DateField {...fieldProps} />}
                </FastField>
                <small className="form-text text-muted">
                  Use the format : yyyy-mm-dd
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="time">Waktu</label>
                <FastField
                  className={`form-control ${errors.time &&
                    touched.time &&
                    'error'}`}
                  name="time"
                  component={TimeField}
                />
                <ErrorMessage
                  name="time"
                  component="small"
                  className="form-text text-danger time-error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <FastField
                  className={`form-control ${errors.description &&
                    touched.description &&
                    'error'}`}
                  as="textarea"
                  name="description"
                />
                <ErrorMessage
                  name="description"
                  component="small"
                  className="form-text text-danger description-error"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-8">
              <table className="table">
                <thead>
                  <tr className="">
                    <th className="border-top-0" style={{ width: '33.3333%' }}>
                      From
                    </th>
                    <th className="border-top-0" style={{ width: '33.3333%' }}>
                      To
                    </th>
                    <th className="border-top-0" style={{ width: '33.3333%' }}>
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="">
                      <label className="sr-only" htmlFor="from">
                        From account
                      </label>
                      <FastField className="form-control" name="from">
                        {fieldProps => (
                          <SelectField
                            {...fieldProps}
                            options={props.accounts}
                          />
                        )}
                      </FastField>
                    </td>
                    <td className="">
                      <label className="sr-only" htmlFor="to">
                        To account
                      </label>
                      <FastField className="form-control" name="to">
                        {fieldProps => (
                          <SelectField
                            {...fieldProps}
                            options={props.accounts}
                          />
                        )}
                      </FastField>
                      {/*
                      <ErrorMessage
                        name="to"
                        component="small"
                        className="form-text text-muted"
                      />
                      */}
                    </td>
                    <td className="">
                      <label className="sr-only" htmlFor="account">
                        Amount
                      </label>
                      <FastField
                        className="form-control"
                        placeholder="Amount"
                        name="amount"
                      >
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            onFocus={handleFocus}
                            className="form-control"
                            type="text"
                            autoComplete="none"
                            placeholder="10,000"
                            mask={getNumberMask()}
                          />
                        )}
                      </FastField>
                      <ErrorMessage
                        name="amount"
                        component="small"
                        className="form-text text-muted"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="action-btn">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </button>
            {!isNew && (
              <button
                type="button"
                onClick={handleDelete}
                className="btn btn-outline-danger"
                disabled={isSubmitting}
              >
                <M id="app.delete" defaultMessage="Delete" />
              </button>
            )}
          </div>
        </Form>
      )}
    />
  );
}
